<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mx-auto mt-5">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Edit Admin
              </h5>
            </div>
            <form @submit.prevent="submit" ref="addUser" autocomplete="">
              <div class="form-group">
                <label class="primary-text-color"
                  >Name <span class="text-danger">*</span></label
                >
                <span class="text-danger d-block" v-if="errors.f_name">
                  Name is required
                </span>
                <input
                  type="text"
                  class="custom-form-control"
                  required
                  v-model="admin.profile.name"
                  @keydown="validateText($event)"
                />
              </div>
              <div class="form-group mt-2">
                <label class="primary-text-color">Phone</label>
                <input
                  type="text"
                  class="custom-form-control"
                  v-model="admin.profile.phone"
                  @keypress="isNumber($event)"
                  minlength="11"
                  maxlength="11"
                />
              </div>
              <div class="form-group mt-2">
                <label class="primary-text-color"
                  >Email <span class="text-danger">*</span></label
                >
                <span class="text-danger d-block" v-if="errors.username">
                  Email is required
                </span>
                <input
                  type="email"
                  class="custom-form-control"
                  required
                  v-model="admin.username"
                  autocomplete="new-email"
                />
              </div>
              <div class="form-group mt-2">
                <label class="primary-text-color"
                  >Role <span class="text-danger">*</span></label
                >
                <select
                  class="custom-form-control"
                  v-model="admin.role.name"
                  required
                >
                  <option value="">Select...</option>
                  <option v-for="role in roles" :value="role.name">
                    {{ role.name }}
                  </option>
                </select>
              </div>
              <div class="row">
                <div class="col-md-9">
                  <button
                    type="submit"
                    class="custom-btn primary-bg-color text-white btn-block w-100 mt-3"
                  >
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <router-link
                    to="/users"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import isNumber from "@/helpers/helpers";
import validateText from "@/helpers/helpers";

export default {
  mixins: [isNumber],
  data() {
    return {
      admin: {
        profile: [],
        role: {
          name: "",
        },
      },
      roles: [],
      errors: [],
      userId: null,
      loading: false,
    };
  },
  created() {
    this.fetchRoles();
    this.userId = this.$route.params.id;
    this.fetch(this.userId);
  },
  mounted() {},
  methods: {
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("users/getSingle", { id })
        .then((response) => {
          this.admin = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    submit() {
      this.loading = true;
      this.errors = [];
      const data = {
        user: this.admin,
      };
      const id = this.userId;
      this.$store
        .dispatch("users/update", { data, id })
        .then(() => {
          this.loading = false;
          this.$toast.show("Successfuly updated.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.errors = [];
        })
        .catch((e) => {
          this.errors = e.error;
          this.loading = false;
        });
    },
    fetchRoles() {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("roles/getAll", { search, pagination })
        .then((response) => {
          this.loading = false;
          this.roles = response.data;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>
