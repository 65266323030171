<template>
  <div class="container-fluid">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <button
      class="btn primary-bg-color text-white mt-5"
      @click="$router.go(-1)"
    >
      Back
    </button>
    <button
      class="btn btn-success text-white mt-5 float-end"
      @click="fetch(batchId, true)"
    >
      Export
    </button>
    <div class="card mt-3">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 mt-3">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                {{ items?.batch }}
              </h5>
            </div>
          </div>
          <div class="col-md-12">
            <data-table
              :columns="columns"
              :items="items"
              :module="module"
              :isAction="false"
              :isSearch="false"
            ></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dataTable from "@/components/dataTable.vue";

export default {
  components: {
    dataTable,
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          text: "Participant",
          value: "name",
        },
        {
          text: "Total Videos",
          value: "total_videos",
        },
        {
          text: "Watched Videos",
          value: "watch_videos",
        },
        {
          text: "Total Assignments",
          value: "total_assignments",
        },
        {
          text: "Submitted Assignments",
          value: "submitted_assignments",
        },
        {
          text: "CGPA",
          value: "cgpa",
        },
      ],
      items: [],
      module: "reports",
      batchId: "",
    };
  },
  mounted() {
    this.batchId = this.$route.params.id;
    this.fetch(this.batchId);
  },
  methods: {
    fetch(id, download = false) {
      this.loading = true;
      this.$store
        .dispatch("batch/getBatchPerformance", { id, download })
        .then((response) => {
          if (download) {
            window.location.href = response.data;
          } else {
            this.items = response.data;
          }
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
  },
};
</script>
