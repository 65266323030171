<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mx-auto mt-5">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Add FAQ
              </h5>
            </div>
            <form @submit.prevent="submit">
              <div class="form-group mb-3">
                <label>Title <span class="text-danger">*</span></label>
                <input
                  type="text"
                  v-model="faq.title"
                  class="custom-form-control"
                  requried
                />
              </div>
              <div class="form-group">
                <label>Details</label>
                <textarea
                  v-model="faq.detail"
                  class="custom-form-control"
                  style="height: 100px"
                ></textarea>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <button
                    type="submit"
                    class="custom-btn primary-bg-color text-white btn-block w-100 mt-3"
                  >
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <button
                    type="reset"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                  >
                    Clear All
                  </button>
                </div>
                <div class="col-md-3">
                  <router-link
                    to="/faq"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      faq: {
        title: null,
        detail: null,
      },
    };
  },
  methods: {
    submit() {
      this.loading = true;
      const data = this.faq;
      this.$store
        .dispatch("faq/store", { data })
        .then((response) => {
          this.loading = false;
          this.$toast.show("Successfuly added", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.faq.title = null;
          this.faq.detail = null;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
  },
};
</script>
