<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mx-auto mt-5">
        <button
          class="btn primary-bg-color text-white mb-3"
          @click="$router.go(-1)"
        >
          Back
        </button>
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Feedback
              </h5>
            </div>
            <!-- Display Feedback Data -->
            <div v-if="feedback">
              <p>
                <strong>Student Name:</strong> {{ feedback?.student?.name }}
              </p>
              <p><strong>Course Name:</strong> {{ feedback?.course?.name }}</p>
              <p><strong>Skill Learned:</strong> {{ feedback?.skillLearn }}</p>
              <p><strong>Skill Used:</strong> {{ feedback?.skillUsed }}</p>
              <!-- Display Objectives and Answers -->
              <table class="table table-bordered mt-3">
                <thead>
                  <tr>
                    <th>Objective</th>
                    <th>Answer</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(objective, index) in feedback.objectives"
                    :key="index"
                  >
                    <td>{{ objective?.obj?.text ?? " " }}</td>
                    <td v-if="objective.answer == 1">Yes</td>
                    <td v-else>No</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import router from "@/router";

export default {
  data() {
    return {
      loading: false,
      feedback: [],
    };
  },
  mounted() {
    this.feedbackId = this.$route.params.id;
    this.fetch(this.feedbackId);
  },
  methods: {
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("courses/getSingleFeedback", { id })
        .then((response) => {
          this.feedback = response.data;
          console.log("feedback",this.feedback);
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.department);
      const id = this.departmentId;
      this.$store
        .dispatch("department/update", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            this.$toast.show("Successfuly updated.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
          } else {
            this.errors = response.error;
            console.log(response.error);
          }
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
  },
};
</script>
