<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-6 mx-auto mt-5">
        <router-link to="/assignment" class="btn btn-sm primary-bg-color text-white mb-3">Back</router-link>
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">View Assignment</h4>
          </div>
          <div class="card-body">
            <form v-if="assignmentType == 'Assignment'">
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label>Batch <span class="text-danger">*</span></label>
                  <select class="form-control form-control-sm" v-model="assignment.batch_id" disabled>
                    <option value="">Select Batch</option>
                    <option v-for="item in batch" :value="item.id">
                      {{ item.title }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group mt-3">
                <label>Assignment Type <span class="text-danger">*</span></label>
                <div class="form-group">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="assignment.assignment_type"
                      name="assignmentable_type" id="quiz" value="Quiz" disabled />
                    <label class="form-check-label" for="quiz">Quiz</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="assignment.assignment_type"
                      name="assignmentable_type" id="manual" value="Manual" disabled />
                    <label class="form-check-label" for="manual">Manual Assessment</label>
                  </div>
                </div>
              </div>
              <div class="form-group" v-if="isQuiz">
                <div class="col-md-12 mt-3">
                  <label>Quiz <span class="text-danger">*</span></label>
                  <select class="form-control form-control-sm" v-model="assignment.assignmentable_id" disabled>
                    <option value="">Select Quiz</option>
                    <option v-for="item in quizes" :value="item.id">
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group" v-if="isManual">
                <div class="col-md-12 mt-3">
                  <label>Manual Assessment <span class="text-danger">*</span></label>
                  <select class="form-control form-control-sm" v-model="assignment.assignmentable_id" disabled>
                    <option value="">Select Assessment</option>
                    <option v-for="item in manualAssessments" :value="item.id">
                      {{ item.description }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group mt-2">
                <label>Submission Deadline <span class="text-danger">*</span></label>
                <input type="date" v-model="assignment.submission_deadline" class="form-control form-control-sm"
                  disabled />
              </div>
              <div class="form-group mt-2">
                <label>Duration</label>
                <input type="text" v-model="assignment.duration" class="form-control form-control-sm" disabled />
              </div>
            </form>
            <form v-else>
              <div class="form-group mt-3">
                <label>Assignment Type <span class="text-danger">*</span></label>
                <div class="form-group">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="assignment.assignment_type"
                      name="assignmentable_type" id="quiz" value="Quiz" disabled />
                    <label class="form-check-label" for="quiz">Quiz</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" v-model="assignment.assignment_type"
                      name="assignmentable_type" id="manual" value="ManualAssessment" disabled />
                    <label class="form-check-label" for="manual">Manual Assessment</label>
                  </div>
                </div>
              </div>
              <div class="form-group" v-if="isQuiz">
                <div class="col-md-12 mt-3">
                  <label>Quiz <span class="text-danger">*</span></label>
                  <select class="form-control form-control-sm" v-model="assignment.assignmentable_id" disabled>
                    <option value="">Select Quiz</option>
                    <option v-for="item in quizes" :value="item.id">
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group" v-if="isManual">
                <div class="col-md-12 mt-3">
                  <label>Manual Assessment <span class="text-danger">*</span></label>
                  <select class="form-control form-control-sm" v-model="assignment.assignmentable_id" disabled>
                    <option value="">Select Assessment</option>
                    <option v-for="item in manualAssessments" :value="item.id">
                      {{ item.description }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group mt-2">
                <label>Submission Deadline <span class="text-danger">*</span></label>
                <input type="date" v-model="assignment.submission_deadline" class="form-control form-control-sm"
                  disabled />
              </div>
              <div class="form-group mt-2" v-if="isQuiz">
                <label>Duration</label>
                <input type="text" v-model="assignment.duration" class="form-control form-control-sm" disabled />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";

export default {
  data() {
    return {
      loading: false,
      batch: [],
      assignment: {
        batch_id: "",
        submission_deadline: null,
        duration: null,
        assignmentable_type: null,
        assignmentable_id: "",
        assignment_type: null,
      },
      isQuiz: false,
      isManual: false,
      quizes: [],
      manualAssessments: [],
      assignmentId: null,
      assignmentType: null,
    };
  },
  mounted() {
    this.assignmentId = this.$route.params.id;
    this.assignmentType = this.$route.query.type;
    this.fetchAssignment(this.assignmentId);
    this.fetchBatch();
  },
  methods: {
    fetchAssignment(id) {
      this.loading = true;
      this.$store
        .dispatch("assignment/getSingle", { id, type: this.assignmentType })
        .then((response) => {
          this.assignment = response.data;
          console.log("fetch", this.assignment);
          if (this.assignment.assignment_type == "Quiz") {
            this.isQuiz = true;
            this.isManual = false;
            this.fetchQuiz();
          } else {
            this.isQuiz = false;
            this.isManual = true;
            this.fetchAssessment();
          }
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    fetchBatch() {
      this.loading = true;
      const search = "";
      const pagination = false;
      const facilitator_id =
        this.role == null ? localStorage.getItem("upId") : null;
      this.$store
        .dispatch("batch/getAll", { search, pagination, facilitator_id })
        .then((response) => {
          this.batch = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    fetchQuiz() {
      this.loading = true;
      const search = "";
      const pagination = false;
      const facilitator_id = null;
      this.$store
        .dispatch("quiz/getAll", { search, pagination, facilitator_id })
        .then((response) => {
          this.quizes = response.data;
          console.log("quz", this.quizes);
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    fetchAssessment() {
      this.loading = true;
      const search = "";
      const pagination = false;
      const facilitator_id = null;
      this.$store
        .dispatch("manualAssessment/getAll", {
          search,
          pagination,
          facilitator_id,
        })
        .then((response) => {
          this.manualAssessments = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
  },
};
</script>
