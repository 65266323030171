<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mx-auto mt-5"> 
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Edit Department
              </h5>
            </div>
            <form @submit.prevent="submit">
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color">Name <span class="text-danger">*</span></label>
                  <input type="text" class="custom-form-control" v-model="department.name" required />
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color">Focal Person</label>
                  <input type="text" class="custom-form-control" v-model="department.focal_person"/>
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color">Description</label>
                  <textarea class="custom-form-control" v-model="department.description"></textarea>
                </div>
              </div>
              <div class="row">
                <div class="col-md-9">
                  <button type="submit" class="custom-btn primary-bg-color text-white btn-block w-100 mt-3">
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <router-link to="/department"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3">Cancel</router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";

export default {
  data() {
    return {
      loading: false,
      department: {
        name: null,
        description: null,
      },
      departmentId: null,
    };
  },
  mounted() {
    this.departmentId = this.$route.params.id;
    this.fetch(this.departmentId);
  },
  methods: {
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("department/getSingle", { id })
        .then((response) => {
          this.department = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.department);
      const id = this.departmentId;
      this.$store
        .dispatch("department/update", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            this.$toast.show("Successfuly updated.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
          } else {
            this.errors = response.error;
            console.log(response.error);
          }
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
  },
};
</script>
