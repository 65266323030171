import { createWebHistory, createRouter } from "vue-router";
import Body from "../components/body";
import Dashboard from "@/views/dashboard/default";
import Login from "@/auth/login";
import VerfiyEmail from "@/auth/verify-email.vue";
import ResetPassword from "@/auth/reset_password.vue";
import RoleIndex from "@/views/role/index";
import AddRole from "@/views/role/add";
import EditRole from "@/views/role/edit";
import AdminIndex from "@/views/admin/index";
import AddAdmin from "@/views/admin/add";
import EditAdmin from "@/views/admin/edit";
import AdminProfile from "@/views/admin/profile";
import AdminChangePassword from "@/views/admin/changePassword";
import ParticipantIndex from "@/views/participant/index";
import AddParticipant from "@/views/participant/add";
import EditParticipant from "@/views/participant/edit";
import ViewParticipant from "@/views/participant/view";
import FacilitatorIndex from "@/views/facilitator/index";
import AddFacilitator from "@/views/facilitator/add";
import EditFacilitator from "@/views/facilitator/edit";
import CourseIndex from "@/views/course/index";
import CourseLevelIndex from "@/views/courseLevel/index";
import CourseMaterialIndex from "@/views/courseMaterial/index";
import AddCourse from "@/views/course/add";
import EditCourse from "@/views/course/edit";
import ReviewsCourse from "@/views/course/reviews";
import FeedbackCourse from "@/views/course/feedback";
import SingleFeedbackCourse from "@/views/course/singleFeedback";
import EnrolmentCourseRequest from "@/views/course/enrolmentRequest";
import EditLevel from "@/views/courseLevel/edit";
import QuizIndex from "@/views/quiz/index";
import AddQuiz from "@/views/quiz/add";
import EditQuiz from "@/views/quiz/edit";
import IndexManualAssessment from "@/views/manualAssessment/index";
import AddManualAssessment from "@/views/manualAssessment/add";
import EditManualAssessment from "@/views/manualAssessment/edit";
import IndexBatch from "@/views/batch/index";
import AddBatch from "@/views/batch/add";
import EditBatch from "@/views/batch/edit";
import ParticipentBatch from "@/views/batch/students";
import AddParticipentBatch from "@/views/batch/addstudent";

import EditBatchMaterial from "@/views/batch/editMaterial";

import IndexDepartment from "@/views/department/index";
import AddDepartment from "@/views/department/add";
import EditDepartment from "@/views/department/edit";

import IndexQualification from "@/views/qualification/index";
import AddQualification from "@/views/qualification/add";
import EditQualification from "@/views/qualification/edit";

import IndexDesignation from "@/views/designation/index";
import AddDesignation from "@/views/designation/add";
import EditDesignation from "@/views/designation/edit";

import EditCourseMaterial from "@/views/courseMaterial/edit";
import IndexAssignment from "@/views/assignment/index";
import AddAssignment from "@/views/assignment/add";
import EditAssignment from "@/views/assignment/edit";
import AddHOWTOVIDEOS from "@/views/webPages/howToAddVideos/add";
import EDITHOWTOVIDEOS from "@/views/webPages/howToAddVideos/edit";
import INDEXHOWTOVIDEOS from "@/views/webPages/howToAddVideos/index";
import INDEXSUBMITTEDASSIGNMENTS from "@/views/submittedAssgmnets/index";
import VIEWSUBMITTEDASSIGNMENTS from "@/views/submittedAssgmnets/view";
import VIEWSINGLEASSIGNEMNT from "@/views/submittedAssgmnets/mark";

import IndexSupport from "@/views/userSupport/index";
import ViewSupport from "@/views/userSupport/edit";
import IndexNotifications from "@/views/notification/index.vue";
import SendNotifications from "@/views/notification/add.vue";

import IndexChat from "@/views/chat/index";
import AddChat from "@/views/chat/add";
import EditChat from "@/views/chat/edit";

import IndexDistrict from "@/views/district/index.vue";
import AddDistrict from "@/views/district/add.vue";
import EditDistrict from "@/views/district/edit.vue";

import IndexTahseel from "@/views/tahseel/index.vue";
import AddTahseel from "@/views/tahseel/add.vue";
import EditTahseel from "@/views/tahseel/edit.vue";

import WebContent from "@/views/webContent/index";
import ReportIndex from "@/views/reports/index";
import ReportStudents from "@/views/reports/students";

import FaqIndex from "@/views/faq/index";
import FaqAdd from "@/views/faq/add";
import FaqEdit from "@/views/faq/edit";

import Terms from "@/views/setting/terms";
import Policy from "@/views/setting/policy";

import EndOfCourseFeedbacks from "@/views/endCourseFeeedback/index"

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    component: Body,
    children: [
      {
        path: "/dashboard",
        component: Dashboard,
      },
    ],
  },
  {
    path: "/auth",
    children: [
      {
        path: "login",
        name: "Login 1",
        component: Login,
        meta: {
          public: true,
        },
      },
      {
        path: "verifyEmail",
        component: VerfiyEmail,
        meta: {
          public: true,
        },
      },
      {
        path: "resetPassword",
        component: ResetPassword,
        meta: {
          public: true,
        },
      },
    ],
  },
  {
    path: "/roles",
    component: Body,
    children: [
      {
        path: "/roles",
        component: RoleIndex,
      },
      {
        path: "/roles/add",
        component: AddRole,
      },
      {
        path: "/roles/edit/:id",
        component: EditRole,
      },
    ],
  },
  {
    path: "/users",
    component: Body,
    children: [
      {
        path: "/users/profile",
        component: AdminProfile,
      },
      {
        path: "/users/changePassword/:id?",
        component: AdminChangePassword,
      },
      {
        path: "/users",
        component: AdminIndex,
      },
      {
        path: "/users/add",
        component: AddAdmin,
      },
      {
        path: "/users/edit/:id",
        component: EditAdmin,
      },
    ],
  },
  {
    path: "/participants",
    component: Body,
    children: [
      {
        path: "/participants",
        component: ParticipantIndex,
      },
      {
        path: "/participants/add",
        component: AddParticipant,
      },
      {
        path: "/participants/edit/:id",
        component: EditParticipant,
      },
      {
        path: "/participants/view/:id",
        component: ViewParticipant,
      },
    ],
  },
  {
    path: "/facilitators",
    component: Body,
    children: [
      {
        path: "/facilitators",
        component: FacilitatorIndex,
      },
      {
        path: "/facilitators/add",
        component: AddFacilitator,
      },
      {
        path: "/facilitators/edit/:id",
        component: EditFacilitator,
      },
    ],
  },
  {
    path: "/courses",
    component: Body,
    children: [
      {
        path: "/courses",
        component: CourseIndex,
      },
      {
        path: "/courses/add",
        component: AddCourse,
      },
      {
        path: "/courses/edit/:id",
        component: EditCourse,
      },
    ],
  },
  {
    path: "/course-reviews",
    component: Body,
    children: [
      {
        path: "/course-reviews",
        component: ReviewsCourse,
      },
    ],
  },
  {
    path: "/course-feedback",
    component: Body,
    children: [
      {
        path: "/course-feedback",
        component: FeedbackCourse,
      },
      {
        path: "/feedback/:id",
        component: SingleFeedbackCourse,
      },
    ],
  },
  {
    path: "/end-course-feedback",
    component: Body,
    children: [
      {
        path: "/end-course-feedback",
        component: EndOfCourseFeedbacks,
      },
    ],
  },
  {
    path: "/enrollment-request",
    component: Body,
    children: [
      {
        path: "/enrollment-request",
        component: EnrolmentCourseRequest,
      },
    ],
  },
  {
    path: "/courseLevels",
    component: Body,
    children: [
      {
        path: "/courseLevels/:id",
        component: CourseLevelIndex,
        meta: {
          breadcrumb: "Course / Level",
        },
      },
      {
        path: "/courseLevels/edit/:id",
        component: EditLevel,
      },
    ],
  },
  {
    path: "/quiz",
    component: Body,
    children: [
      {
        path: "/quiz",
        component: QuizIndex,
      },
      {
        path: "/quiz/add",
        component: AddQuiz,
      },
      {
        path: "/quiz/edit/:id",
        component: EditQuiz,
      },
    ],
  },
  {
    path: "/manualAssessment",
    component: Body,
    children: [
      {
        path: "/manualAssessment",
        component: IndexManualAssessment,
      },
      {
        path: "/manualAssessment/add",
        component: AddManualAssessment,
      },
      {
        path: "/manualAssessment/edit/:id",
        component: EditManualAssessment,
      },
    ],
  },
  {
    path: "/batch",
    component: Body,
    children: [
      {
        path: "/batch",
        component: IndexBatch,
      },
      {
        path: "/batch/add",
        component: AddBatch,
      },
      {
        path: "/batch/edit/:id",
        component: EditBatch,
      },
      {
        path: "/batch/participent/:id",
        component: ParticipentBatch,
      },
      {
        path: "/add/batch/participent/:id",
        component: AddParticipentBatch,
      },
      {
        path: "/edit/batch/material/:id",
        component: EditBatchMaterial,
      },
    ],
  },
  {
    path: "/department",
    component: Body,
    children: [
      {
        path: "/department",
        component: IndexDepartment,
      },
      {
        path: "/department/add",
        component: AddDepartment,
      },
      {
        path: "/department/edit/:id",
        component: EditDepartment,
      },
    ],
  },
  {
    path: "/qualifications",
    component: Body,
    children: [
      {
        path: "/qualifications",
        component: IndexQualification,
      },
      {
        path: "/qualifications/add",
        component: AddQualification,
      },
      {
        path: "/qualification/edit/:id",
        component: EditQualification,
      },
    ],
  },
  {
    path: "/designation",
    component: Body,
    children: [
      {
        path: "/designation",
        component: IndexDesignation,
      },
      {
        path: "/designation/add",
        component: AddDesignation,
      },
      {
        path: "/designation/edit/:id",
        component: EditDesignation,
      },
    ],
  },
  {
    path: "/courseMaterial",
    component: Body,
    children: [
      {
        path: "/courseMaterial/:id",
        component: CourseMaterialIndex,
      },
      {
        path: "/courseMaterial/edit/:id",
        component: EditCourseMaterial,
      },
    ],
  },
  {
    path: "/notifications",
    component: Body,
    children: [
      {
        path: "/notifications",
        component: IndexNotifications,
      },
      {
        path: "/notifications/send",
        component: SendNotifications,
      },
    ],
  },
  {
    path: "/assignment",
    component: Body,
    children: [
      {
        path: "/assignment",
        component: IndexAssignment,
      },
      {
        path: "/assignment/add",
        component: AddAssignment,
      },
      {
        path: "/assignment/edit/:id",
        component: EditAssignment,
      },
    ],
  },
  {
    path: "/how-to",
    component: Body,
    children: [
      {
        path: "/how-to",
        component: INDEXHOWTOVIDEOS,
      },
      {
        path: "/howTo/add",
        component: AddHOWTOVIDEOS,
      },
      {
        path: "/howTo/edit/:id",
        component: EDITHOWTOVIDEOS,
      },
    ],
  },
  {
    path: "/chat",
    component: Body,
    children: [
      {
        path: "/chat",
        component: IndexChat,
      },
      {
        path: "/chat/add",
        component: AddChat,
      },
      {
        path: "/chat/edit/:id",
        component: EditChat,
      },
    ],
  },
  {
    path: "/district",
    component: Body,
    children: [
      {
        path: "/district",
        component: IndexDistrict,
      },
      {
        path: "/district/add",
        component: AddDistrict,
      },
      {
        path: "/district/edit/:id",
        component: EditDistrict,
      },
    ],
  },
  {
    path: "/tahseel",
    component: Body,
    children: [
      {
        path: "/tahseel",
        component: IndexTahseel,
      },
      {
        path: "/tahseel/add",
        component: AddTahseel,
      },
      {
        path: "/tahseel/edit/:id",
        component: EditTahseel,
      },
    ],
  },
  {
    path: "/submitted-assignments",
    component: Body,
    children: [
      {
        path: "/submitted-assignments",
        component: INDEXSUBMITTEDASSIGNMENTS,
      },
      {
        path: "/submissions/:id",
        component: VIEWSUBMITTEDASSIGNMENTS,
      },
      {
        path: "/markAssigment/:id",
        component: VIEWSINGLEASSIGNEMNT,
      },
      {
        path: "/qualifications",
        component: IndexQualification,
      },
    ],
  },
  {
    path: "/user-support",
    component: Body,
    children: [
      {
        path: "/user-support",
        component: IndexSupport,
      },
      {
        path: "/support/view/:id",
        component: ViewSupport,
      },
    ],
  },
  {
    path: "/web-content",
    component: Body,
    children: [
      {
        path: "/web-content",
        component: WebContent,
      },
    ],
  },
  {
    path: "/reports",
    component: Body,
    children: [
      {
        path: "/reports",
        component: ReportIndex,
      },
      {
        path: "/reports/participants/:id",
        component: ReportStudents,
      },
    ],
  },
  {
    path: "/terms",
    component: Body,
    children: [
      {
        path: "/terms",
        component: Terms,
      },
    ],
  },
  {
    path: "/policy",
    component: Body,
    children: [
      {
        path: "/policy",
        component: Policy,
      },
    ],
  },
  {
    path: "/faq",
    component: Body,
    children: [
      {
        path: "/faq",
        component: FaqIndex,
      },
      {
        path: "/faq/add",
        component: FaqAdd,
      },
      {
        path: "/faq/edit/:id",
        component: FaqEdit,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem("token");
  if (!to.meta?.public && !isAuthenticated) {
    next({ name: "Login 1" });
  } else {
    next();
  }
});
export default router;
