export default {
  methods: {
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      // Allow Ctrl+A, Ctrl+C, Ctrl+V
      if (
        (evt.ctrlKey &&
          (charCode === 65 || charCode === 67 || charCode === 86)) ||
        // Allow backspace, tab, delete, enter, and escape
        [8, 9, 13, 27, 46].includes(charCode)
      ) {
        return;
      }
      // Allow only numbers (0-9)
      if (
        (evt.keyCode >= 48 && evt.keyCode <= 57) ||
        (evt.keyCode >= 96 && evt.keyCode <= 105)
      ) {
        return; // Allow the event to proceed
      }

      evt.preventDefault();
    },
    validateText(evt) {
      if (evt.key >= "0" && evt.key <= "9") {
        evt.preventDefault();
      }
    },
  },
};
