<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="card position-relative" style="top: 50px">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 mt-3">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Privacy Policy
              </h5>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <form @submit.prevent="submit">
            <label>Privacy Policy</label>
            <quill-editor v-model:value="policy" :options="options" />
            <div class="row">
              <div class="col-md-12">
                <button
                  type="submit"
                  class="custom-btn primary-bg-color text-white btn-block w-100 mt-3"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      policy: null,
      options: {
        modules: {
          toolbar: [
            "bold",
            "italic",
            "underline",
            { list: "ordered" },
            { list: "bullet" },
          ],
        },
      },
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      const key = "policy";
      this.$store
        .dispatch("setting/getAll", { key })
        .then((response) => {
          this.loading = false;
          if (response.data?.value) {
            this.policy = response.data.value;
          }
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    submit() {
      this.loading = true;
      const data = {
        key: "policy",
        value: this.policy,
      };
      this.$store
        .dispatch("setting/store", { data })
        .then((response) => {
          this.loading = false;
          this.$toast.show("Successfuly updated", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
  },
};
</script>

<style scoped>
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  min-height: 500px;
}
</style>
