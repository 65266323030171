<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <button
      class="btn primary-bg-color text-white mt-5"
      @click="$router.go(-1)"
    >
      Back
    </button>
    <div class="card mt-3">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 mt-3">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                {{ items.title }}
              </h5>
            </div>
          </div>
          <div class="col-md-12">
            <div
              v-if="showFilter"
              class="p-4 mt-3 col-md-8 offset-md-2 mb-3"
              style="
                border-radius: 16px;
                background-color: rgba(0, 116, 206, 0.2);
              "
            >
              <h5>Search Participants <span class="text-danger">*</span></h5>
              <div class="form-group row">
                <div class="col-md-6 mt-3">
                  <label class="primary-text-color">Department</label>
                  <select
                    class="custom-form-control"
                    v-model="department_id"
                    @change="fetchStudents"
                  >
                    <option value="">Select Department</option>
                    <option
                      v-for="department in departments"
                      :value="department.id"
                    >
                      {{ department.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-6 mt-3">
                  <label class="primary-text-color">Qualification</label>
                  <select
                    class="custom-form-control"
                    v-model="qualification"
                    @change="fetchStudents"
                  >
                    <option value="">Select Qualification</option>
                    <option
                      v-for="qualification in qualifications"
                      :value="qualification.name"
                    >
                      {{ qualification.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group mt-3">
                <label class="primary-text-color">Designation</label>
                <select
                  class="custom-form-control"
                  v-model="designation"
                  @change="fetchStudents"
                >
                  <option value="" selected>Select Designation</option>
                  <option
                    v-for="designation in designations"
                    :value="designation.name"
                  >
                    {{ designation.name }}
                  </option>
                </select>
              </div>
            </div>

            <!-- Button or checkbox to toggle filter visibility -->
            <button
              class="btn primary-bg-color text-white mb-3"
              @click="toggleFilter"
            >
              <span v-if="showFilter == false">Student Filter</span>
              <span v-if="showFilter == true">Hide Filter</span>
            </button>

            <data-table
              :columns="columns"
              @checkboxUpdated="checkboxUpdated"
              :batchStudentCheck="batchStudentCheck"
              @handle-status="handleStatus"
              :status="status"
              :items="students"
              :module="module"
            ></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dataTable from "@/components/dataTable.vue";

export default {
  components: {
    dataTable,
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          text: "Participant Name",
          value: "name",
        },
        {
          text: "Designation",
          value: "designation",
        },
        {
          text: "Department",
          value: "department",
          secondValue: "name",
        },
        {
          text: "Qualification",
          value: "qualification",
        },
      ],
      items: [],
      module: "batch",
      permissions: null,
      isEdit: false,
      isDelete: false,
      isView: true,
      batchId: "",
      status: true,
      departments: [],
      qualification: "",
      designation: "",
      department_id: "",
      students: [],
      showFilter: false,
      batchStudentCheck: true,
      qualifications: [],
      designations: [],
    };
  },
  mounted() {
    this.batchId = this.$route.params.id;
    this.fetch(this.batchId);
    this.fetchDepartments();
    this.fetchStudents();
    this.fetchQualifications();
    this.fetchDesignations();
    this.permissions = localStorage.getItem("permissions");
    if (this.permissions && this.permissions.includes("batch-Edit")) {
      this.isEdit = true;
    }
    if (this.permissions && this.permissions.includes("batch-Delete")) {
      this.isDelete = true;
    }
  },
  methods: {
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("batch/getBatchStudents", { id })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    fetchDepartments() {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("department/getAll", { search, pagination })
        .then((response) => {
          this.departments = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    fetchQualifications() {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("qualification/getAll", { search, pagination })
        .then((response) => {
          this.qualifications = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    fetchDesignations() {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("designation/getAll", { search, pagination })
        .then((response) => {
          this.designations = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    fetchStudents() {
      this.loading = true;
      const department = this.department_id;
      const qualification = this.qualification;
      const designation = this.designation;
      this.$store
        .dispatch("participants/filterStudents", {
          department,
          qualification,
          designation,
        })
        .then((response) => {
          this.loading = false;
          this.students = response;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    checkboxUpdated(stu) {
      this.loading = true;
      const data = {
        batch_id: this.batchId,
        student_id: stu.id,
      };
      this.$store
        .dispatch("batch/addStudentBatch", { data })
        .then((response) => {
          if (response.success == true) {
            this.loading = false;
            this.$toast.show("Successfuly added.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            this.fetchStudents();
          } else {
            this.errors = response.error;
            console.log(response.error);
          }
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
  },
};
</script>
