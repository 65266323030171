<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-10 mx-auto mt-5">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Create Chat Group
              </h5>
            </div>
            <form @submit.prevent="submit">
              <div class="form-group mb-3">
                <label>Group Title <span class="text-danger">*</span></label>
                <input
                  type="text"
                  v-model="groupTitle"
                  class="custom-form-control"
                  required
                />
              </div>
              <div class="form-group mb-3">
                <label>Select Batch <span class="text-danger">*</span></label>
                <select
                  class="custom-form-control"
                  v-model="batchId"
                  @change="fetchStudents"
                  required
                >
                  <option v-for="batch in batches" :value="batch.encrypted_id">
                    {{ batch.title }}
                  </option>
                </select>
              </div>
              <section v-if="batchStudents.length > 0">
                <h6>Batch Participants</h6>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th style="width: 50px"></th>
                      <th v-for="column in columns">{{ column.text }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="student in batchStudents">
                      <td>
                        <input
                          type="checkbox"
                          v-model="groupParticipants"
                          :value="student.student.id"
                          class="form-check-input"
                        />
                      </td>
                      <td>{{ student.student.name }}</td>
                    </tr>
                  </tbody>
                </table>
              </section>
              <div class="row">
                <div class="col-md-6">
                  <button
                    type="submit"
                    class="custom-btn primary-bg-color text-white btn-block w-100 mt-3"
                  >
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <button
                    type="button"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    @click="clearAll"
                  >
                    Clear All
                  </button>
                </div>
                <div class="col-md-3">
                  <router-link
                    to="/chat"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dataTable from "@/components/dataTable.vue";

export default {
  components: {
    dataTable,
  },
  data() {
    return {
      loading: false,
      batches: [],
      batchId: null,
      batchStudents: [],
      columns: [
        {
          text: "Name",
          value: "student",
          secondValue: "name",
        },
      ],
      groupParticipants: [],
      groupTitle: null,
    };
  },
  mounted() {
    this.fetchBatches();
  },
  methods: {
    submit() {
      this.loading = true;
      const data = {
        name: this.groupTitle,
        participants: this.groupParticipants,
        batchId: this.batchId,
        userId: localStorage.getItem("uid"),
      };
      this.$store
        .dispatch("chat/store", { data })
        .then((response) => {
          this.loading = false;
          this.$toast.show("Successfuly added.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.groupTitle = null;
          this.batchId = null;
          this.groupParticipants = [];
          this.batchStudents = [];
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    fetchStudents() {
      this.loading = true;
      const id = this.batchId;
      this.$store
        .dispatch("batch/getBatchStudents", { id })
        .then((response) => {
          this.loading = false;
          this.batchStudents = response.data.enrollments.data;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    fetchBatches() {
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("batch/getAll", { search, pagination })
        .then((response) => {
          this.batches = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
