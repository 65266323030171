export var menuItems = {
  data: [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: "stroke-home",
      iconf: "fill-home",
      type: "link",
      active: false,
      public: true,
    },
    {
      title: "Administration",
      icon: "stroke-user",
      iconf: "fill-user",
      type: "sub",
      active: false,
      public: false,
      module: ["admins_view", "roles_view"],
      children: [
        {
          path: "/users",
          title: "Admins",
          type: "link",
          active: false,
          module: "admins_view",
          public: false,
        },
        {
          path: "/roles",
          title: "Roles",
          type: "link",
          active: false,
          module: "roles_view",
          public: false,
        },
      ],
    },
    {
      title: "Participant Management",
      path: "/participants",
      icon: "participants",
      iconf: "participantsFill",
      svgType: "static",
      type: "link",
      active: false,
      public: false,
      module: "participants_view",
    },
    {
      title: "Facilitator Management",
      path: "/facilitators",
      icon: "facilitator",
      iconf: "facilitatorFill",
      svgType: "static",
      type: "link",
      active: false,
      public: false,
      module: "facilitators_view",
    },
    {
      title: "Batch Management",
      path: "/batch",
      icon: "stroke-to-do",
      iconf: "fill-to-do",
      type: "link",
      active: false,
      public: false,
      module: "batch_view",
    },
    {
      title: "Course Management",
      icon: "stroke-knowledgebase",
      iconf: "fill-knowledgebase",
      type: "sub",
      active: false,
      public: false,
      module: ["courses_view"],
      children: [
        {
          path: "/courses",
          title: "courses",
          type: "link",
          active: false,
          module: "courses_view",
          public: false,
        },
        {
          path: "/enrollment-request",
          title: "Enrollment Requests",
          type: "link",
          active: false,
          module: "courses_view",
          public: false,
        },
       
      ],
    },
    {
      title: "Assignment management",
      icon: "stroke-task",
      iconf: "fill-task",
      type: "sub",
      active: false,
      public: false,
      module: ["quiz_view", "manualAssessment_view"],
      children: [
        {
          path: "/quiz",
          title: "Quiz",
          type: "link",
          active: false,
          module: "quiz_view",
          public: false,
        },
        {
          path: "/manualAssessment",
          title: "Manual Assessment",
          type: "link",
          active: false,
          module: "manualAssessment_view",
          public: false,
        },
      ],
    },
    {
      title: "Assessment Tool",
      path: "/assignment",
      icon: "stroke-button",
      iconf: "fill-button",
      type: "link",
      active: false,
      public: false,
      module: "assessment_view",
    },
    {
      title: "Chat Groups",
      path: "/chat",
      icon: "stroke-chat",
      iconf: "fill-chat",
      type: "link",
      active: false,
      public: false,
      module: "chat_group",
    },
    {
      title: "Notifications",
      path: "/notifications",
      icon: "notification",
      iconf: "notification",
      type: "link",
      active: false,
      public: false,
      module: "notification_view",
    },
    {
      title: "Website management",
      icon: "webContent",
      iconf: "webContentFill",
      svgType: "static",
      type: "sub",
      active: false,
      public: false,
      module: ["web_pages_view","web_pages_add","web_content_edit","faqs_view","terms_view","policy_view"],
      children: [
        {
          path: "/how-to",
          title: "How To Add",
          type: "link",
          active: false,
          module: "web_pages_add",
          public: false,
          icon: "webContent",
          iconf: "webContentFill",
          svgType: "static",
        },
        {
          path: "/web-content",
          title: "Website Content",
          type: "link",
          active: false,
          module: "web_content_edit",
          public: false,
          icon: "webContent",
          iconf: "webContentFill",
          svgType: "static",
        },
        {
          path: "/faq",
          title: "FAQs",
          type: "link",
          active: false,
          module: "faqs_view",
          public: false,
          icon: "webContent",
          iconf: "webContentFill",
          svgType: "static",
        },
        {
          path: "/terms",
          title: "Terms & Conditions",
          type: "link",
          active: false,
          module: "terms_view",
          public: false,
          icon: "webContent",
          iconf: "webContentFill",
          svgType: "static",
        },
        {
          path: "/policy",
          title: "Privacy Policy",
          type: "link",
          active: false,
          module: "policy_view",
          public: false,
          icon: "webContent",
          iconf: "webContentFill",
          svgType: "static",
        },
      ],
    },
    // {
    //   title: "Website Content",
    //   path: "/web-content",
    //   icon: "webContent",
    //   iconf: "webContentFill",
    //   svgType:"static",
    //   type: "link",
    //   active: false,
    //   public: false,
    //   module: "web_content_edit",
    // },

    {
      title: "Feedbacks",
      icon: "stroke-charts",
      iconf: "fill-charts",
      type: "sub",
      active: false,
      public: false,
      module: ["courses_view"],
      children: [
        {
          path: "/course-reviews",
          title: "Reviews",
          type: "link",
          active: false,
          module: "courses_view",
          public: false,
        },
        {
          path: "/course-feedback",
          title: "In Service",
          type: "link",
          active: false,
          module: "courses_view",
          public: false,
        },
        {
          path: "/end-course-feedback",
          title: "Completed  Course Feedbacks",
          type: "link",
          active: false,
          module: "courses_view",
          public: false,
        },
      ],
    },
    {
      title: "Support",
      path: "/user-support",
      icon: "support",
      iconf: "supportFill",
      svgType: "static",
      type: "link",
      active: false,
      public: false,
      module: "support_view",
    },
    {
      title: "Reports",
      path: "/reports",
      icon: "stroke-support-tickets",
      iconf: "fill-support-tickets",
      type: "link",
      active: false,
      public: false,
      module: "reports_view",
    },
    {
      title: "Settings",
      icon: "stroke-blog",
      iconf: "fill-blog",
      type: "sub",
      active: false,
      public: false,
      module: [
        "department_view",
        "qualificaion_view",
        "designation_view",
        "district_view",
        "tahseel_view",
      ],
      children: [
        {
          path: "/department",
          title: "Department",
          type: "link",
          active: false,
          module: "department_view",
          public: false,
        },
        {
          path: "/qualifications",
          title: "Qualification",
          type: "link",
          active: false,
          module: "qualification_view",
          public: false,
        },
        {
          path: "/designation",
          title: "Designation",
          type: "link",
          active: false,
          module: "designation_view",
          public: false,
        },
        {
          path: "/district",
          title: "Districts",
          type: "link",
          active: false,
          module: "district_view",
          public: false,
        },
        {
          path: "/tahseel",
          title: "Tehsil",
          type: "link",
          active: false,
          module: "tahseel_view",
          public: false,
        },
      ],
    },
  ],
};
