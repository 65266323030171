<template class="">
  <div class="chat-main-div">
    <!-- Chat Conversation -->
    <transition name="chat">
      <div
        class="chatbox-1 bg-white position-fixed bottom-0"
        v-if="chatVisible"
      >
        <div
          class="chat-head main-bg-blue px-sm-3 px-2 pe-sm-4 pe-3 d-flex justify-content-between align-items-center"
        >
          <div class="d-flex align-items-center">
            <img
              v-if="selectedGroup?.user_profile?.profile_pic"
              :src="selectedGroup?.user_profile?.profile_pic?.image_url"
              class="chat-profile-image-small"
            />
            <img
              v-if="
                selectedGroup.user_profile &&
                selectedGroup.user_profile.profile_pic == null
              "
              src="@/assets/images/man.png"
              class="chat-profile-image-small"
            />
            <img
              v-if="!selectedGroup.user_profile"
              src="@/assets/images/group-chat-avatar.png"
              class="chat-profile-image-small"
            />
            <div
              class="fs-12 text-white ps-sm-3 ps-2"
              v-if="selectedGroup.user_profile"
            >
              {{ selectedGroup.user_profile.name }}
            </div>
            <div class="fs-12 text-white ps-sm-3 ps-2" v-else>
              {{ selectedGroup?.name }}
            </div>
          </div>
          <div class="" @click="hideChat">
            <img
              class="d-lg-block d-none"
              src="@/assets/images/chat-close-btn.png"
              alt=""
            />
            <img
              class="d-lg-none d-block"
              src="@/assets/images/chat-back-icon.png"
              alt=""
            />
          </div>
        </div>
        <div class="chatting-body overflow-y-auto" id="chat-box">
          <div
            v-if="messages.length > 0"
            v-for="message in messages"
            :class="{
              rtl: message.user_id == pusherId,
              'mb-3': message.user_id != pusherId,
            }"
          >
            <div
              class="d-flex align-items-center"
              v-if="message.conversation_id == selectedGroup.conversation_id"
            >
              <div>
                <img
                  v-if="message?.user_profile?.profile_pic"
                  :src="message?.user_profile?.profile_pic?.image_url"
                  class="chat-profile-image-small"
                />
                <img
                  v-if="
                    message.user_profile &&
                    message.user_profile.profile_pic == null
                  "
                  src="@/assets/images/man.png"
                  class="chat-profile-image-small"
                />
                <img
                  v-if="!message.user_profile"
                  src="@/assets/images/group-chat-avatar.png"
                  class="chat-profile-image-small"
                />
              </div>
              <span
                class="fs-12"
                :class="{
                  'blue-chat': message.user_id != pusherId,
                  'main-bg-blue': message.user_id != pusherId,
                  'ms-2': message.user_id != pusherId,
                  'gray-chat': message.user_id == pusherId,
                  'me-2': message.user_id == pusherId,
                }"
                >{{ message.text }}</span
              >
            </div>
            <div class="fs-12 gray-color ps-5 mt-sm-2">
              <span>{{ message?.user_profile?.name }}</span>
              <span class="ps-3">{{ message?.created_at }}</span>
            </div>
          </div>
        </div>
        <div class="px-3">
          <form @submit.prevent="sendMessage">
            <input
              type="text"
              class="chat-texting w-100 mb-2"
              placeholder="Type here"
              v-model="newMessage"
              required
            />
            <div class="d-flex align-items-center fs-12 text-white ms-2">
              <!-- <label for="chat-attachment-btn">
                <input type="file" id="chat-attachment-btn" hidden />
                <img src="@/assets/images/chat-attachment-icon.png" />
              </label> -->
              <button
                type="submit"
                class="btn btn-primary d-flex align-items-center primary-bg-color px-3 py-2 rounded ms-3"
              >
                <img src="@/assets/images/chat-send-icon.png" alt="" />
                <span class="ps-2"> Send </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </transition>

    <!-- Chat List -->
    <transition name="list">
      <div
        class="chatbox-2 bg-white position-fixed bottom-0"
        v-if="listVisible"
      >
        <div
          class="chat-head main-bg-blue px-3 pe-4 d-flex justify-content-between align-items-center"
          @click="hideList"
        >
          <div class="d-flex align-items-center">
            <div class="fs-12 text-white">Chat List</div>
          </div>
          <div class="">
            <img src="@/assets/images/chat-list-close-icon.png" alt="" />
          </div>
        </div>
        <div class="chatting-body overflow-y-auto">
          <div
            class="d-flex justify-content-between align-items-center mb-4 conversation-item"
            v-for="conversation in conversations"
            @click="showChat(conversation)"
          >
            <div class="d-flex align-items-center">
              <div>
                <img
                  v-if="conversation?.user_profile?.profile_pic"
                  :src="conversation?.user_profile?.profile_pic?.image_url"
                  class="chat-profile-image"
                />
                <img
                  v-if="
                    conversation.user_profile &&
                    conversation.user_profile.profile_pic == null
                  "
                  src="@/assets/images/man.png"
                  class="chat-profile-image"
                />
                <img
                  v-if="!conversation.user_profile"
                  src="@/assets/images/group-chat-avatar.png"
                  class="chat-profile-image"
                />
              </div>
              <div class="ps-2">
                <div
                  class="small fw-semibold blue-color"
                  v-if="conversation.user_profile"
                >
                  {{ conversation.user_profile.name }}
                </div>
                <div class="small fw-semibold blue-color" v-else>
                  {{ conversation.name }}
                </div>
              </div>
            </div>
            <div class="text-center">
              <span
                v-if="conversation.msg_count > 0"
                class="fs-10 fw-semibold main-bg-blue rounded-circle text-white p-1 px-2"
                >{{ conversation.msg_count }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <div class="chat-icon" @click="showList">
    <img src="@/assets/images/chat-icon.png" alt="" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      listVisible: false,
      chatVisible: false,
      conversations: [],
      messages: [],
      pusherId: localStorage.getItem("pusher_id"),
      newMessage: null,
      channelName: null,
      conversationId: null,
      selectedGroup: null,
      pusherChannel: null,
      lastUserId: null,
    };
  },
  inject: ["pusherService"],
  created() {
    this.channelName = "LMS." + this.pusherId;
  },
  mounted() {
    this.pusher();
  },
  onUnmounted() {
    this.pusherService.unsubscribe(this.channelName);
  },
  methods: {
    scrollToBottom() {
      this.$nextTick(() => {
        if (document.getElementById("chat-box")) {
          let chatBox = document.getElementById("chat-box");
          chatBox.scrollTop = chatBox.scrollHeight;
        }
      });
    },
    sendMessage() {
      const data = {
        conversation_id: this.conversationId,
        user_id: parseInt(this.pusherId),
        text: this.newMessage,
        user_profile: null,
        receiver_id: null,
      };
      if (!this.selectedGroup.batch_id) {
        data.receiver_id = this.selectedGroup.user_id;
      }
      this.messages.push(data);
      this.newMessage = null;
      this.scrollToBottom();
      this.lastUserId = this.pusherId;
      this.$store
        .dispatch("chat/sendMessage", { data })
        .then(() => {})
        .catch((e) => {
          console.log(e);
        });
    },
    pusher() {
      this.pusherChannel = this.pusherService.subscribe(this.channelName);
      this.pusherChannel.bind(this.channelName, (data) => {
        this.conversations.forEach((item) => {
          if (
            item.conversation_id &&
            item.conversation_id == data.data.conversation_id
          ) {
            item.msg_count += 1;
          } else if (item.id == data.data.conversation_id) {
            item.msg_count += 1;
          }
        });
        if (this.chatVisible == true) {
          this.messages.push(data.data);
          this.scrollToBottom();
        }
      });
    },
    fetchChatParticipants() {
      const id = this.pusherId;
      this.$store
        .dispatch("chat/getParticipants", { id })
        .then((response) => {
          this.conversations = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    showList() {
      this.fetchChatParticipants();
      this.listVisible = true;
    },
    hideList() {
      this.listVisible = false;
    },
    showChat(group) {
      this.selectedGroup = group;
      this.conversationId = group.conversation_id;
      this.messages = [];
      this.chatVisible = true;
      const id = group.conversation_id;
      this.$store
        .dispatch("chat/getConversation", { id })
        .then((response) => {
          this.messages = response.data;
          this.scrollToBottom();
        })
        .catch((e) => {
          console.log(e);
        });
      const data = {
        conversation_id: this.conversationId,
        user_id: this.pusherId,
      };
      this.$store
        .dispatch("chat/readMessages", { data })
        .then(() => {
          group.msg_count = 0;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    hideChat() {
      this.chatVisible = false;
    },
  },
};
</script>
<style scoped>
.conversation-item:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
</style>
