<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mx-auto mt-5">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Add Role
              </h5>
            </div>
            <form @submit.prevent="submit" ref="addRole">
              <label class="primary-text-color"
                >Name <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="custom-form-control"
                required
                v-model="role.name"
              />
              <table class="table mt-3">
                <tbody>
                  <tr>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="checkAll"
                        @change="toggleCheckAll"
                      />
                      <label for="checkAll" style="margin-left: 5px"
                        >Check All</label
                      >
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">Admins</td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-1"
                        v-model="role.permissions"
                        :value="getName('admins', 'view')"
                      />
                      <label for="addCheckbox-1">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-2"
                        v-model="role.permissions"
                        :value="getName('admins', 'add')"
                      />
                      <label for="addCheckbox-2">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="editCheckbox-3"
                        v-model="role.permissions"
                        :value="getName('admins', 'edit')"
                      />
                      <label for="editCheckbox-3">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="block-4"
                        v-model="role.permissions"
                        :value="getName('admins', 'block')"
                      />
                      <label for="block-4">Block</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="approve-4"
                        v-model="role.permissions"
                        :value="getName('admins', 'approve')"
                      />
                      <label for="approve-4">Unblock</label>
                    </td>
                    <!-- <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="notification-4"
                        v-model="role.permissions"
                        :value="getName('admins', 'send_notification')"
                      />
                      <label for="notification-4">Send Notificatioin</label>
                    </td> -->
                    
                  </tr>
                  <tr>
                    <td style="font-weight: bold">Roles</td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-6"
                        v-model="role.permissions"
                        :value="getName('roles', 'view')"
                      />
                      <label for="addCheckbox-6">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-7"
                        v-model="role.permissions"
                        :value="getName('roles', 'add')"
                      />
                      <label for="addCheckbox-7">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="editCheckbox-8"
                        v-model="role.permissions"
                        :value="getName('roles', 'edit')"
                      />
                      <label for="editCheckbox-8">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="deleteCheckbox-9"
                        v-model="role.permissions"
                        :value="getName('roles', 'delete')"
                      />
                      <label for="deleteCheckbox-9">Delete</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">Participants</td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-10"
                        v-model="role.permissions"
                        :value="getName('participants', 'view')"
                      />
                      <label for="addCheckbox-10">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-11"
                        v-model="role.permissions"
                        :value="getName('participants', 'add')"
                      />
                      <label for="addCheckbox-11">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="editCheckbox-12"
                        v-model="role.permissions"
                        :value="getName('participants', 'edit')"
                      />
                      <label for="editCheckbox-12">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="block-13"
                        v-model="role.permissions"
                        :value="getName('participants', 'block')"
                      />
                      <label for="block-13">Block</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="approve-14"
                        v-model="role.permissions"
                        :value="getName('participants', 'approve')"
                      />
                      <label for="approve-14">Unblock</label>
                    </td>
                    <!-- <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="notification-15"
                        v-model="role.permissions"
                        :value="getName('participants', 'send_notification')"
                      />
                      <label for="notification-15">Send Notificatioin</label>
                    </td> -->
                   
                  </tr>
                  <tr>
                    <td style="font-weight: bold">Facilitator</td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-17"
                        v-model="role.permissions"
                        :value="getName('facilitators', 'view')"
                      />
                      <label for="addCheckbox-17">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-18"
                        v-model="role.permissions"
                        :value="getName('facilitators', 'add')"
                      />
                      <label for="addCheckbox-18">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="editCheckbox-19"
                        v-model="role.permissions"
                        :value="getName('facilitators', 'edit')"
                      />
                      <label for="editCheckbox-19">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="block-20"
                        v-model="role.permissions"
                        :value="getName('facilitators', 'block')"
                      />
                      <label for="block-20">Block</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="approve-21"
                        v-model="role.permissions"
                        :value="getName('facilitators', 'approve')"
                      />
                      <label for="approve-21">Unblock</label>
                    </td>
                    <!-- <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="notification-22"
                        v-model="role.permissions"
                        :value="getName('facilitators', 'send_notification')"
                      />
                      <label for="notification-22">Send Notificatioin</label>
                    </td> -->
                  
                  </tr>
                  <tr>
                    <td style="font-weight: bold">Batch</td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-24"
                        v-model="role.permissions"
                        :value="getName('batch', 'view')"
                      />
                      <label for="addCheckbox-24">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-25"
                        v-model="role.permissions"
                        :value="getName('batch', 'add')"
                      />
                      <label for="addCheckbox-25">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="editCheckbox-26"
                        v-model="role.permissions"
                        :value="getName('batch', 'edit')"
                      />
                      <label for="editCheckbox-26">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="deleteCheckbox-27"
                        v-model="role.permissions"
                        :value="getName('batch', 'delete')"
                      />
                      <label for="deleteCheckbox-27">Delete</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">Department</td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-28"
                        v-model="role.permissions"
                        :value="getName('department', 'view')"
                      />
                      <label for="addCheckbox-28">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-29"
                        v-model="role.permissions"
                        :value="getName('department', 'add')"
                      />
                      <label for="addCheckbox-29">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="editCheckbox-30"
                        v-model="role.permissions"
                        :value="getName('department', 'edit')"
                      />
                      <label for="editCheckbox-30">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="deleteCheckbox-31"
                        v-model="role.permissions"
                        :value="getName('department', 'delete')"
                      />
                      <label for="deleteCheckbox-31">Delete</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">Designation</td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-32"
                        v-model="role.permissions"
                        :value="getName('designation', 'view')"
                      />
                      <label for="addCheckbox-32">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-33"
                        v-model="role.permissions"
                        :value="getName('designation', 'add')"
                      />
                      <label for="addCheckbox-33">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="editCheckbox-34"
                        v-model="role.permissions"
                        :value="getName('designation', 'edit')"
                      />
                      <label for="editCheckbox-34">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="deleteCheckbox-35"
                        v-model="role.permissions"
                        :value="getName('designation', 'delete')"
                      />
                      <label for="deleteCheckbox-35">Delete</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">Qualification</td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-36"
                        v-model="role.permissions"
                        :value="getName('qualification', 'view')"
                      />
                      <label for="addCheckbox-36">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-37"
                        v-model="role.permissions"
                        :value="getName('qualification', 'add')"
                      />
                      <label for="addCheckbox-37">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="editCheckbox-38"
                        v-model="role.permissions"
                        :value="getName('qualification', 'edit')"
                      />
                      <label for="editCheckbox-38">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="deleteCheckbox-39"
                        v-model="role.permissions"
                        :value="getName('qualification', 'delete')"
                      />
                      <label for="deleteCheckbox-39">Delete</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">Course</td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-41"
                        v-model="role.permissions"
                        :value="getName('courses', 'view')"
                      />
                      <label for="addCheckbox-41">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-42"
                        v-model="role.permissions"
                        :value="getName('courses', 'add')"
                      />
                      <label for="addCheckbox-42">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="editCheckbox-43"
                        v-model="role.permissions"
                        :value="getName('courses', 'edit')"
                      />
                      <label for="editCheckbox-43">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="deleteCheckbox-44"
                        v-model="role.permissions"
                        :value="getName('courses', 'delete')"
                      />
                      <label for="deleteCheckbox-44">Delete</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">Quiz</td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-45"
                        v-model="role.permissions"
                        :value="getName('quiz', 'view')"
                      />
                      <label for="addCheckbox-45">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-46"
                        v-model="role.permissions"
                        :value="getName('quiz', 'add')"
                      />
                      <label for="addCheckbox-46">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="editCheckbox-47"
                        v-model="role.permissions"
                        :value="getName('quiz', 'edit')"
                      />
                      <label for="editCheckbox-47">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="deleteCheckbox-48"
                        v-model="role.permissions"
                        :value="getName('quiz', 'delete')"
                      />
                      <label for="deleteCheckbox-48">Delete</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">Manual Assessment</td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-49"
                        v-model="role.permissions"
                        :value="getName('manualAssessment', 'view')"
                      />
                      <label for="addCheckbox-49">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-50"
                        v-model="role.permissions"
                        :value="getName('manualAssessment', 'add')"
                      />
                      <label for="addCheckbox-50">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="editCheckbox-51"
                        v-model="role.permissions"
                        :value="getName('manualAssessment', 'edit')"
                      />
                      <label for="editCheckbox-51">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="deleteCheckbox-52"
                        v-model="role.permissions"
                        :value="getName('manualAssessment', 'delete')"
                      />
                      <label for="deleteCheckbox-52">Delete</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">Assessment Tool</td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-53"
                        v-model="role.permissions"
                        :value="getName('assessment', 'view')"
                      />
                      <label for="addCheckbox-53">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-54"
                        v-model="role.permissions"
                        :value="getName('assessment', 'add')"
                      />
                      <label for="addCheckbox-54">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="editCheckbox-55"
                        v-model="role.permissions"
                        :value="getName('assessment', 'edit')"
                      />
                      <label for="editCheckbox-55">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="deleteCheckbox-56"
                        v-model="role.permissions"
                        :value="getName('assessment', 'delete')"
                      />
                      <label for="deleteCheckbox-56">Delete</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">Web Pages</td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-57"
                        v-model="role.permissions"
                        :value="getName('web_pages', 'view')"
                      />
                      <label for="addCheckbox-57">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-58"
                        v-model="role.permissions"
                        :value="getName('web_pages', 'add')"
                      />
                      <label for="addCheckbox-58">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="editCheckbox-59"
                        v-model="role.permissions"
                        :value="getName('web_pages', 'edit')"
                      />
                      <label for="editCheckbox-59">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="deleteCheckbox-60"
                        v-model="role.permissions"
                        :value="getName('web_pages', 'delete')"
                      />
                      <label for="deleteCheckbox-60">Delete</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">Notifications</td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-61"
                        v-model="role.permissions"
                        :value="getName('notification', 'view')"
                      />
                      <label for="addCheckbox-61">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-62"
                        v-model="role.permissions"
                        :value="getName('notification', 'add')"
                      />
                      <label for="addCheckbox-62">Send</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">Website Content</td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-63"
                        v-model="role.permissions"
                        :value="getName('web_content', 'edit')"
                      />
                      <label for="addCheckbox-63">Modify</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">Reports</td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-64"
                        v-model="role.permissions"
                        :value="getName('reports', 'view')"
                      />
                      <label for="addCheckbox-64">View</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">Terms and Conditions</td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-65"
                        v-model="role.permissions"
                        :value="getName('terms', 'view')"
                      />
                      <label for="addCheckbox-65">Modify</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">Privacy Policy</td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-66"
                        v-model="role.permissions"
                        :value="getName('policy', 'view')"
                      />
                      <label for="addCheckbox-66">Modify</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">FAQs</td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-67"
                        v-model="role.permissions"
                        :value="getName('faqs', 'add')"
                      />
                      <label for="addCheckbox-67">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-68"
                        v-model="role.permissions"
                        :value="getName('faqs', 'edit')"
                      />
                      <label for="addCheckbox-68">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-69"
                        v-model="role.permissions"
                        :value="getName('faqs', 'view')"
                      />
                      <label for="addCheckbox-69">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input
                        type="checkbox"
                        id="addCheckbox-70"
                        v-model="role.permissions"
                        :value="getName('faqs', 'delete')"
                      />
                      <label for="addCheckbox-70">Delete</label>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="row">
                <div class="col-md-6">
                  <button
                    type="submit"
                    class="custom-btn primary-bg-color text-white btn-block w-100 mt-3"
                  >
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <button
                    type="button"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    @click="clearAll"
                  >
                    Clear All
                  </button>
                </div>
                <div class="col-md-3">
                  <router-link
                    to="/roles"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";

export default {
  data() {
    return {
      role: {
        name: "",
        permissions: [],
      },
      loading: false,
      modules: [],
    };
  },
  mounted() {
    this.fetchModules();
  },
  methods: {
    submit() {
      this.loading = true;
      const data = toRaw(this.role);
      this.$store
        .dispatch("roles/store", { data })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.$toast.show("Successfuly added", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            this.role.name = "";
            this.role.permissions = [];
            document.querySelector(
              'input[type="checkbox"]#checkAll'
            ).checked = false;
          } else {
            this.$toast.show("Failed to save", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
    fetchModules() {
      this.loading = true;
      this.$store
        .dispatch("module/getAll")
        .then((response) => {
          this.loading = false;
          this.modules = response.data;
          this.admins = this.modules.filter((permission) =>
            permission.name.startsWith("admins_")
          );
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    getName(module, action) {
      const permission = this.modules.find(
        (permission) => permission.name === `${module}_${action}`
      );
      return permission ? permission.name : null;
    },
    toggleCheckAll(event) {
      const checkboxes = document.querySelectorAll(
        'input[type="checkbox"]:not(#checkAll)'
      );
      checkboxes.forEach((checkbox) => {
        checkbox.checked = event.target.checked;
        if (
          checkbox.checked &&
          !this.role.permissions.includes(checkbox.value)
        ) {
          this.role.permissions.push(checkbox.value);
        } else if (!checkbox.checked) {
          const index = this.role.permissions.indexOf(checkbox.value);
          if (index !== -1) {
            this.role.permissions.splice(index, 1);
          }
        }
      });
    },
    clearAll() {
      this.role.name = "";
      this.role.permissions = [];
      document.querySelector('input[type="checkbox"]#checkAll').checked = false;
    },
  },
};
</script>

<style>
.checkbox-label input[type="checkbox"] {
  margin-right: 2px;
}

.table td {
  padding: 0.5rem;
}

.checkbox-label label {
  display: inline-block;
}

.table > :not(caption) > * > * {
  border-bottom-width: 0 !important;
}

.low-margin {
  width: 15% !important;
}
</style>
