<template>
  <div>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row" v-if="admin">
        <div class="col-md-3 mt-5">
          <div class="card">
            <div class="card-body">
              <h6>Districts</h6>
              <h5 class="text-primary">{{ data?.districts ?? 0 }}</h5>
              <p class="text-secondary">
                Number of Districts Registered in System
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-3 mt-5">
          <div class="card">
            <div class="card-body">
              <h6>Courses</h6>
              <h5 class="text-danger">{{ data?.courses ?? 0 }}</h5>
              <p class="text-secondary">
                Number of Courses Registered in System
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-3 mt-5">
          <div class="card">
            <div class="card-body">
              <h6>Participants</h6>
              <h5 class="text-info">{{ data?.students ?? 0 }}</h5>
              <p class="text-secondary">Number of Particiapnts in System</p>
            </div>
          </div>
        </div>
        <div class="col-md-3 mt-5">
          <div class="card">
            <div class="card-body">
              <h6>Batches</h6>
              <h5 class="text-success">{{ data?.active_batches ?? 0 }}</h5>
              <p class="text-secondary">Number of Active Batches</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="admin">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <h5 class="mb-3">Courses with Active Batches</h5>
              <div class="row">
                <div class="col-md-6" v-for="batch in data?.course_batches">
                  <h6>{{ batch.course_title }}</h6>
                  <p class="text-secondary">
                    {{ batch.batch_count }} Active Batch(es)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="admin">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h5 class="mb-3">Batches</h5>
            <table class="table table-responsive">
              <thead class="table-blue">
                <tr>
                  <th>Batch Name</th>
                  <th>Session</th>
                  <th>No. Participants</th>
                  <th>Facilitator</th>
                  <th>Course</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="batch in data?.batches">
                  <td>{{ batch.title }}</td>
                  <td>{{ batch.start_date }} - {{ batch.end_date }}</td>
                  <td>{{ batch.batch_enrollments.length }}</td>
                  <td>{{ batch.facilitator.name }}</td>
                  <td>{{ batch.course.name }}</td>
                  <td>
                    <p v-if="batch.isEnd == 0" class="text-success">Active</p>
                    <p class="text-danger" v-else>End</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-md-12 mt-5">
        <div class="card">
          <div class="card-header card-no-border">
            <h5>Batch Performance</h5>
          </div>
          <div class="card-body pt-0">
            <div class="row m-0 overall-card">
              <div class="col-xl-9 col-md-12 col-sm-7 p-0">
                <div class="chart-right">
                  <div class="row">
                    <div class="col-xl-12">
                      <div class="card-body p-0">
                        <div class="current-sale-container">
                          <apexchart
                            height="305"
                            type="bar"
                            :options="chartOptions"
                            :series="series"
                          ></apexchart>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-md-12 col-sm-5 p-0">
                <div class="row g-sm-4 g-2">
                  <div class="col-xl-12 col-md-4">
                    <div class="light-card balance-card widget-hover">
                      <div>
                        <span class="f-light">Total Batches</span>
                        <h6 class="mt-1 mb-0">{{ data?.total_batches }}</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-12 col-md-4">
                    <div class="light-card balance-card widget-hover">
                      <div>
                        <span class="f-light">Completed Batches</span>
                        <h6 class="mt-1 mb-0">{{ data?.completed_batches }}</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-12 col-md-4">
                    <div class="light-card balance-card widget-hover">
                      <div>
                        <span class="f-light">On Going Batches</span>
                        <h6 class="mt-1 mb-0">{{ data?.on_going_batches }}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl-4 col-md-6 appointment-sec box-col-6">
        <div class="appointment">
          <div class="card">
            <div class="card-header card-no-border">
              <div class="header-top">
                <h5 class="m-0">Recent Assignments</h5>
              </div>
            </div>
            <div class="card-body pt-0">
              <div class="appointment-table table-responsive">
                <table class="table table-bordernone">
                  <tbody>
                    <tr v-for="item in data?.latest_assignments" :key="item">
                      <td class="img-content-box">
                        <a
                          class="d-block f-w-500"
                          :href="'/markAssigment/' + item.id"
                          target="_blank"
                          >{{ item.user.name }}</a
                        >
                      </td>
                      <td class="text-end">
                        <p class="m-0 font-success">
                          {{ item.assignment_type_with_label }}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: null,
      admin: false,
      series: [
        {
          name: "",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 200,
          type: "bar",
        },
        xaxis: {
          categories: [],
        },
        plotOptions: {
          bar: {
            columnWidth: "35%",
            distributed: true,
          },
        },
      },
    };
  },
  mounted() {
    if (
      localStorage.getItem("role") &&
      localStorage.getItem("role") == "super-admin"
    ) {
      this.admin = true;
      this.fetch("admin");
    } else {
      this.fetch("teacher");
    }
  },
  methods: {
    fetch(type) {
      const facilitator_id = localStorage.getItem("role")
        ? null
        : localStorage.getItem("upId");
      this.$store
        .dispatch("dashboard/get", { type, facilitator_id })
        .then((response) => {
          this.data = response.data;
          if (type == "teacher") {
            this.series[0].data = this.data.batch_performance.performance;
            this.chartOptions.xaxis.categories =
              this.data.batch_performance.title;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
