<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="card position-relative" style="top: 50px">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 mt-3">
            <div class="d-flex justify-content-center mb-3">
              <h5
                class="ps-2"
                style="border-left: 2px solid #0074ce"
                v-if="title"
              >
                {{ title }}
              </h5>
            </div>
            <button
              class="btn btn-sm btn-primary primary-bg-color mb-3"
              @click="goBack"
            >
              Back
            </button>
            <button
              v-if="permissions && permissions.includes('courses_add')"
              class="btn btn-sm btn-primary primary-bg-color float-end mb-3"
              type="button"
              data-bs-toggle="modal"
              :data-bs-target="'#addLevelModal' + index"
            >
              Add Level
            </button>
          </div>
          <div class="col-md-12">
            <data-table
              :columns="columns"
              :items="items"
              :module="module"
              :isEdit="isEdit"
              :isDelete="isDelete"
              :isView="isView"
              @deleted="fetch(courseId)"
              @nextPage="nextPage"
            ></data-table>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Level Modal -->
    <addLevelModal
      :index="index"
      :selectedItem="selectedItem"
      :module="module"
    ></addLevelModal>
  </div>
</template>
<script>
import dataTable from "@/components/dataTable.vue";
import router from "@/router";
import addLevelModal from "@/components/modals/addLevel";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    dataTable,
    addLevelModal,
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          text: "Level",
          value: "name",
        },
        {
          text: "Description",
          value: "description",
        },
        {
          text: "Date",
          value: "created",
        },
        {
          text: "Material",
          value: "material_count",
        },
      ],
      items: [],
      module: "courseLevels",
      isEdit: false,
      isDelete: false,
      isView: false,
      courseId: null,
      title: null,
      index: 1,
      selectedItem: {
        id: null,
      },
      permissions: null,
    };
  },
  mounted() {
    this.permissions = localStorage.getItem("permissions");
    if (this.permissions && this.permissions.includes("courses_edit")) {
      this.isEdit = true;
    }
    if (this.permissions && this.permissions.includes("courses_view")) {
      this.isView = true;
    }
    if (this.permissions && this.permissions.includes("courses_delete")) {
      this.isDelete = true;
    }
    this.courseId = this.$route.params.id;
    this.selectedItem.encrypted_id = this.$route.params.id;
    this.fetch(this.courseId);
  },
  methods: {
    goBack() {
      router.go(-1);
    },
    nextPage(url) {
      this.loading = true;
      axiosInstance.get(url).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
    fetch(id) {
      this.loading = true;
      const search = "";
      this.$store
        .dispatch("courseLevels/getLevelsByCourse", { id, search })
        .then((response) => {
          this.items = response.data;
          this.title = this.items.data[0].course.name;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
  },
};
</script>
