<template>
  <div>
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="page-wrapper">
      <div class="container-fluid p-0">
        <div class="row">
          <div class="col-12">
            <div class="login-card">
              <div>
                <div class="d-flex justify-content-center mb-4">
                  <img height="300px" width="250px" class="img-fluid" src="../assets/logo-blue.png" alt="" />
                </div>
                <div class="login-main">
                  <form class="theme-form" @submit.prevent="submit">
                    <h4>Verify Email</h4>
                    <span class="text-primary">{{ msg }}</span>
                    <div class="form-group">
                      <label class="col-form-label">Email</label>
                      <div class="form-input position-relative">
                        <input type="email" v-model="email" class="custom-form-control" required
                          placeholder="email@test.com" autofocus />
                        <span v-if="errors.email" class="text-danger">{{
                          errors.email[0]
                        }}</span>
                      </div>
                    </div>
                    <div class="form-group mb-0">
                      <button type="submit" class="custom-btn primary-bg-color text-white btn-block w-100">
                        Verify
                      </button>
                    </div>
                    <p class="mt-4 mb-0">
                      Already have an account?
                      <router-link to="/auth/login" class="primary-text-color">
                        Sign in
                      </router-link>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { toRaw } from "vue";

export default {
  data() {
    return {
      active: true,
      email: null,
      loading: false,
      errors: [],
      msg: null,
    };
  },
  created() {
    if (!!localStorage.getItem("token")) {
      router.push({ path: "/" });
    }
  },
  methods: {
    submit() {
      this.loading = true;
      this.errors = [];
      const data = { username: this.email };
      this.$store
        .dispatch("auth/verifyEmail", { data })
        .then((response) => {
          this.loading = false;
          this.msg = response.data;
        })
        .catch((e) => {
          this.loading = false;
          this.errors = e.error;
        });
    },
  },
};
</script>
