<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="card position-relative" style="top: 50px">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 mt-3">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Assessment Tool
              </h5>
            </div>
            <router-link
              v-if="permissions && permissions.includes('assessment_add')"
              to="/assignment/add"
              class="btn btn-sm btn-primary mb-2 float-end primary-bg-color"
              >Send Assignment</router-link
            >
          </div>

          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                id="participants-tab"
                data-bs-toggle="tab"
                href="#participants"
                role="tab"
                aria-controls="participants"
                aria-selected="true"
                >Batch Assignments</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="description-tab"
                data-bs-toggle="tab"
                href="#description"
                role="tab"
                aria-controls="description"
                aria-selected="false"
                >Group Assignments</a
              >
            </li>
          </ul>

          <div class="tab-content">
            <div
              class="tab-pane fade show active"
              id="participants"
              role="tabpanel"
              aria-labelledby="participants-tab"
            >
              <div class="col-md-12">
                <data-table
                  :columns="columns"
                  :items="items"
                  :module="module"
                  @deleted="fetch"
                  :isEdit="isEdit"
                  :isDelete="isDelete"
                  @search="fetch"
                  @clear="fetch"
                  @nextPage="nextPage"
                ></data-table>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="description"
              role="tabpanel"
              aria-labelledby="description-tab"
            >
              <div class="col-md-12">
                <data-table
                  :columns="columnsGroupAssignments"
                  :items="groupAssignmentItems"
                  :module="module"
                  @deleted="fetch"
                  :isEdit="isEdit"
                  :isDelete="isDelete"
                  @search="fetch"
                  @clear="fetch"
                  @nextPage="nextPage"
                ></data-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    dataTable,
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          text: "Assignment Type",
          value: "assignment_type",
        },
        {
          text: "Batch",
          value: "batch",
          secondValue: "title",
        },

        {
          text: "Submission Deadline",
          value: "submission_deadline",
        },
      ],
      columnsGroupAssignments: [
        {
          text: "Assignment Type",
          value: "assignment_type",
        },
        {
          text: "Submission Deadline",
          value: "submission_deadline",
        },
      ],
      items: [],
      groupAssignmentItems: [],
      permissions: null,
      isEdit: false,
      isDelete: false,
      module: "assignment",
    };
  },
  mounted() {
    this.fetch();
    this.permissions = localStorage.getItem("permissions");
    if (this.permissions && this.permissions.includes("assessment_edit")) {
      this.isEdit = true;
    }
    if (this.permissions && this.permissions.includes("assessment_delete")) {
      this.isDelete = true;
    }
  },
  methods: {
    nextPage(url) {
      this.loading = true;
      axiosInstance.get(url).then((response) => {
        this.items = response.data.data.batchAssignments;
        this.groupAssignmentItems = response.data.data.groupAssignments;
        this.loading = false;
      });
    },
    fetch(search = "") {
      this.loading = true;
      this.$store
        .dispatch("assignment/getAll", { search })
        .then((response) => {
          this.items = response.data.batchAssignments;
          this.groupAssignmentItems = response.data.groupAssignments;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
  },
};
</script>
