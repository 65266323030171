<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="card position-relative" style="top: 50px">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 mt-3">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" v-if="title">{{ courseName }} | {{ title }}</h5>
            </div>
            <button
              class="btn btn-sm btn-primary primary-bg-color mb-3"
              @click="goBack"
            >
              Back
            </button>
            <button
              v-if="permissions && permissions.includes('courses_add')"
              class="btn btn-sm btn-primary primary-bg-color float-end mb-3"
              type="button"
              data-bs-toggle="modal"
              :data-bs-target="'#addMaterialModal' + index"
            >
              Add Material
            </button>
          </div>
          <div class="col-md-12">
            <data-table
              :columns="columns"
              :items="items"
              :module="module"
              :isEdit="isEdit"
              :isDelete="isDelete"
              @deleted="handleReload"
              @nextPage="nextPage"
            ></data-table>
          </div>
        </div>
      </div>
    </div>
    <!-- Add Material Modal -->
    <addMaterialModal
      :index="index"
      :selectedItem="selectedItem"
      :module="module"
      @reloadData="handleReload"
    ></addMaterialModal>
  </div>
</template>
<script>
import dataTable from "@/components/dataTable.vue";
import router from "@/router";
import addMaterialModal from "@/components/modals/addMaterial";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    dataTable,
    addMaterialModal,
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          text: "Material",
          value: "title",
        },
        {
          text: "Type",
          value: "type",
        },
        {
          text: "Date",
          value: "created",
        },
      ],
      items: [],
      module: "courseMaterial",
      isEdit: false,
      isDelete: false,
      isView: false,
      courseId: null,
      title: null,
      index: 1,
      selectedItem: {
        id: null,
      },
      courseName: "",
      permissions: null,
    };
  },
  mounted() {
    this.permissions = localStorage.getItem("permissions");
    if (this.permissions && this.permissions.includes("courses_edit")) {
      this.isEdit = true;
    }
    if (this.permissions && this.permissions.includes("courses_view")) {
      this.isView = true;
    }
    if (this.permissions && this.permissions.includes("courses_delete")) {
      this.isDelete = true;
    }
    this.courseId = this.$route.params.id;
    this.selectedItem.id = this.$route.params.id;
    this.fetch(this.courseId);
  },
  methods: {
    nextPage(url) {
      this.loading = true;
      axiosInstance.get(url).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
    goBack() {
      router.go(-1);
    },
    fetch(id, search = "") {
      this.loading = true;
      const titleMap = {
        Quiz: "name",
        Video: "title",
        Assignment: "description",
        Document: "title",
      };
      this.$store
        .dispatch("courseMaterial/getMaterialByLevel", { id, search })
        .then((response) => {
          this.items = response.data;
          this.items.data.forEach((item) => {
            item.title = item.material[titleMap[item.type]] || "";
          });
          this.title = this.items.data[0].course_level.name;
          this.courseName = this.items.data[0].course_level.course.name;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    handleReload() {
      this.fetch(this.courseId);
    },
  },
};
</script>
