import axiosInstance from "@/helpers/axios";

export default {
  namespaced: true,
  actions: {
    get({ dispatch, commit }, { type, facilitator_id = null }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/dashboard?type=${type}&facilitator_id=${facilitator_id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
  },
};
