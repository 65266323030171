<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mx-auto mt-5">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Send Assessment
              </h5>
            </div>
            <form @submit.prevent="submit">
              <div class="form-group">
                <label class="primary-text-color"
                  >Course <span class="text-danger">*</span></label
                >
                <select
                  class="custom-form-control"
                  v-model="courseId"
                  @change="fetchBatch"
                  required
                >
                  <option value="">Select Course</option>
                  <option v-for="item in courses" :value="item.id">
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Batch <span class="text-danger">*</span></label
                  >
                  <select
                    class="custom-form-control"
                    v-model="assignment.batch_id"
                    required
                    @change="selectBatch"
                  >
                    <option value="">Select Batch</option>
                    <option v-for="item in batch" :value="item">
                      {{ item.title }}
                    </option>
                  </select>
                </div>
                <p
                  class="mt-2"
                  v-if="
                    assignment.batch_id && assignment.batch_id.batch_enrollments
                  "
                >
                  Participants Found:
                  {{
                    participants !== null
                      ? participants
                      : assignment.batch_id.batch_enrollments.length
                  }}
                </p>
              </div>
              <div class="mt-3">
                <p>Do you want to create Groups?</p>
                <a
                  href="#participantModal"
                  class="btn btn-primary primary-bg-color"
                  data-bs-toggle="modal"
                  @click="setIsGroupTrue"
                >
                  Create Groups
                </a>
              </div>
              <div v-if="isGroup">
                <div
                  class="p-4 mt-3"
                  style="
                    border-radius: 16px;
                    background-color: rgba(0, 116, 206, 0.2);
                  "
                  v-if="groups >= 1"
                  v-for="(group, index) in assignment.groups"
                >
                  <h5 class="primary-text-color d-inline-block me-3">
                    Group {{ index + 1 }}
                  </h5>
                  <span class="text-black"
                    >Number of Participants: {{ group.students.length }}</span
                  >
                  <div class="form-group mt-3">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        v-model="group.assignmentable_type"
                        :name="'assignmentable_type' + index"
                        :id="'quiz' + index"
                        value="App\Models\Quiz"
                        required
                      />
                      <label
                        class="primary-text-color form-check-label"
                        :for="'quiz' + index"
                        >Quiz</label
                      >
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        v-model="group.assignmentable_type"
                        :name="'assignmentable_type' + index"
                        :id="'manual' + index"
                        value="App\Models\ManualAssessment"
                        required
                      />
                      <label
                        class="primary-text-color form-check-label"
                        :for="'manual' + index"
                        >Manual Assessment</label
                      >
                    </div>
                  </div>
                  <div
                    class="form-group"
                    v-if="group.assignmentable_type == 'App\\Models\\Quiz'"
                  >
                    <div class="col-md-12 mt-3">
                      <label class="primary-text-color"
                        >Quiz <span class="text-danger">*</span></label
                      >
                      <select
                        class="custom-form-control"
                        v-model="group.assignmentable_id"
                        required
                      >
                        <option value="">Select Quiz</option>
                        <option v-for="item in quizes" :value="item.id">
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div
                    class="form-group"
                    v-if="
                      group.assignmentable_type ==
                      'App\\Models\\ManualAssessment'
                    "
                  >
                    <div class="col-md-12 mt-3">
                      <label class="primary-text-color"
                        >Manual Assessment
                        <span class="text-danger">*</span></label
                      >
                      <select
                        class="custom-form-control"
                        v-model="group.assignmentable_id"
                        required
                      >
                        <option value="">Select Assessment</option>
                        <option
                          v-for="item in manualAssessments"
                          :value="item.id"
                        >
                          {{ item.title }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row mt-2">
                    <div class="col-md-6">
                      <label class="primary-text-color"
                        >Submission Deadline
                        <span class="text-danger">*</span></label
                      >
                      <input
                        type="date"
                        v-model="group.submission_deadline"
                        class="custom-form-control"
                        required
                        :min="today"
                      />
                    </div>
                    <div
                      class="col-md-6"
                      v-if="group.assignmentable_type == 'App\\Models\\Quiz'"
                    >
                      <div class="form-group">
                        <label class="primary-text-color"
                          >Duration <span class="text-danger">*</span></label
                        >
                        <input
                          type="text"
                          v-model="group.duration"
                          class="custom-form-control"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!isGroup">
                <div class="form-group mt-3">
                  <div class="form-group">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        v-model="assignment.assignmentable_type"
                        name="assignmentable_type"
                        id="quiz"
                        value="quiz"
                        required
                        @change="changeAssignmentType"
                      />
                      <label
                        class="primary-text-color form-check-label"
                        for="quiz"
                        >Quiz</label
                      >
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        v-model="assignment.assignmentable_type"
                        name="assignmentable_type"
                        id="manual"
                        value="manual"
                        required
                        @change="changeAssignmentType"
                      />
                      <label
                        class="primary-text-color form-check-label"
                        for="manual"
                        >Manual Assessment</label
                      >
                    </div>
                  </div>
                </div>
                <div class="form-group" v-if="isQuiz">
                  <div class="col-md-12 mt-3">
                    <label class="primary-text-color"
                      >Quiz <span class="text-danger">*</span></label
                    >
                    <select
                      class="custom-form-control"
                      v-model="assignment.assignmentable_id"
                      required
                    >
                      <option value="">Select Quiz</option>
                      <option v-for="item in quizes" :value="item.id">
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group" v-if="isManual">
                  <div class="col-md-12 mt-3">
                    <label class="primary-text-color"
                      >Manual Assessment
                      <span class="text-danger">*</span></label
                    >
                    <select
                      class="custom-form-control"
                      v-model="assignment.assignmentable_id"
                      required
                    >
                      <option value="">Select Assessment</option>
                      <option
                        v-for="item in manualAssessments"
                        :value="item.id"
                      >
                        {{ item.title }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group row mt-2" v-if="isQuiz || isManual">
                  <div class="col-md-6">
                    <label class="primary-text-color"
                      >Submission Deadline
                      <span class="text-danger">*</span></label
                    >
                    <input
                      type="date"
                      v-model="assignment.submission_deadline"
                      class="custom-form-control"
                      required
                      :min="today"
                    />
                  </div>
                  <div class="col-md-6" v-if="isQuiz">
                    <label class="primary-text-color"
                      >Duration <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      v-model="assignment.duration"
                      class="custom-form-control"
                      @keypress="isNumber($event)"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <button
                    type="submit"
                    class="custom-btn primary-bg-color text-white btn-block w-100 mt-3"
                  >
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <button
                    type="button"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    @click="clearAll"
                  >
                    Clear All
                  </button>
                </div>
                <div class="col-md-3">
                  <router-link
                    to="/assignment"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Participant List Modal -->
    <div
      class="modal fade"
      id="participantModal"
      tabindex="-1"
      aria-labelledby="participantModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="d-flex justify-content-center mb-5 mt-5">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Participant List
              </h5>
            </div>
            <data-table
              :columns="columns"
              :items="this.batchParticipants"
              :isGroupCheck="isGroupCheck"
              @groupStudents="addStutentToGroup"
            ></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import dataTable from "@/components/dataTable.vue";
import isNumber from "@/helpers/helpers";

export default {
  mixins: [isNumber],
  components: {
    dataTable,
  },
  data() {
    return {
      loading: false,
      batch: [],
      assignment: {
        batch_id: [],
        submission_deadline: null,
        duration: null,
        assignmentable_type: null,
        assignmentable_id: "",
        groups: [],
      },
      isChecked: true,
      isGroupCheck: true,
      group: {
        students: [],
        submission_deadline: null,
        duration: null,
        assignmentable_type: null,
        assignmentable_id: "",
      },
      isQuiz: false,
      isManual: false,
      quizes: [],
      manualAssessments: [],
      isGroup: false,
      courses: [],
      courseId: "",
      numofGroups: "",
      groups: null,
      students: [],
      participants: null,
      columns: [
        {
          text: "Participant Name",
          value: "student",
          secondValue: "name",
        },
        {
          text: "Designation",
          value: "student",
          secondValue: "designation",
        },
        {
          text: "Department",
          value: "student",
          secondValue: "department",
          thirdValue: "name",
        },
        {
          text: "Qualification",
          value: "student",
          secondValue: "qualification",
        },
      ],
      batchParticipants: {
        data: [],
      },
      today: new Date().toISOString().split("T")[0],
      role: localStorage.getItem("role"),
    };
  },
  mounted() {
    this.fetchCourses();
    this.fetchQuiz();
    this.fetchAssessment();
  },
  methods: {
    setIsGroupTrue() {
      if (this.assignment.batch_id == "") {
        this.$toast.show("Please select batch to create groups", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        this.participants = this.assignment.batch_id.batch_enrollments.length;
        this.isGroup = true;
      }
    },
    fetchBatch() {
      this.loading = true;
      const id = this.courseId;
      this.$store
        .dispatch("batch/getBatchByCourse", { id })
        .then((response) => {
          this.batch = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    selectBatch() {
      this.batchParticipants.data = this.assignment.batch_id.batch_enrollments;
    },
    fetchCourses() {
      this.loading = true;
      const search = "";
      const pagination = false;
      const facilitator_id =
        this.role == null ? localStorage.getItem("upId") : null;
      this.$store
        .dispatch("courses/getAll", { search, pagination, facilitator_id })
        .then((response) => {
          this.courses = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    fetchQuiz() {
      this.loading = true;
      const search = "";
      const pagination = false;
      const facilitator_id =
        this.role == null ? localStorage.getItem("upId") : null;
      this.$store
        .dispatch("quiz/getAll", { search, pagination, facilitator_id })
        .then((response) => {
          this.quizes = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    fetchAssessment() {
      this.loading = true;
      const search = "";
      const pagination = false;
      const facilitator_id =
        this.role == null ? localStorage.getItem("upId") : null;
      this.$store
        .dispatch("manualAssessment/getAll", {
          search,
          pagination,
          facilitator_id,
        })
        .then((response) => {
          this.manualAssessments = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    changeAssignmentType() {
      if (this.assignment.assignmentable_type == "quiz") {
        this.isQuiz = true;
        this.isManual = false;
        this.fetchQuiz();
      } else {
        this.isQuiz = false;
        this.isManual = true;
        this.fetchAssessment();
      }
    },
    addStutentToGroup(students) {
      const newGroup = { students: [] };
      students.forEach((student) => {
        newGroup.students.push(student);
      });
      this.assignment.groups.push(newGroup);
      this.isGroup = true;
      this.groups++;
      const remainingEnrollments =
        this.assignment.batch_id.batch_enrollments.filter((enrollment) => {
          return !students.some((student) => student.id === enrollment.id);
        });
      this.assignment.batch_id.batch_enrollments = remainingEnrollments;
      this.$toast.show("Group Created Successfully", {
        theme: "outline",
        position: "top",
        type: "success",
        duration: 5000,
      });
    },
    submit() {
      this.loading = true;
      this.assignment.batch_id = this.assignment.batch_id.id;
      const data = toRaw(this.assignment);
      this.$store
        .dispatch("assignment/store", { data })
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            this.$toast.show("Successfuly added.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            Object.keys(this.assignment).forEach((key) => {
              if (key === "batch_id") {
                this.assignment[key] = "";
              } else {
                this.assignment[key] = null;
              }
            });
            this.isQuiz = false;
            this.isManual = false;
            this.courseId = "";
            this.isGroup = false;
            this.groups = [];
            this.assignment.groups = [];
          } else {
            this.errors = response.error;
            console.log(response.error);
          }
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    clearAll() {
      Object.keys(this.assignment).forEach((key) => {
        if (key === "batch_id") {
          this.assignment[key] = "";
        } else {
          this.assignment[key] = null;
        }
      });
      this.assignment.groups = [];
      this.isQuiz = false;
      this.isManual = false;
      this.courseId = "";
      this.isGroup = false;
      this.groups = [];
    },
  },
};
</script>
