<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mx-auto mt-5">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Edit Facilitator
              </h5>
            </div>
            <form @submit.prevent="submit" autocomplete="off">
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Email <span class="text-danger">*</span></label
                  >
                  <span class="d-block text-danger">{{
                    errors?.username
                  }}</span>
                  <input
                    type="email"
                    v-model="profile.user.username"
                    class="custom-form-control"
                    required
                    pattern="[^\s@]+@[^\s@]+\.[^\spatt@]+"
                    autocomplete="new-email"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color">Password</label>
                  <input
                    type="password"
                    v-model="profile.user.password"
                    class="custom-form-control"
                    autocomplete="new-password"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color">Re-Type Password</label>
                  <span
                    class="text-danger d-block"
                    v-if="errors.confirmPassword"
                  >
                    {{ errors.confirmPassword }}
                  </span>
                  <input
                    type="password"
                    v-model="profile.user.confirmPassword"
                    class="custom-form-control"
                    autocomplete="new-con-password"
                  />
                </div>
              </div>
              <div class="form-group row mt-3">
                <div class="col-md-3">
                  <img
                    :src="previewProfilePic"
                    style="
                      width: 120px;
                      height: 120px;
                      border-radius: 50%;
                      border: 1px solid #ddd;
                    "
                  />
                </div>
                <div class="col-md-8 mt-3">
                  <label>Upload Profile Picture</label>
                  <div class="position-relative">
                    <input
                      type="file"
                      ref="profilePic"
                      class="position-relative"
                      accept="image/png, image/jpg, image/jpeg"
                      @change="profilePicSelected"
                      style="opacity: 0; z-index: 100"
                    />
                    <button
                      type="button"
                      class="btn btn-primary primary-bg-color position-absolute"
                      style="left: 0"
                    >
                      Browse...
                    </button>
                  </div>
                </div>
              </div>

              <div class="form-group row mt-3">
                <div class="col-md-12">
                  <label class="primary-text-color"
                    >Name <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="custom-form-control"
                    v-model="profile.name"
                    required
                    @keydown="validateText($event)"
                  />
                </div>
              </div>
              <div class="form-group mt-2">
                <div class="col-md-12">
                  <label class="primary-text-color">Department</label>
                  <select
                    class="custom-form-control"
                    v-model="profile.department_id"
                    required
                  >
                    <option value="">Select Department</option>
                    <option
                      v-for="department in departments"
                      :value="department.id"
                    >
                      {{ department.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color">Designation</label>
                  <select
                    v-model="profile.designation"
                    class="custom-form-control"
                  >
                    <option value="" selected>Select Designation</option>
                    <option v-for="des in designations" :value="des.name">
                      {{ des.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Qualification <span class="text-danger">*</span></label
                  >
                  <select
                    v-model="profile.qualification"
                    class="custom-form-control"
                    required
                  >
                    <option value="">Select Qualification</option>
                    <option v-for="des in qualifications" :value="des.name">
                      {{ des.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Select District <span class="text-danger">*</span></label
                  >
                  <select
                    class="custom-form-control"
                    required
                    v-model="districtId"
                    @change="fetchTahseel"
                  >
                    <option v-for="district in districts" :value="district.id">
                      {{ district.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Select Tehsil <span class="text-danger">*</span></label
                  >
                  <select
                    class="custom-form-control"
                    v-model="profile.tahseel_id"
                    required
                  >
                    <option v-for="tahseel in tahseels" :value="tahseel.id">
                      {{ tahseel.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color">Subject Specialty</label>
                  <input
                    type="text"
                    v-model="profile.subject_specialty"
                    class="custom-form-control"
                  />
                </div>
              </div>
              <div class="form-group mt-3">
                <label class="primary-text-color" for="tagsInput"
                  >Skills <span>(Press enter to add tag)</span></label
                >
                <div class="tags-input-container">
                  <span
                    v-for="(tag, index) in tags"
                    :key="index"
                    class="badge badge-primary mb-2"
                    >{{ tag
                    }}<span
                      class="tag-close ms-2 cursor-pointer"
                      @click="removeTag(index)"
                      ><i class="bi bi-trash"></i></span
                  ></span>
                </div>
                <input
                  v-model="tagInput"
                  @keypress.enter="addTag"
                  type="text"
                  id="tagInput"
                  class="custom-form-control"
                />
              </div>

              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Phone <span class="text-danger">*</span></label
                  >
                  <span class="d-block text-danger">{{ errors?.phone }}</span>
                  <input
                    type="text"
                    maxlength="11"
                    minlength="11"
                    v-model="profile.phone"
                    class="custom-form-control"
                    @keypress="isNumber($event)"
                    required
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >CNIC <span class="text-danger">*</span></label
                  >
                  <span class="d-block text-danger">{{ errors?.cnic }}</span>
                  <input
                    maxlength="13"
                    type="text"
                    minlength="13"
                    v-model="profile.cnic"
                    class="custom-form-control"
                    @keypress="isNumber($event)"
                    required
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color">Gender</label>
                  <div class="col-md-12">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        id="male"
                        value="Male"
                        v-model="profile.gender"
                      />
                      <label class="form-check-label" for="male">Male</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        id="female"
                        value="Female"
                        v-model="profile.gender"
                      />
                      <label class="form-check-label" for="female"
                        >Female</label
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color">Date of Birth</label>
                  <input
                    type="date"
                    v-model="profile.dob"
                    class="custom-form-control"
                  />
                </div>
              </div>
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#permissionModal"
                class="custom-btn primary-bg-color text-white btn-block w-100 mt-3"
              >
                Select Permissions
              </button>
              <div class="row">
                <div class="col-md-9">
                  <button
                    type="submit"
                    class="custom-btn primary-bg-color text-white btn-block w-100 mt-3"
                  >
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <router-link
                    to="/facilitators"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="permissionModal"
    tabindex="-1"
    aria-labelledby="permissionModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4>Select Permissions</h4>
        </div>
        <div class="modal-body">
          <table class="table">
            <tbody>
              <tr>
                <td class="checkbox-label">
                  <input
                    type="checkbox"
                    id="checkAll"
                    @change="toggleCheckAll"
                  />
                  <label for="checkAll" style="margin-left: 5px"
                    >Check All</label
                  >
                </td>
              </tr>
              <tr>
                <td style="font-weight: bold">Batch</td>
                <td class="checkbox-label">
                  <input
                    type="checkbox"
                    id="addCheckbox-24"
                    v-model="profile.user.permissions"
                    :value="getName('batch', 'view')"
                  />
                  <label for="addCheckbox-24">View</label>
                </td>
                <td class="checkbox-label">
                  <input
                    type="checkbox"
                    id="addCheckbox-25"
                    v-model="profile.user.permissions"
                    :value="getName('batch', 'add')"
                  />
                  <label for="addCheckbox-25">Add</label>
                </td>
                <td class="checkbox-label">
                  <input
                    type="checkbox"
                    id="editCheckbox-26"
                    v-model="profile.user.permissions"
                    :value="getName('batch', 'edit')"
                  />
                  <label for="editCheckbox-26">Edit</label>
                </td>
                <td class="checkbox-label">
                  <input
                    type="checkbox"
                    id="deleteCheckbox-27"
                    v-model="profile.user.permissions"
                    :value="getName('batch', 'delete')"
                  />
                  <label for="deleteCheckbox-27">Delete</label>
                </td>
              </tr>
              <tr>
                <td style="font-weight: bold">Course</td>
                <td class="checkbox-label">
                  <input
                    type="checkbox"
                    id="addCheckbox-41"
                    v-model="profile.user.permissions"
                    :value="getName('courses', 'view')"
                  />
                  <label for="addCheckbox-41">View</label>
                </td>
                <td class="checkbox-label">
                  <input
                    type="checkbox"
                    id="addCheckbox-42"
                    v-model="profile.user.permissions"
                    :value="getName('courses', 'add')"
                  />
                  <label for="addCheckbox-42">Add</label>
                </td>
                <td class="checkbox-label">
                  <input
                    type="checkbox"
                    id="editCheckbox-43"
                    v-model="profile.user.permissions"
                    :value="getName('courses', 'edit')"
                  />
                  <label for="editCheckbox-43">Edit</label>
                </td>
                <td class="checkbox-label">
                  <input
                    type="checkbox"
                    id="deleteCheckbox-44"
                    v-model="profile.user.permissions"
                    :value="getName('courses', 'delete')"
                  />
                  <label for="deleteCheckbox-44">Delete</label>
                </td>
              </tr>
              <tr>
                <td style="font-weight: bold">Quiz</td>
                <td class="checkbox-label">
                  <input
                    type="checkbox"
                    id="addCheckbox-45"
                    v-model="profile.user.permissions"
                    :value="getName('quiz', 'view')"
                  />
                  <label for="addCheckbox-45">View</label>
                </td>
                <td class="checkbox-label">
                  <input
                    type="checkbox"
                    id="addCheckbox-46"
                    v-model="profile.user.permissions"
                    :value="getName('quiz', 'add')"
                  />
                  <label for="addCheckbox-46">Add</label>
                </td>
                <td class="checkbox-label">
                  <input
                    type="checkbox"
                    id="editCheckbox-47"
                    v-model="profile.user.permissions"
                    :value="getName('quiz', 'edit')"
                  />
                  <label for="editCheckbox-47">Edit</label>
                </td>
                <td class="checkbox-label">
                  <input
                    type="checkbox"
                    id="deleteCheckbox-48"
                    v-model="profile.user.permissions"
                    :value="getName('quiz', 'delete')"
                  />
                  <label for="deleteCheckbox-48">Delete</label>
                </td>
              </tr>
              <tr>
                <td style="font-weight: bold">Manual Assessment</td>
                <td class="checkbox-label">
                  <input
                    type="checkbox"
                    id="addCheckbox-49"
                    v-model="profile.user.permissions"
                    :value="getName('manualAssessment', 'view')"
                  />
                  <label for="addCheckbox-49">View</label>
                </td>
                <td class="checkbox-label">
                  <input
                    type="checkbox"
                    id="addCheckbox-50"
                    v-model="profile.user.permissions"
                    :value="getName('manualAssessment', 'add')"
                  />
                  <label for="addCheckbox-50">Add</label>
                </td>
                <td class="checkbox-label">
                  <input
                    type="checkbox"
                    id="editCheckbox-51"
                    v-model="profile.user.permissions"
                    :value="getName('manualAssessment', 'edit')"
                  />
                  <label for="editCheckbox-51">Edit</label>
                </td>
                <td class="checkbox-label">
                  <input
                    type="checkbox"
                    id="deleteCheckbox-52"
                    v-model="profile.user.permissions"
                    :value="getName('manualAssessment', 'delete')"
                  />
                  <label for="deleteCheckbox-52">Delete</label>
                </td>
              </tr>
              <tr>
                <td style="font-weight: bold">Assessment Tool</td>
                <td class="checkbox-label">
                  <input
                    type="checkbox"
                    id="addCheckbox-53"
                    v-model="profile.user.permissions"
                    :value="getName('assessment', 'view')"
                  />
                  <label for="addCheckbox-53">View</label>
                </td>
                <td class="checkbox-label">
                  <input
                    type="checkbox"
                    id="addCheckbox-54"
                    v-model="profile.user.permissions"
                    :value="getName('assessment', 'add')"
                  />
                  <label for="addCheckbox-54">Add</label>
                </td>
                <td class="checkbox-label">
                  <input
                    type="checkbox"
                    id="editCheckbox-55"
                    v-model="profile.user.permissions"
                    :value="getName('assessment', 'edit')"
                  />
                  <label for="editCheckbox-55">Edit</label>
                </td>
                <td class="checkbox-label">
                  <input
                    type="checkbox"
                    id="deleteCheckbox-56"
                    v-model="profile.user.permissions"
                    :value="getName('assessment', 'delete')"
                  />
                  <label for="deleteCheckbox-56">Delete</label>
                </td>
              </tr>
              <tr>
                <td style="font-weight: bold">Reports</td>
                <td class="checkbox-label">
                  <input
                    type="checkbox"
                    id="addCheckbox-63"
                    v-model="profile.user.permissions"
                    :value="getName('reports', 'view')"
                  />
                  <label for="addCheckbox-63">View</label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-sm btn-secondary"
            data-bs-dismiss="modal"
            @click="profile.user.permissions = []"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-sm primary-bg-color text-white"
            data-bs-dismiss="modal"
          >
            Select
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import isNumber from "@/helpers/helpers";
import validateText from "@/helpers/helpers";

export default {
  mixins: [isNumber, validateText],
  data() {
    return {
      loading: false,
      tagInput: "",
      tags: [],
      profile: {
        name: null,
        department_id: "",
        designation: null,
        phone: null,
        gender: null,
        dob: null,
        email: null,
        cnic: null,
        type: "Teacher",
        qualification: null,
        user: {
          username: null,
          password: null,
          confirmPassword: null,
          permissions: [],
        },
      },
      errors: [],
      profilePicture: null,
      previewProfilePic: null,
      uploadProfilePic: null,
      userId: null,
      departments: [],
      designations: [],
      qualifications: [],
      modules: [],
      districts: [],
      districtId: null,
      tahseels: [],
    };
  },
  mounted() {
    this.fetchDepartments();
    this.fetchDistrict();
    this.userId = this.$route.params.id;
    this.fetch(this.userId);
    this.fetchDesigantion();
    this.fetchQualifications();
    this.fetchModules();
  },
  methods: {
    fetchDistrict() {
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("district/getAll", { search, pagination })
        .then((response) => {
          this.districts = response.data;
        })
        .catch((e) => {
          console.log("district:", e);
        });
    },
    fetchTahseel() {
      this.loading = true;
      const id = this.districtId;
      this.$store
        .dispatch("tahseel/getByDistrict", { id })
        .then((response) => {
          this.tahseels = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log("district:", e);
        });
    },
    fetchModules() {
      this.loading = true;
      this.$store
        .dispatch("module/getAll")
        .then((response) => {
          this.loading = false;
          this.modules = response.data;
          this.admins = this.modules.filter((permission) =>
            permission.name.startsWith("admins_")
          );
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    getName(module, action) {
      const permission = this.modules.find(
        (permission) => permission.name === `${module}_${action}`
      );
      return permission ? permission.name : null;
    },
    toggleCheckAll(event) {
      const checkboxes = document.querySelectorAll(
        'input[type="checkbox"]:not(#checkAll)'
      );
      checkboxes.forEach((checkbox) => {
        checkbox.checked = event.target.checked;
        if (
          checkbox.checked &&
          !this.profile.user.permissions.includes(checkbox.value)
        ) {
          this.profile.user.permissions.push(checkbox.value);
        } else if (!checkbox.checked) {
          const index = this.profile.user.permissions.indexOf(checkbox.value);
          if (index !== -1) {
            this.profile.user.permissions.splice(index, 1);
          }
        }
      });
    },
    fetchDepartments() {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("department/getAll", { search, pagination })
        .then((response) => {
          this.departments = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("facilitators/getSingle", { id })
        .then((response) => {
          this.profile = response.data;
          if (this.profile.tahseel) {
            this.districtId = this.profile.tahseel.district_id;
            this.fetchTahseel();
          }
          response.data.skills.forEach((skill) => {
            this.tags.push(skill.skill);
          });
          this.profile.user.permissions = response?.data?.user.permissions;
          this.previewProfilePic = this.profile?.profile_image?.image_url;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    profilePicSelected() {
      this.profilePicture = this.$refs.profilePic.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.previewProfilePic = e.target.result;
      };
      reader.onloadend = () => {
        this.uploadProfilePic = reader.result;
      };
      reader.readAsDataURL(this.profilePicture);
    },
    submit() {
      this.loading = true;
      if (this.profile.user.password != this.profile.user.confirmPassword) {
        this.errors.confirmPassword = "Password didn't match";
        this.loading = false;
      }
      this.profile.user.skills = this.tags;
      if (this.uploadProfilePic != null) {
        this.profile.file = this.uploadProfilePic;
      }
      const data = toRaw(this.profile);
      const id = this.userId;
      this.$store
        .dispatch("users/update", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            this.$toast.show("Successfuly updated.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
          } else {
            this.$toast.show("Please review your form.", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
            this.errors = response.error;
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    addTag(e) {
      e.preventDefault();
      if (this.tagInput.trim() !== "") {
        this.tags.push(this.tagInput.trim());
        this.tagInput = "";
      }
    },
    removeTag(index) {
      this.tags.splice(index, 1);
    },
    fetchDesigantion() {
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("designation/getAll", { search, pagination })
        .then((response) => {
          this.designations = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    fetchQualifications() {
      const search = "";
      const pagination = false;
      this.loading = true;
      this.$store
        .dispatch("qualification/getAll", { search, pagination })
        .then((response) => {
          this.qualifications = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
  },
};
</script>
