<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <button
      class="btn primary-bg-color text-white mt-5"
      @click="$router.go(-1)"
    >
      Back
    </button>
    <div class="card mt-3">
      <div class="card-body">
        <div class="row">
          <div
            class="col-md-12"
            v-if="items?.assignment_type_with_label == 'Quiz'"
          >
            <div class="container">
              <h4 class="card-title mb-4">
                {{ items.assignment.name }}
              </h4>
              <div class="questions">
                <div
                  v-for="question in items.quiz_submission"
                  :key="question.id"
                  class="question mb-4"
                >
                  <h5>{{ question.question.name }}</h5>
                  <div class="options row">
                    <div
                      v-for="option in question.question.options"
                      :key="option.id"
                      class="col-md-6 mb-2"
                    >
                      <label class="form-check d-flex align-items-center">
                        <input
                          class="form-check-input me-2"
                          type="radio"
                          :name="'Question' + question.id"
                          :value="option.name"
                          :checked="question.answer == option.id"
                          disabled
                        />
                        <span class="form-check-label">{{ option.name }}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              Marks: {{ items.percentage }}
            </div>
          </div>
          <div v-else>
            <h4 class="card-title mb-4">
              {{ items?.assignment?.description }}
            </h4>
            <div class="mb-4">
              <a
                :href="items?.manual_assessment_submission?.file?.image_url"
                class="btn primary-bg-color text-white"
                target="_blank"
                >Open Assignment</a
              >
            </div>
            <p>Total Marks: {{ items?.assignment?.total_marks }}</p>
            <div v-if="!items.percentage || items.percentage === 'Not checked'">
              <form @submit.prevent="submit">
                <div class="mb-3">
                  <label for="marks" class="form-label"
                    >Marks <span class="text-danger">*</span></label
                  >
                  <input
                    v-model="marks"
                    type="text"
                    id="marks"
                    class="form-control"
                    required
                    @keydown="isNumber($event)"
                  />
                  <div id="errorMessage" style="color: red"></div>
                </div>
                <button type="submit" class="btn primary-bg-color text-white">Submit</button>
              </form>
            </div>
            <div v-else>
              <p class="fw-bold">Obtained Marks: {{ items?.percentage }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dataTable from "@/components/dataTable.vue";
import isNumber from "@/helpers/helpers";

export default {
  mixins: [isNumber],
  components: {
    dataTable,
  },
  data() {
    return {
      loading: false,
      marks: "",
      items: [],
      batchId: "",
      module: "submitAssignments",
      permissions: null,
      isEdit: false,
      isDelete: false,
      isView: true,
      module: "markAssigment",
    };
  },
  mounted() {
    this.batchId = this.$route.params.id;
    this.fetchAssesment(this.batchId);
  },
  methods: {
    fetchAssesment(id) {
      this.loading = true;
      this.$store
        .dispatch("assignment/MarkAssignments", { id })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    submit() {
      if (this.marks > this.items.assignment.total_marks) {
        alert(
          "Please enter a number between 1 and " +
            this.items.assignment.total_marks +
            " ."
        );
      } else {
        this.loading = true;
        const data = {
          id: this.items.id,
          marks: this.marks,
        };
        this.$store
          .dispatch("assignment/storeMarks", { data })
          .then((response) => {
            this.loading = false;
            if (response.success == true) {
              window.location.reload();
            } else {
              this.errors = response.error;
              console.log(response.error);
            }
          })
          .catch((e) => {
            console.log(e.error);
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style>
label.form-check.d-flex.align-items-center {
  color: #0d6efd;
}
</style>
