<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mx-auto mt-5">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Edit Course
              </h5>
            </div>
            <form @submit.prevent="submit">
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Course Name <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    v-model="course.name"
                    class="custom-form-control"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Duration (in months)
                  </label>
                  <input
                    type="text"
                    v-model="course.duration"
                    class="custom-form-control"
                    required
                    readonly
                    disabled
                  />
                </div>
              </div>
              <div class="form-group mt-3" v-if="role == 'super-admin'">
                <label class="primary-text-color"
                  >Facilitator <span class="text-danger">*</span></label
                >
                <select
                  class="custom-form-control"
                  v-model="course.facilitator_id"
                  required
                >
                  <option value="" disabled>Select a Facilitator</option>
                  <option
                    v-for="facilitator in facilitators"
                    :value="facilitator.id"
                  >
                    {{ facilitator.name }}
                  </option>
                </select>
              </div>

              <div class="form-group row">
                <div class="col-md-6 mt-3">
                  <label class="primary-text-color">Predecessor</label>
                  <select
                    class="custom-form-control"
                    v-model="course.predecessor_course_id"
                  >
                    <option value="">Select Course</option>
                    <option v-for="course in courses" :value="course.id">
                      {{ course.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-6 mt-3">
                  <label class="primary-text-color">Successor</label>
                  <select
                    class="custom-form-control"
                    v-model="course.successor_course_id"
                  >
                    <option value="">Select Course</option>
                    <option v-for="course in courses" :value="course.id">
                      {{ course.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color">Description</label>
                  <textarea
                    class="custom-form-control"
                    v-model="course.description"
                  ></textarea>
                </div>
              </div>

              <div class="form-group">
                <div class="image-group">
                  <div class="col-md-4 mt-3">
                    <img
                      v-if="course.thumbnail"
                      :src="thumbnailPreview"
                      class="img-thumbnail"
                      style="width: 100%"
                    />
                  </div>
                </div>
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Upload Thumbnail</label
                  >
                  <div class="position-relative">
                    <input
                      type="file"
                      ref="thumbnail"
                      class="position-relative"
                      accept="image/png, image/jpg, image/jpeg"
                      @change="selectThumbnail"
                      style="opacity: 0; z-index: 100"
                    />
                    <button
                      type="button"
                      class="btn btn-primary primary-bg-color position-absolute"
                      style="left: 0"
                    >
                      Browse...
                    </button>
                  </div>
                </div>
              </div>

              <div class="row">
                <template
                  v-for="(obj, index) in course.objectives"
                  :key="index"
                >
                  <div class="d-flex justify-content-end mt-3">
                    <button
                      type="button"
                      class="btn btn-sm btn-danger"
                      @click="removeObjective(index)"
                    >
                      Remove
                    </button>
                  </div>
                  <label class="primary-text-color"
                    >Objective {{ index + 1 }}</label
                  >
                  <div>
                    <textarea
                      class="custom-form-control"
                      v-model="course.objectives[index].objective"
                    ></textarea>
                  </div>
                </template>
                <div class="col-md-6 mt-3">
                  <button
                    class="btn btn-sm primary-bg-color text-white"
                    @click="addObjective"
                  >
                    Add Objective
                  </button>
                </div>
              </div>
              <div class="row">
                <template v-for="(kpi, index) in course.kpis" :key="index">
                  <div class="d-flex justify-content-end mt-3">
                    <button
                      type="button"
                      class="btn btn-sm btn-danger"
                      @click="removeKpi(index)"
                    >
                      Remove
                    </button>
                  </div>
                  <label class="primary-text-color">Kpi {{ index + 1 }}</label>
                  <div>
                    <textarea
                      class="custom-form-control"
                      v-model="course.kpis[index].kpi"
                    ></textarea>
                  </div>
                </template>
                <div class="col-md-6 mt-3">
                  <button
                    class="btn btn-sm primary-bg-color text-white"
                    @click="addKpi"
                  >
                    Add KPI
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-9">
                  <button
                    type="submit"
                    class="custom-btn primary-bg-color text-white btn-block w-100 mt-3"
                  >
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <router-link
                    to="/courses"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import dataTable from "@/components/dataTable.vue";

export default {
  components: {
    dataTable,
  },
  data() {
    return {
      loading: false,
      course: {
        name: null,
        description: null,
        predecessor_course_id: null,
        facilitator_id: null,
        successor_course_id: null,
        objectives: [],
        kpis: [],
        thumbnail: "",
        duration: null,
      },
      objective: {
        objective: "",
      },
      kpi: {
        kpi: "",
      },
      courseLevel: {
        course_id: null,
        name: null,
        description: null,
      },
      courseId: null,
      facilitators: [],
      courses: [],
      columns: [
        {
          text: "Name",
          value: "name",
        },
      ],
      items: [],
      module: "courseLevels",
      thumbnailPreview: "",
      role:localStorage.getItem('role'),
    };
  },
  mounted() {
    this.fetchCourses();
    this.courseId = this.$route.params.id;
    this.fetch(this.courseId);
    this.fetchTeacher("Teacher");
  },
  methods: {
    fetchCourses() {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("courses/getAll", { search, pagination })
        .then((response) => {
          this.courses = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("courses/getSingle", { id })
        .then((response) => {
          this.course = response.data;
          this.thumbnailPreview = this.course.thumbnail.image_url;
          this.course.objectives = response.data.objectives_and_kpis
            .filter((item) => item.type === "obj")
            .map((item) => ({ objective: item.text }));

          this.course.kpis = response.data.objectives_and_kpis
            .filter((item) => item.type === "kpi")
            .map((item) => ({ kpi: item.text }));
          this.loading = false;
          this.courses = this.courses.filter(
            (course) => course.id !== this.course.id
          );
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.course);
      const id = this.courseId;
      this.$store
        .dispatch("courses/update", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            this.$toast.show("Successfuly updated.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
          } else {
            this.errors = response.error;
            console.log(response.error);
          }
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    addObjective(event) {
      event.preventDefault();
      this.course.objectives.push(Object.assign({}, this.objective));
      this.objective = { objective: "" };
    },
    removeObjective(index) {
      this.course.objectives.splice(index, 1);
    },
    addKpi(event) {
      event.preventDefault();
      this.course.kpis.push(Object.assign({}, this.kpi));
      this.kpi = { kpi: "" };
    },
    removeKpi(index) {
      this.course.kpis.splice(index, 1);
    },
    selectThumbnail() {
      let picture = this.$refs.thumbnail.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.thumbnailPreview = e.target.result;
      };
      reader.onloadend = () => {
        this.course.thumbnail = reader.result;
      };
      reader.readAsDataURL(picture);
    },
    fetchTeacher(type) {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("facilitators/getAll", { type, search, pagination })
        .then((response) => {
          this.facilitators = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
  },
};
</script>
