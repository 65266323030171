<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mx-auto mt-5">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Edit Batch
              </h5>
            </div>
            <form @submit.prevent="submit">
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Course <span class="text-danger">*</span></label
                  >
                  <select
                    class="custom-form-control"
                    v-model="batch.course_id"
                    required
                    disabled
                  >
                    <option value="">Select Course</option>
                    <option v-for="course in courses" :value="course.id">
                      {{ course.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Title <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="custom-form-control"
                    v-model="batch.title"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6 mt-3">
                  <label class="primary-text-color"
                    >Start Date <span class="text-danger">*</span></label
                  >
                  <input
                    type="date"
                    class="custom-form-control"
                    v-model="batch.start_date"
                    required
                    :min="today"
                    readonly
                    disabled
                  />
                </div>
                <div class="col-md-6 mt-3">
                  <label class="primary-text-color"
                    >End Date <span class="text-danger">*</span></label
                  >
                  <input
                    type="date"
                    class="custom-form-control"
                    v-model="batch.end_date"
                    required
                    :min="today"
                    readonly
                    disabled
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6 mt-3">
                  <label class="primary-text-color">Vacation Start Date</label>
                  <input
                    type="date"
                    class="custom-form-control"
                    v-model="batch.vacation_start_date"
                    :min="today"
                  />
                </div>
                <div class="col-md-6 mt-3">
                  <label class="primary-text-color">Vacation End Date</label>
                  <input
                    type="date"
                    class="custom-form-control"
                    v-model="batch.vacation_end_date"
                    :min="today"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Facilitator <span class="text-danger">*</span></label
                  >
                  <select
                    class="custom-form-control"
                    v-model="batch.teacher_id"
                    required
                    readonly
                    disabled
                  >
                    <option value="">Select Facilitator</option>
                    <option v-for="teacher in teachers" :value="teacher.id">
                      {{ teacher.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col-md-9">
                  <button
                    type="submit"
                    class="custom-btn primary-bg-color text-white btn-block w-100 mt-3"
                  >
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <router-link
                    to="/batch"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Participant List Modal -->
    <div
      class="modal fade"
      id="participantModal"
      tabindex="-1"
      aria-labelledby="participantModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="d-flex justify-content-center mb-5 mt-5">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Participant List
              </h5>
            </div>
            <data-table
              :columns="columns"
              :items="students"
              :isEdit="isEdit"
              :isDelete="isDelete"
            ></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import dataTable from "@/components/dataTable.vue";

export default {
  components: {
    dataTable,
  },
  data() {
    return {
      loading: false,
      batch: {
        title: null,
        course_id: "",
        start_date: null,
        end_date: null,
        vacation_start_date: null,
        vacation_end_date: null,
        batch_teacher: [],
        batch_enrollments: [],
      },
      student_ids: [],
      courses: [],
      teachers: [],
      departments: [],
      department_id: "",
      students: [],
      batchId: null,
      qualification: "",
      designation: "",
      columns: [
        {
          text: "Participant Name",
          value: "name",
        },
        {
          text: "Designation",
          value: "designation",
        },
        {
          text: "Department",
          value: "department",
          secondValue: "name",
        },
        {
          text: "Qualification",
          value: "qualification",
        },
      ],
      isEdit: false,
      isDelete: false,
      today: null,
    };
  },
  mounted() {
    this.batchId = this.$route.params.id;
    this.fetchBatch(this.batchId);
    this.fetchCourses();
    this.fetchTeachers();
    this.fetchDepartments();
    this.today = new Date().toISOString().split("T")[0];
  },
  methods: {
    fetchBatch(id) {
      this.loading = true;
      this.$store
        .dispatch("batch/getSingle", { id })
        .then((response) => {
          this.batch = response.data;
          this.batch.batch_enrollments.forEach((item) => {
            this.students.push(item.student);
          });
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    fetchStudents() {
      this.loading = true;
      const type = "Student";
      const department = this.department_id;
      const qualification = this.qualification;
      this.$store
        .dispatch("participants/filterStudents", {
          type,
          department,
          qualification,
        })
        .then((response) => {
          this.students = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    fetchCourses() {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("courses/getAll", { search, pagination })
        .then((response) => {
          this.courses = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    fetchTeachers() {
      this.loading = true;
      const type = "Teacher";
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("facilitators/getAll", { type, search, pagination })
        .then((response) => {
          this.teachers = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    fetchDepartments() {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("department/getAll", { search, pagination })
        .then((response) => {
          this.departments = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.batch);
      const id = this.batchId;
      this.$store
        .dispatch("batch/update", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            this.$toast.show("Successfuly updated.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            this.students = [];
          } else {
            this.errors = response.error;
            console.log(response.error);
          }
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.modal-content {
  min-height: 800px;
  max-height: 800px;
}
</style>
