<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <router-link
      to="/submitted-assignments"
      class="btn primary-bg-color text-white mt-5"
      >Back</router-link
    >
    <div class="card mt-3">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 mt-3">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                {{ courseTitle }}
              </h5>
            </div>
          </div>
          <div class="col-md-12">
            <data-table
              :columns="columns"
              :items="items"
              :module="module"
              :isView="isView"
              @deleted="fetch"
            ></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dataTable from "@/components/dataTable.vue";

export default {
  components: {
    dataTable,
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          text: "Student Name",
          value: "user",
          secondValue: "name",
        },
        {
          text: "Assignment",
          value: "assignment_type_with_label",
        },
        {
          text: "Submit Date",
          value: "created_at",
        },
        {
          text: "Marks",
          value: "percentage",
        },
      ],
      items: [],
      batchId: "",
      module: "submitAssignments",
      permissions: null,
      isEdit: false,
      isDelete: false,
      isView: true,
      module: "markAssigment",
      courseTitle: null,
    };
  },
  mounted() {
    this.batchId = this.$route.params.id;
    this.fetchAssesment(this.batchId);
  },
  methods: {
    fetchAssesment(id) {
      this.loading = true;
      this.$store
        .dispatch("assignment/getSubmittedAssignments", { id })
        .then((response) => {
          this.items = response.data.assignments;
          this.courseTitle = response.data.batch;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>
