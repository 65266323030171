import axiosInstance from "@/helpers/axios";

export default {
  namespaced: true,
  actions: {
    getAll(
      { dispatch, commit },
      { search, pagination = true, facilitator_id = null }
    ) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(
            `/courses?search=${search}&pagination=${pagination}&facilitator_id=${facilitator_id}`
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    getSingle({ dispatch, commit }, { id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/courses/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    store({ dispatch, commit }, { data }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .post("/courses", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    update({ dispatch, commit }, { data, id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .put(`/courses/${id}`, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    delete({ dispatch, commit }, { id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .delete(`/courses/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    courseReviews({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/course-reviews`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    courseFeedback({ dispatch, commit },{type}) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/course-feedback?type=${type}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    getSingleFeedback({ dispatch, commit }, { id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/course-feedback/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    enrollmentRequests({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/enrollment-requests`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
  },
};
