<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="card position-relative" style="top: 50px">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 mt-3">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Notifications
              </h5>
            </div>
            <router-link v-if="permissions && permissions.includes('notification_view')" to="/notifications/send"
              class="btn btn-sm btn-primary mb-2 float-end primary-bg-color">Send Notification</router-link>
          </div>
          <div class="col-md-12">
            <data-table :columns="columns" :items="items" :isAction="action" @deleted="fetch" @search="fetch"
              @clear="fetch" @nextPage="nextPage"></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    dataTable,
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          text: "Title",
          value: "body",
          secondValue: "title",
        },
        {
          text: "Body",
          value: "body",
          secondValue: "detail",
        },
        {
          text: "Type",
          value: "to",
        },
      ],
      items: [],
      action: false,
      module: "notifications",
      permissions: null,
    };
  },
  mounted() {
    this.fetch();
    this.permissions = localStorage.getItem("permissions");
  },
  methods: {
    fetch(search = "") {
      this.loading = true;
      this.$store
        .dispatch("notifications/getAllNotifications", { search })
        .then((response) => {
          this.items = response.data;
          this.items.data.forEach((item) => {
            item.body = JSON.parse(item.body);
          });
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    nextPage(url) {
      this.loading = true;
      axiosInstance.get(url).then((response) => {
        this.items = response.data.data;
        this.items.data.forEach((item) => {
          item.body = JSON.parse(item.body);
        });
        this.loading = false;
      });
    },
  },
};
</script>
