<template>
  <loading :active="loading" :is-full-page="true" loader="bars"></loading>
  <div class="row">
    <form @submit.prevent="submit">
      <div class="col-md-10 mx-auto mt-5">
        <div class="card">
          <div class="card-header p-3">
            <h4 class="text-center">Banner Block</h4>
          </div>
          <div class="card-body">
            <div class="form-group mb-3">
              <label>Headline</label>
              <input
                type="text"
                v-model="website.banner.heading"
                class="custom-form-control"
              />
            </div>
            <div class="form-group row mb-3">
              <div class="col-md-6">
                <label>Button Text</label>
                <input
                  type="text"
                  v-model="website.banner.button_text"
                  class="custom-form-control"
                />
              </div>
              <div class="col-md-6">
                <label>Button Link</label>
                <input
                  type="text"
                  v-model="website.banner.button_link"
                  class="custom-form-control"
                />
              </div>
            </div>
            <div class="form-group mb-3">
              <label>Image</label>
              <input
                type="file"
                accept="image/jpg,image/png,image/jpeg"
                class="custom-form-control"
                @change="selectImage($event, 'banner')"
              />
              <img v-if="bannerImagePreview" :src="bannerImagePreview" />
              <img v-else :src="website.banner?.image?.image_url" />
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header p-3">
            <h4 class="text-center">About us Block</h4>
          </div>
          <div class="card-body">
            <div class="form-group mb-3">
              <label>Heading</label>
              <input
                type="text"
                v-model="website.about.heading"
                class="custom-form-control"
              />
            </div>
            <div class="form-group mb-3">
              <label>Text</label>
              <input
                type="text"
                v-model="website.about.text"
                class="custom-form-control"
              />
            </div>
            <div class="form-group row mb-3">
              <div class="col-md-6">
                <label>Image 1</label>
                <input
                  type="file"
                  accept="image/jpg,image/png,image/jpeg"
                  class="custom-form-control"
                  @change="selectImage($event, 'about1')"
                />
                <img v-if="aboutImage1Preview" :src="aboutImage1Preview" />
                <img v-else :src="website.about?.about_image1?.image_url" />
              </div>
              <div class="col-md-6">
                <label>Image 2</label>
                <input
                  type="file"
                  accept="image/jpg,image/png,image/jpeg"
                  class="custom-form-control"
                  @change="selectImage($event, 'about2')"
                />
                <img v-if="aboutImage2Preview" :src="aboutImage2Preview" />
                <img v-else :src="website.about?.about_image2?.image_url" />
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header p-3">
            <h4 class="text-center">Courses Block</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-4" v-for="(course, index) in website.courses">
                <div class="form-group mb-3">
                  <div class="row">
                    <label class="col-md-6">Icon</label>
                    <div class="col-md-6" v-if="index > 0">
                      <span
                        class="text-danger text-underline float-end d-inline-block cursor-pointer"
                        @click="removeCourse(index, course)"
                        >Remove</span
                      >
                    </div>
                  </div>
                  <img
                    class="mb-1"
                    v-if="course.imagePreview"
                    :src="course.imagePreview"
                  />
                  <img class="mb-1" v-else :src="course.image?.image_url" />
                  <input
                    type="file"
                    accept="image/jpg,image/png,image/jpeg"
                    class="custom-form-control"
                    @change="selectImage($event, 'course', index)"
                  />
                </div>
                <div class="form-group mb-3">
                  <label>Heading</label>
                  <input
                    type="text"
                    v-model="course.heading"
                    class="custom-form-control"
                  />
                </div>
                <div class="form-group mb-3">
                  <label>Text</label>
                  <input
                    type="text"
                    v-model="course.text"
                    class="custom-form-control"
                  />
                </div>
              </div>
              <div
                class="col-md-3 d-flex justify-content-start align-items-center"
                v-if="courses.length < 5"
              >
                <button type="button" class="add-btn" @click="addCourse">
                  +
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header p-3">
            <h4 class="text-center">Counter Block</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div
                class="col-md-3"
                v-for="(counter, index) in website.counters"
              >
                <div class="form-group mb-3">
                  <div class="row">
                    <label class="col-md-6">Counter</label>
                    <div class="col-md-6" v-if="index > 0">
                      <span
                        class="text-danger text-underline float-end d-inline-block cursor-pointer"
                        @click="removeCounter(index, counter)"
                        >Remove</span
                      >
                    </div>
                  </div>
                  <input
                    type="text"
                    v-model="counter.counter"
                    class="custom-form-control"
                  />
                </div>
                <div class="form-group mb-3">
                  <label>Title</label>
                  <input
                    type="text"
                    v-model="counter.text"
                    class="custom-form-control"
                  />
                </div>
              </div>
              <div
                class="col-md-3 d-flex justify-content-start align-items-center"
                v-if="counters.length < 4"
              >
                <button type="button" class="add-btn" @click="addCounter">
                  +
                </button>
              </div>
            </div>
            <div class="form-group mb-3">
              <label>Image</label>
              <input
                type="file"
                accept="image/jpg,image/png,image/jpeg"
                class="custom-form-control"
                @change="selectImage($event, 'counter')"
              />
              <img v-if="counterImagePreview" :src="counterImagePreview" />
              <img v-else :src="website?.counter_image?.image_url" />
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header p-3">
            <h4 class="text-center">How it works Block</h4>
          </div>
          <div class="card-body">
            <div class="form-group mb-3">
              <label>Heading</label>
              <input
                type="text"
                v-model="website.how_it_works_heading"
                class="custom-form-control"
              />
            </div>
            <div class="form-group mb-3">
              <label>Video</label>
              <input
                type="file"
                accept="video/*"
                class="custom-form-control"
                @change="selectVideo($event)"
              />
              <video
                v-if="videoPreview"
                class="mt-2 w-50"
                :src="videoPreview"
                controls
              ></video>
              <video
                v-else
                class="mt-2 w-50"
                :src="website?.video_file?.image_url"
                controls
              ></video>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header p-3">
            <h4 class="text-center">Testimonials Block</h4>
          </div>
          <div class="card-body">
            <section v-for="(testimonial, index) in website.testimonials">
              <span
                class="text-danger cursor-pointer d-block text-end text-underline mt-3"
                v-if="index > 0"
                @click="removeTestimonial(index, testimonial)"
                >Remove</span
              >
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group mb-3">
                    <label>Image</label>
                    <input
                      type="file"
                      accept="image/png,image/jpg,image/jpeg"
                      class="custom-form-control"
                      @change="selectImage($event, 'testi', index)"
                    />
                    <img
                      v-if="testimonial.imagePreview"
                      :src="testimonial.imagePreview"
                    />
                    <img v-else :src="testimonial.image?.image_url" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group mb-3">
                    <label>Name</label>
                    <input
                      type="text"
                      v-model="testimonial.name"
                      class="custom-form-control"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group mb-3">
                    <label>Designation</label>
                    <input
                      type="text"
                      v-model="testimonial.designation"
                      class="custom-form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>Testimonial</label>
                <input
                  type="text"
                  v-model="testimonial.testimonial"
                  class="custom-form-control"
                />
              </div>
            </section>
            <span
              class="text-primary cursor-pointer d-block text-underline mt-3"
              v-if="testimonials.length < 4"
              @click="addTestimonial"
              >Add Testimonial</span
            >
          </div>
        </div>
       <div class="row">
       <div class="col-md-2 ms-auto">
        <button type="submit" class="custom-btn primary-bg-color text-white w-100  float-end mb-5">
          Save
        </button>
       </div>
       </div>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      courses: [],
      course: {
        image: null,
        heading: null,
        text: null,
        imagePreview: null,
        file: null,
      },
      counters: [],
      couter: {
        counter: null,
        text: null,
      },
      testimonials: [],
      testimonial: {
        image: null,
        name: null,
        designation: null,
        testimonial: null,
        imagePreview: null,
        file: null,
      },
      banner: {
        heading: null,
        button_text: null,
        button_link: null,
        image: null,
        file: null,
      },
      about: {
        heading: null,
        text: null,
        image1: null,
        image2: null,
        file1: null,
        file2: null,
      },
      website: {
        how_it_works_heading: null,
        how_it_works_video: null,
        counter_cover_image: null,
        video: null,
        file: null,
        banner: {
          heading: null,
          button_text: null,
          button_link: null,
          image: null,
          file: null,
        },
        about: {
          heading: null,
          text: null,
          image1: null,
          image2: null,
          file1: null,
          file2: null,
        },
      },
      bannerImagePreview: null,
      aboutImage1Preview: null,
      aboutImage2Preview: null,
      counterImagePreview: null,
      videoPreview: null,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$store
        .dispatch("webContent/getAll")
        .then((response) => {
          this.loading = false;
          this.website = response.data;
          if (!this.website.about) {
            this.website.about = this.about;
          }
          if (!this.website.banner) {
            this.website.banner = this.banner;
          }
          if (this.website.counters.length < 1) {
            this.addCounter();
          }
          if (this.website.courses.length < 1) {
            this.addCourse();
          }
          if (this.website.testimonials.length < 1) {
            this.addTestimonial();
          }
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    submit() {
      this.loading = true;
      const data = this.website;
      this.$store
        .dispatch("webContent/store", { data })
        .then(() => {
          this.fetch();
          this.$toast.show("Successfuly updated.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    selectVideo(event) {
      const file = event.target.files[0];
      this.videoPreview = URL.createObjectURL(file);
      let reader = new FileReader();
      reader.onloadend = () => {
        this.website.video = reader.result;
      };
      reader.readAsDataURL(file);
    },
    selectImage(event, type, index = null) {
      let picture = event.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        if (type == "banner") {
          this.bannerImagePreview = e.target.result;
        }
        if (type == "about1") {
          this.aboutImage1Preview = e.target.result;
        }
        if (type == "about2") {
          this.aboutImage2Preview = e.target.result;
        }
        if (type == "counter") {
          this.counterImagePreview = e.target.result;
        }
        if (type == "course") {
          this.website.courses[index].imagePreview = e.target.result;
        }
        if (type == "testi") {
          this.website.testimonials[index].imagePreview = e.target.result;
        }
      };
      reader.onloadend = () => {
        if (type == "banner") {
          this.website.banner.file = reader.result;
        }
        if (type == "about1") {
          this.website.about.file1 = reader.result;
        }
        if (type == "about2") {
          this.website.about.file2 = reader.result;
        }
        if (type == "counter") {
          this.website.file = reader.result;
        }
        if (type == "course") {
          this.website.courses[index].file = reader.result;
        }
        if (type == "testi") {
          this.website.testimonials[index].file = reader.result;
        }
      };
      reader.readAsDataURL(picture);
    },
    addCourse() {
      this.website.courses.push(Object.assign({}, this.course));
    },
    removeCourse(index, course) {
      this.website.courses.splice(index, 1);
      if (course.id) {
        this.loading = true;
        const id = course.id;
        this.$store
          .dispatch("webContent/removeCourse", { id })
          .then(() => {
            this.loading = false;
            this.$toast.show("Successfuly removed.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
          })
          .catch((e) => {
            this.loading = false;
            console.log(e);
          });
      }
    },
    addCounter() {
      this.website.counters.push(Object.assign({}, this.counter));
    },
    removeCounter(index, counter) {
      this.website.counters.splice(index, 1);
      if (counter.id) {
        this.loading = true;
        const id = counter.id;
        this.$store
          .dispatch("webContent/removeCounter", { id })
          .then(() => {
            this.loading = false;
            this.$toast.show("Successfuly removed.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
          })
          .catch((e) => {
            this.loading = false;
            console.log(e);
          });
      }
    },
    addTestimonial() {
      this.website.testimonials.push(Object.assign({}, this.testimonial));
    },
    removeTestimonial(index, testi) {
      this.website.testimonials.splice(index, 1);
      if (testi.id) {
        this.loading = true;
        const id = testi.id;
        this.$store
          .dispatch("webContent/removeTestimonial", { id })
          .then(() => {
            this.loading = false;
            this.$toast.show("Successfuly removed.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
          })
          .catch((e) => {
            this.loading = false;
            console.log(e);
          });
      }
    },
  },
};
</script>
<style scoped>
img {
  width: 50%;
  margin-top: 10px;
}
</style>
