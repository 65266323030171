<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mx-auto mt-5">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Edit Level
              </h5>
            </div>
            <form @submit.prevent="submit">
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Level Name <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    v-model="level.name"
                    class="custom-form-control"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color">Description</label>
                  <textarea
                    class="custom-form-control"
                    v-model="level.description"
                  ></textarea>
                </div>
              </div>
              <div class="row">
                <div class="col-md-9">
                  <button
                    type="submit"
                    class="custom-btn primary-bg-color text-white btn-block w-100 mt-3"
                  >
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <button
                    type="button"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    @click="goBack"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import dataTable from "@/components/dataTable.vue";
import router from "@/router";

export default {
  components: {
    dataTable,
  },
  data() {
    return {
      loading: false,
      level: {
        name: null,
        description: null,
        course_id: null,
      },
      courseLevelId: null,
      thumbnailPreview: null,
      videoPreview: null,
      materialId: null,
      material: null,
      module: "courseMaterial",
      courseId: null,
    };
  },
  mounted() {
    this.courseLevelId = this.$route.params.id;
    this.courseId = this.$route.params.courseId;
    this.fetch(this.courseLevelId);
  },
  methods: {
    goBack() {
      router.go(-1);
    },
    selectThumbnail() {
      let picture = this.$refs.thumbnail.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.thumbnailPreview = e.target.result;
      };
      reader.onloadend = () => {
        this.courseMaterial.thumbnail = reader.result;
      };
      reader.readAsDataURL(picture);
    },
    selectVideo() {
      let video = document.getElementById("video-preview");
      this.videoPreview = this.$refs.video.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        video.src = e.target.result;
      };
      reader.onloadend = () => {
        this.courseMaterial.video = reader.result;
      };
      reader.readAsDataURL(this.videoPreview);
    },
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("courseLevels/getSingle", { id })
        .then((response) => {
          this.level = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.level);
      const id = this.courseLevelId;
      console.log("data",data);
      this.$store
        .dispatch("courseLevels/update", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            this.$toast.show("Successfuly updated.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
          } else {
            this.errors = response.error;
            console.log(response.error);
          }
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
  },
};
</script>
