<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mx-auto mt-5">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Add Manual Assessment
              </h5>
            </div>
            <form @submit.prevent="submit">
              <div class="form-group mb-3">
                <label class="primary-text-color">Course <span class="text-danger">*</span></label>
                <select class="custom-form-control" v-model="selectedCourse" @change="selectCourse" required>
                  <option value="">Select Course</option>
                  <option v-for="course in courses" :value="course">
                    {{ course.name }}
                  </option>
                </select>
              </div>


              <div class="form-group">
                <div class="col-md-12 mb-3">
                  <label class="primary-text-color">Title<span class="text-danger">*</span></label>
                  <textarea class="custom-form-control" v-model="assessment.title" required></textarea>
                </div>
              </div>


              <div class="form-group">
                <div class="col-md-12 mb-3">
                  <label class="primary-text-color">Description <span class="text-danger">*</span></label>
                  <textarea class="custom-form-control" v-model="assessment.description" required></textarea>
                </div>
              </div>


              <div class="form-group">
                <label class="primary-text-color">Total Marks <span class="text-danger">*</span></label>
                <input type="text" v-model="assessment.total_marks" class="custom-form-control mb-3" required
                  @keydown="isNumber($event)" />
              </div>





              <div>
                <label class="primary-text-color">Document <span class="text-danger">*</span></label>
                <div class="position-relative">
                  <input type="file" ref="profilePic" class="position-relative" accept=".pdf, .doc, .docx"
                    @change="handlePDF" style="opacity: 0; z-index: 100" />
                  <button type="button" class="btn btn-primary primary-bg-color position-absolute" style="left: 0">
                    Browse...
                  </button>
                </div>


              </div>

              <div v-if="perview" class="mt-3">
                <img src="@/assets/images/pdf_icon.png" style="width: 50px; height: 50px;" alt="PDF Icon"
                  class="pdf-icon" @click="openPdfPreview" />
              </div>









              <!-- <div class="form-group" v-if="role != null">
                <div class="col-md-12 mb-3">
                  <label class="primary-text-color"
                    >Facilitator <span class="text-danger">*</span></label
                  >
                  <select
                    class="custom-form-control"
                    v-model="facilitator_id"
                    required
                  >
                    <option value="" disabled>Select a Facilitator</option>
                    <option
                      v-for="facilitator in facilitators"
                      :value="facilitator.user_id"
                      :key="facilitator.id"
                    >
                      {{ facilitator.name }}
                    </option>
                  </select>
                </div>
              </div> -->
              <div class="row mt-3">
                <div class="col-md-6">
                  <button type="submit" class="custom-btn primary-bg-color text-white btn-block w-100 mt-3">
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <button type="button" class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    @click="clearAll">
                    Clear All
                  </button>
                </div>
                <div class="col-md-3">
                  <router-link to="/manualAssessment"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3">Cancel</router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import isNumber from "@/helpers/helpers";

export default {
  mixins: [isNumber],
  data() {
    return {
      loading: false,
      title: null,
      assessment: {
        description: null,
        total_marks: null,
        course_id: null,
        title: null,
        document: null,
      },
      facilitators: [],
      facilitator_id: "",
      role: localStorage.getItem("role"),
      courses: [],
      selectedCourse: null,
      perview: "",
    };
  },
  mounted() {
    this.fetch("Teacher");
    this.fetchCourses();
  },
  methods: {
    handlePDF() {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64String = btoa(
          new Uint8Array(reader.result)
            .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        this.assessment.document = `data:application/pdf;base64,${base64String}`;
        const blob = new Blob([reader.result], { type: 'application/pdf' });
        this.perview = URL.createObjectURL(blob);
      };
      reader.readAsArrayBuffer(file);
    },
    selectCourse() {
      this.assessment.course_id = this.selectedCourse.id;
      this.facilitator_id = this.selectedCourse.facilitator.id;
    },
    submit() {
      this.loading = true;
      this.assessment.total_marks = parseInt(this.assessment.total_marks);
      const data = toRaw(this.assessment);
      data.facilitator_id =
        this.role == null ? localStorage.getItem("upId") : this.facilitator_id;
      this.$store
        .dispatch("manualAssessment/store", { data })
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            this.$toast.show("Successfuly added.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            Object.keys(this.assessment).forEach(
              (key) => (this.assessment[key] = null)
            );
            this.title = null;
            this.facilitator_id = "";
            this.selectedCourse = "";
            this.perview="";
          } else {
            this.errors = response.error;
            console.log(response.error);
          }
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    clearAll() {
      Object.keys(this.assessment).forEach(
        (key) => (this.assessment[key] = null)
      );
      this.title = null;
    },
    fetch(type) {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("facilitators/getAll", { type, search, pagination })
        .then((response) => {
          this.facilitators = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    fetchCourses() {
      const search = "";
      const pagination = false;
      const facilitator_id =
        this.role == null ? localStorage.getItem("upId") : null;
      this.$store
        .dispatch("courses/getAll", { search, pagination, facilitator_id })
        .then((response) => {
          this.courses = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    openPdfPreview() {
      if (this.perview) {
        window.open(this.perview, '_blank');
      } else {
        console.error('No preview URL available.');
      }
    },
  },
};
</script>
