<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>

    <div class="card position-relative" style="top: 50px">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 mt-3">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Admins
              </h5>
            </div>
            <router-link v-if="permissions && permissions.includes('admins_add')" to="/users/add"
              class="btn btn-sm btn-primary mb-2 float-end primary-bg-color">
              Add Admin
            </router-link>
          </div>
          <div class="col-md-12">
            <data-table :columns="columns" :module="module" :items="items" :isEdit="isEdit" :isApprove="isApprove"
              :isBlock="isBlock" :isChangePassword="changePassword" @deleted="fetch('Admin')" @search="search"
              @clear="search" @nextPage="nextPage"></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      searchValue: "",
      columns: [
        {
          text: "Username",
          value: "email",
        },
        {
          text: "Role",
          value: "role",
        },
        {
          text: "Status",
          value: "user",
          secondValue: "status",
        },
      ],
      items: [],
      module: "users",
      permissions: null,
      isEdit: false,
      isApprove: false,
      isBlock: false,
      loading: false,
      changePassword: false,
      searchString: "",
    };
  },
  mounted() {
    this.fetch("Admin");
    this.permissions = localStorage.getItem("permissions");
    if (this.permissions && this.permissions.includes("admins_edit")) {
      this.isEdit = true;
    }
    if (this.permissions && this.permissions.includes("admins_approve")) {
      this.isApprove = true;
    }
    if (this.permissions && this.permissions.includes("admins_block")) {
      this.isBlock = true;
    }
    if (
      this.permissions &&
      this.permissions.includes("admins_change_password")
    ) {
      this.changePassword = true;
    }
  },
  methods: {
    search(search) {
      this.searchString = search;
      this.fetch("Admin");
    },
    nextPage(url) {
      this.loading = true;
      url += `&type=Admin&search=${this.searchString}`;
      axiosInstance.get(url).then((response) => {
        this.items = response.data.data;
        this.items.data.forEach(item => {
            item.role=item.user.roles[0].name ?? "";
          });
        this.loading = false;
      });
    },
    fetch(type) {
      this.loading = true;
      const search = this.searchString;
      this.$store
        .dispatch("users/getAllAdmins", { type, search })
        .then((response) => {
          this.items = response.data;
          this.items.data.forEach(item => {
            item.role=item.user.roles[0].name ?? "";
          });
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
  },
};
</script>
