<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="card position-relative" style="top: 50px">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 mt-3">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Facilitator Management
              </h5>
            </div>
            <router-link
              v-if="permissions && permissions.includes('facilitators_add')"
              to="/facilitators/add"
              class="btn btn-sm btn-primary mb-2 float-end primary-bg-color"
              >Add Facilitator</router-link
            >
          </div>
          <div class="col-md-12">
            <data-table
              :columns="columns"
              :items="items"
              :module="module"
              :index="1"
              :isEdit="isEdit"
              :isBlock="isBlock"
              :isApprove="isApprove"
              @deleted="fetch('Teacher')"
              @search="search"
              @clear="search"
              @nextPage="nextPage"
            ></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    dataTable,
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Phone",
          value: "phone",
        },
        {
          text: "Tehsil",
          value: "tahseel",
          secondValue: "name",
        },
        {
          text: "Status",
          value: "user",
          secondValue: "status",
        },
      ],
      items: [],
      permissions: null,
      isEdit: false,
      isBlock: false,
      isApprove: false,
      module: "facilitators",
      searchString: "",
    };
  },
  mounted() {
    this.fetch("Teacher");
    this.permissions = localStorage.getItem("permissions");
    if (this.permissions && this.permissions.includes("facilitators_edit")) {
      this.isEdit = true;
    }
    if (this.permissions && this.permissions.includes("facilitators_block")) {
      this.isBlock = true;
    }
    if (this.permissions && this.permissions.includes("facilitators_approve")) {
      this.isApprove = true;
    }
  },
  methods: {
    search(search) {
      this.searchString = search;
      this.fetch("Teacher");
    },
    nextPage(url) {
      this.loading = true;
      url += "&type=Teacher";
      axiosInstance.get(url).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
    fetch(type) {
      this.loading = true;
      const search = this.searchString;
      this.$store
        .dispatch("facilitators/getAll", { type, search })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
  },
};
</script>
