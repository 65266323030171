<template>
  <div>
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <!-- page-wrapper Start-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 p-0">
          <div class="login-card">
            <div>
              <div class="d-flex justify-content-center mb-4">
                <img src="@/assets/logo-blue.png" alt="" style="width: 300px;" />
              </div>
              <div class="login-main">
                <form class="theme-form" @submit.prevent="login">
                  <h4 class="text-center primary-text-color">SIGN IN</h4>
                
                  <span class="text-danger" v-if="errors.login">{{
                    errors.login
                  }}</span>
                  <div class="form-group">
                    <label class="col-form-label primary-text-color"
                      >Email</label
                    >
                    <input
                      class="custom-form-control"
                      type="email"
                      placeholder="email@test.com"
                      v-model="user.email"
                      required
                      autofocus
                      pattern="[^\s@]+@[^\s@]+\.[^\spatt@]+"
                    />
                    <span class="text-danger" v-if="errors.email">{{
                      errors.email[0]
                    }}</span>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label primary-text-color"
                      >Password</label
                    >
                    <div class="form-input position-relative">
                      <input
                        class="custom-form-control"
                        type="password"
                        placeholder="*********"
                        v-model="user.password"
                        required
                      />
                      <span class="text-danger" v-if="errors.password">{{
                        errors.password[0]
                      }}</span>
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <div class="checkbox p-0">
                      <input
                        id="checkbox1"
                        type="checkbox"
                        value="true"
                        v-model="user.remember_me"
                      />
                      <label class="primary-text-color" for="checkbox1"
                        >Remember me</label
                      >
                    </div>
                    <router-link
                      class="link primary-text-color"
                      to="/auth/verifyEmail"
                      >Forgot password?</router-link
                    >
                    <div class="text-end mt-3">
                      <button
                        class="custom-btn primary-bg-color text-white btn-block w-100"
                        type="submit"
                      >
                        Sign in
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- latest jquery-->
  </div>
</template>

<script>
import { toRaw } from "vue";
import router from "@/router";

export default {
  name: "login",
  data() {
    return {
      user: {
        email: "",
        password: "",
        remember_me: false,
        loginType: "admin",
      },
      errors: [],
      loading: false,
      permissions: [],
    };
  },
  created() {
    if (!!localStorage.getItem("token")) {
      router.push({ path: "/" });
    }
  },
  methods: {
    login() {
      this.loading = true;
      this.errors = [];
      const data = toRaw(this.user);
      this.$store
        .dispatch("auth/login", { data })
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            if (response.data.roles == undefined) {
              response.data.permissions.forEach((item) => {
                this.permissions.push(item.name);
              });
            } else {
              response.data.roles[0].permissions.forEach((item) => {
                this.permissions.push(item.name);
              });
              localStorage.setItem("role", response.data.roles[0].name);
            }
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("uid", response.data.encrypted_id);
            localStorage.setItem("pusher_id", response.data.id);
            localStorage.setItem("upId", response.data.profile.encrypted_id);
            localStorage.setItem("f_name", response.data.profile.name);
            localStorage.setItem("permissions", toRaw(this.permissions));
            localStorage.setItem(
              "remember_me",
              response.data.remember_me_token
            );
            window.location.href = "/dashboard";
          } else {
            this.$toast.show(response.error.login, {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
  },
};
</script>
