<template>
    <div class="container">
      <loading :active="loading" :is-full-page="true" loader="bars"></loading>
      <div class="card position-relative" style="top: 50px">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 mt-3">
              <div class="d-flex justify-content-center mb-3">
                <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                  How To Add
                </h5>
              </div>
              <router-link 
                to="/howTo/add"
                class="btn btn-sm btn-primary mb-2 float-end primary-bg-color"
                >Add Video</router-link
              >
            </div>
            <div class="col-md-12">
              <data-table
                :columns="columns"
                :module="module"
                :items="items"
                :isEdit="isEdit"
                :isDelete="isDelete"
                @deleted="fetch"
              ></data-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import DataTable from "@/components/dataTable.vue";
  
  export default {
    components: {
      DataTable,
    },
    data() {
      return {
        items: [],
        columns: [
          {
            text: "Title",
            value: "title",
          },
        ],
        loading: false,
        permissions: null,
        isEdit: true,
        isDelete: true,
        module:"howTo"
      };
    },
    created() {
      this.fetch();
    },
    methods: {
      fetch() {
        this.loading = true;
        this.$store
          .dispatch("howTo/getAll")
          .then((response) => {
            this.items = response.data;
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            console.log(e.error);
          });
      },
    },
  };
  </script>
  