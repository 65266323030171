import axiosInstance from "@/helpers/axios";

export default {
  namespaced: true,
  actions: {
    getAll({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/course-material`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    getMaterialByLevel(
      { dispatch, commit },
      { id, search, pagination = true }
    ) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(
            `/course-material/level/${id}?search=${search}&pagination=${pagination}`
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    getSingle({ dispatch, commit }, { id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/course-material/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    store({ dispatch, commit }, { data, onUploadProgress }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .post("/course-material", data, {
            
            onUploadProgress: onUploadProgress,
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    update({ dispatch, commit }, { data, id, onUploadProgress }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .put(`/course-material/${id}`, data, {
            onUploadProgress: onUploadProgress,
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    delete({ dispatch, commit }, { id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .delete(`/course-material/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
  },
};
