<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-10 mx-auto mt-5">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                {{ batch.title }}
              </h5>
            </div>
            <form @submit.prevent="submit">
              <div class="row mt-3">
                <h4>Course Material Rescheduling</h4>
                <div
                  v-for="(level, tableIndex) in batch.batch_shedule"
                  :key="tableIndex"
                >
                  <h3 class="mt-3">
                    {{ tableIndex + 1 }}. {{ level.level.name }}
                  </h3>
                  <div class="form-group">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        v-model="level.mode"
                        :id="'auto' + level.id"
                        value="auto"
                        :name="'modeName-' + level.level.name"
                        :checked="level.mode == 'auto'"
                      />
                      <label class="form-check-label" :for="'auto' + level.id">Auto</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        v-model="level.mode"
                        :id="'manual' + level.id"
                        value="manual"
                        :name="'modeName-' + level.level.name"
                        :checked="level.mode == 'manual'"
                      />
                      <label class="form-check-label" :for="'manual' + level.id"
                        >Manual</label
                      >
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        v-model="level.mode"
                        :id="'open' + level.id"
                        value="open"
                        :name="'modeName-' + level.level.name"
                        :checked="level.mode == 'open'"
                      />
                      <label class="form-check-label" :for="'open' + level.id">Open</label>
                    </div>
                  </div>
                  <table class="table table-striped dragTable">
                    <thead>
                      <tr>
                        <th>Material Number</th>
                        <th>Material Type</th>
                        <th>Material Title</th>
                        <th v-if="level.mode == 'manual'">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(row, rowIndex) in level.material"
                        :key="row.id"
                      >
                        <td>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="row.sorting"
                            @keydown="isNumber($event)"
                            style="width: 50px"
                          />
                        </td>
                        <td>{{ row.material.type }}</td>
                        <td v-if="row.material.type === 'Video'">
                          {{ row.material.material.title }}
                        </td>
                        <td v-if="row.material.type === 'Quiz'">
                          {{ row.material.material.name }}
                        </td>
                        <td v-if="row.material.type === 'Document'">
                          {{ row.material.material.title }}
                        </td>
                        <td v-if="row.material.type === 'Assignment'">
                          {{ row?.material?.material?.description }}
                        </td>
                        <td v-if="level.mode === 'manual'">
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="row.datetoopen"
                            required
                            :min="row.datetoopen"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <hr>
                </div>
              </div>
              <div class="row">
                <div class="col-md-9">
                  <button
                    type="submit"
                    class="custom-btn primary-bg-color text-white btn-block w-100 mt-3"
                  >
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <router-link
                    to="/batch"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Participant List Modal -->
    <div
      class="modal fade"
      id="participantModal"
      tabindex="-1"
      aria-labelledby="participantModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="d-flex justify-content-center mb-5 mt-5">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Participant List
              </h5>
            </div>
            <data-table
              :columns="columns"
              :items="students"
              :isEdit="isEdit"
              :isDelete="isDelete"
            ></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dataTable from "@/components/dataTable.vue";
import isNumber from "@/helpers/helpers";

export default {
  mixins: [isNumber],
  components: {
    dataTable,
  },
  data() {
    return {
      loading: false,
      batch: {
        title: null,
        course_id: "",
        start_date: null,
        end_date: null,
        vacation_start_date: null,
        vacation_end_date: null,
        batch_teacher: [],
        batch_enrollments: [],
      },
      student_ids: [],
      courses: [],
      teachers: [],
      departments: [],
      department_id: "",
      students: [],
      batchId: null,
      qualification: "",
      designation: "",
      columns: [
        {
          text: "Participant Name",
          value: "name",
        },
        {
          text: "Designation",
          value: "designation",
        },
        {
          text: "Department",
          value: "department",
          secondValue: "name",
        },
        {
          text: "Qualification",
          value: "qualification",
        },
      ],
      isEdit: false,
      isDelete: false,
      today: new Date().toISOString().split("T")[0],
    };
  },
  created() {
    this.batchId = this.$route.params.id;
    this.fetchBatch(this.batchId);
    this.fetchCourses();
    this.fetchTeachers();
    this.fetchDepartments();
  },
  methods: {
    fetchBatch(id) {
      this.loading = true;
      this.$store
        .dispatch("batch/getSingle", { id })
        .then((response) => {
          this.batch = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    fetchStudents() {
      this.loading = true;
      const type = "Student";
      const department = this.department_id;
      const qualification = this.qualification;
      this.$store
        .dispatch("participants/filterStudents", {
          type,
          department,
          qualification,
        })
        .then((response) => {
          this.students = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    fetchCourses() {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("courses/getAll", { search, pagination })
        .then((response) => {
          this.courses = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    fetchTeachers() {
      this.loading = true;
      const type = "Teacher";
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("facilitators/getAll", { type, search, pagination })
        .then((response) => {
          this.teachers = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    fetchDepartments() {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("department/getAll", { search, pagination })
        .then((response) => {
          this.departments = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    submit() {
      this.loading = true;
      const data = this.batch;
      this.$store
        .dispatch("batch/editMaterial", { data })
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            this.$toast.show("Successfuly updated.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            this.students = [];
          } else {
            this.errors = response.error;
            console.log(response.error);
          }
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.modal-content {
  min-height: 800px;
  max-height: 800px;
}
</style>
