import { createStore } from "vuex";

import layout from "./modules/layout";
import menu from "./modules/menu";
import auth from "./modules/auth";
import admins from "./modules/admins";
import users from "./modules/users";
import participants from "./modules/participants";
import facilitators from "./modules/facilitators";
import roles from "./modules/roles";
import courses from "./modules/courses";
import courseLevels from "./modules/courseLevels";
import quiz from "./modules/quiz";
import manualAssessment from "./modules/manualAssessment";
import batch from "./modules/batch";
import department from "./modules/department";
import courseMaterial from "./modules/courseMaterial";
import mediaFile from "./modules/mediaFile";
import assignment from "./modules/assignment";
import module from "./modules/module";
import howTo from "./modules/howTo";
import qualification from "./modules/qualification";
import designation from "./modules/designation";
import support from "./modules/userSupport";
import district from "./modules/district";
import tahseel from "./modules/tahseel";
import dashboard from "./modules/dashboard";
import notifications from "./modules/notification";
import userProfile from "./modules/userProfile";
import chat from "./modules/chat";
import webContent from "./modules/webContent";
import faq from "./modules/faq";
import setting from "./modules/setting";

export default createStore({
  state: { langIcon: "", langLangauge: "", isActive: false },
  getters: {
    langIcon: (state) => {
      return state.langIcon;
    },
    langLangauge: (state) => {
      return state.langLangauge;
    },
  },
  mutations: {
    changeLang(state, payload) {
      localStorage.setItem("currentLanguage", payload.id);
      localStorage.setItem("currentLanguageIcon", payload.icon);
      state.langIcon = payload.icon || "flag-icon-us";
      state.langLangauge = payload.id || "EN";
    },
    change(state) {
      state.isActive = !state.isActive;
    },
  },
  actions: {
    setLang({ commit }, payload) {
      commit("changeLang", payload);
    },
  },
  modules: {
    layout,
    menu,
    auth,
    admins,
    users,
    participants,
    facilitators,
    roles,
    courses,
    courseLevels,
    quiz,
    manualAssessment,
    batch,
    department,
    courseMaterial,
    mediaFile,
    assignment,
    module,
    howTo,
    qualification,
    designation,
    support,
    notifications,
    userProfile,
    chat,
    district,
    tahseel,
    dashboard,
    webContent,
    faq,
    setting,
  },
});
