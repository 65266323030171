<template>
    <div class="container">
      <loading :active="loading" :is-full-page="true" loader="bars"></loading>
      <div class="row">
        <div class="col-md-8 mx-auto mt-5"> 
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-center mb-3">
                <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                  Add Qualification
                </h5>
              </div>
              <form @submit.prevent="submit">
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label class="primary-text-color">Name <span class="text-danger">*</span></label>
                    <input type="text" class="custom-form-control" v-model="qualification.name" required />
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label class="primary-text-color">Description</label>
                    <textarea class="custom-form-control" v-model="qualification.description"></textarea>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <button type="submit" class="custom-btn primary-bg-color text-white btn-block w-100 mt-3">
                      Save
                    </button>
                  </div>
                  <div class="col-md-3">
                    <button type="button" class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                      @click="clearAll">Clear All</button>
                  </div>
                  <div class="col-md-3">
                    <router-link to="/qualifications"
                      class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3">Cancel</router-link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { toRaw } from "vue";
  
  export default {
    data() {
      return {
        loading: false,
        qualification: {
          name: null,
          description: null,
        },
      };
    },
    methods: {
      submit() {
        this.loading = true;
        const data = toRaw(this.qualification);
        console.log("focal person check",data);
        this.$store
          .dispatch("qualification/store", { data })
          .then((response) => {
            this.loading = false;
            if (response.success == true) {
              this.$toast.show("Successfuly added.", {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 5000,
              });
              Object.keys(this.qualification).forEach(
                (key) => (this.qualification[key] = null)
              );
            } else {
              this.errors = response.error;
              console.log(response.error);
            }
          })
          .catch((e) => {
            console.log(e.error);
            this.loading = false;
          });
      },
      clearAll() {
        Object.keys(this.qualification).forEach(
          (key) => (this.qualification[key] = null)
        );
      }
    },
  };
  </script>
  