<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="card position-relative" style="top: 50px">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 mt-3">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                District
              </h5>
            </div>
            <router-link
              to="/district/add"
              class="btn primary-bg-color text-white mb-2 float-end"
              >Add District</router-link
            >
          </div>

          <div class="col-md-12">
            <data-table
              :columns="columns"
              :items="items"
              :module="module"
              @deleted="fetch"
              :isEdit="isEdit"
              :isDelete="isDelete"
              @search="fetch"
              @clear="fetch"
              @nextPage="nextPage"
            ></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    dataTable,
  },
  data() {
    return {
      loading: false,
      showModal: false,
      columns: [
        {
          text: "Name",
          value: "name",
        },
      ],
      items: [],
      permissions: null,
      isEdit: true,
      isDelete: true,
      module: "district",
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    nextPage(url) {
      this.loading = true;
      axiosInstance.get(url).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
    fetch(search = "") {
      this.loading = true;
      this.$store
        .dispatch("district/getAll", { search })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
  },
};
</script>
