<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mt-5 mx-auto">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Add Video
              </h5>
            </div>
            <form @submit.prevent="submit" ref="addRole">
              <div class="form-group">
                <label class="primary-text-color"
                  >Title <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  v-model="howTo.title"
                  class="custom-form-control mb-3"
                  required
                />
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Upload Thumbnail <span class="text-danger">*</span></label
                  >
                  <div class="position-relative">
                    <input
                      type="file"
                      ref="thumbnail"
                      class="position-relative"
                      accept="image/png, image/jpg, image/jpeg"
                      @change="selectThumbnail"
                      style="opacity: 0; z-index: 100"
                      required
                    />
                    <button
                      type="button"
                      class="btn btn-primary primary-bg-color position-absolute"
                      style="left: 0"
                    >
                      Browse...
                    </button>
                  </div>
                </div>
                <div class="image-group">
                  <div class="col-md-4 mt-3">
                    <img
                      v-if="thumbnailPreview"
                      :src="thumbnailPreview"
                      class="img-thumbnail"
                      style="width: 100%"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Upload Video <span class="text-danger">*</span></label
                  >
                  <div class="position-relative">
                    <input
                      type="file"
                      ref="video"
                      class="position-relative"
                      accept="video/*"
                      @change="selectVideo($event)"
                      style="opacity: 0; z-index: 100"
                      required
                    />
                    <button
                      type="button"
                      class="btn btn-primary primary-bg-color position-absolute"
                      style="left: 0"
                    >
                      Browse...
                    </button>
                  </div>
                </div>
                <div class="col-md-12" v-if="videoPreview">
                  <video
                    class="mt-3 w-100"
                    id="video-preview"
                    controls
                    :src="videoPreview"
                  ></video>
                </div>
              </div>
              <div class="row mt-3">
                <div v-if="uploadProgress > 0">
                  <progress :value="uploadProgress" max="100">
                    {{ uploadProgress }}%
                  </progress>
                  <p>{{ uploadProgress }}%</p>
                </div>
                <div class="col-md-6">
                  <button
                    type="submit"
                    class="custom-btn primary-bg-color text-white btn-block w-100 mt-3"
                  >
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <button
                    type="button"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    @click="clearAll"
                  >
                    Clear All
                  </button>
                </div>
                <div class="col-md-3">
                  <router-link
                    to="/how-to"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";

export default {
  data() {
    return {
      thumbnailPreview: "",
      videoPreview: null,
      loading: false,
      uploadProgress: null,
      howTo: {
        title: "",
        video: null
      },
      uploadProgress: 0,
    };
  },
  methods: {
    selectThumbnail() {
      let picture = this.$refs.thumbnail.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.thumbnailPreview = e.target.result;
      };
      reader.readAsDataURL(picture);
    },
    selectVideo(event) {
      const file = event.target.files[0];
      this.videoPreview = URL.createObjectURL(file);
      let reader = new FileReader();
      reader.onloadend = () => {
        this.howTo.video = reader.result;
      };
      reader.readAsDataURL(file);
    },
    onUploadProgress(progressEvent) {
      this.uploadProgress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.howTo);
      data.image = this.thumbnailPreview;
      this.$store
        .dispatch("howTo/store", {
          data,
          onUploadProgress: this.onUploadProgress,
        })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            (this.howTo.title = ""),
              (this.videoPreview = ""),
              (this.thumbnailPreview = "");
            this.$refs.video.value = "";
            this.$refs.thumbnail.value = "";
            this.uploadProgress = 0;
            this.$toast.show("Successfuly added", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    clearAll() {
      (this.howTo.title = ""),
        (this.videoPreview = ""),
        (this.thumbnailPreview = "");
      this.$refs.video.value = "";
      this.$refs.thumbnail.value = "";
    },
  },
};
</script>

<style>
.progress {
  height: 25px;
}

.progress-bar {
  transition: width 0.3s ease-in-out;
}
</style>
