<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-8 mx-auto mt-5">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-center mb-3">
                            <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                                Support
                            </h5>
                        </div>
                        <div class="row ">
                            <div class="col-md-12 mb-3">
                                <img :src="support?.media?.image_url" alt="Image Preview" class="mt-3 w-100" />
                            </div>
                            <div class="row">
                                <div class="row mb-1">
                                    <div class="col-md-12">
                                        <p class="mb-1"><strong>Name:</strong> {{ support?.student?.name }}</p>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <div class="col-md-12">
                                        <p class="mb-1"><strong>Phone:</strong> {{ support?.student?.phone }}</p>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <div class="col-md-12">
                                        <p class="mb-1"><strong>Qualification:</strong> {{
                                            support?.student?.qualification }}</p>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <div class="col-md-12">
                                        <p class="mb-1"><strong>Cnic:</strong> {{ support?.student?.cnic }}</p>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <div class="col-md-12">
                                        <p class="mb-1"><strong>Detail:</strong>
                                        </p>

                                    </div>
                                    <div class="col-md-12">
                                        <p class="mb-1">{{ support?.detail }}
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";

export default {
    data() {
        return {
            loading: false,
            qualification: {
                name: null,
                description: null,
            },
            supportId: null,
            support: [],
        };
    },
    mounted() {
        this.supportId = this.$route.params.id;
        this.fetch(this.supportId);
    },
    methods: {
        fetch(id) {
            this.loading = true;
            this.$store
                .dispatch("support/getSingle", { id })
                .then((response) => {
                    this.support = response.data;
                    console.log("support", this.support);
                    this.loading = false;
                })
                .catch((e) => {
                    console.log(e);
                    this.loading = false;
                });
        },
        clearAll() {
            this.fetch(this.qualificationId);
        },
        submit() {
            this.loading = true;
            const data = toRaw(this.qualification);
            const id = this.qualificationId;
            this.$store
                .dispatch("designation/update", { data, id })
                .then((response) => {
                    this.loading = false;
                    if (response.success == true) {
                        this.$toast.show("Successfuly updated.", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });
                    } else {
                        this.errors = response.error;
                        console.log(response.error);
                    }
                })
                .catch((e) => {
                    console.log(e.error);
                    this.loading = false;
                });
        },
    },
};
</script>


<style scoped>
.profile-img img {
    padding: 5px;
}

.user-details {
    width: 100%;
}
</style>