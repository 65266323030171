<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mt-5 mx-auto">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Add Quiz
              </h5>
            </div>
            <form @submit.prevent="submit" ref="addRole">
              <div class="form-group mb-3">
                <label class="primary-text-color"
                  >Course <span class="text-danger">*</span></label
                >
                <select
                  class="custom-form-control"
                  v-model="selectedCourse"
                  @change="selectCourse"
                  required
                >
                  <option value="">Select Course</option>
                  <option v-for="course in courses" :value="course">
                    {{ course.name }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label class="primary-text-color"
                  >Name <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  v-model="quiz.name"
                  class="custom-form-control mb-3"
                  required
                />
              </div>
              <div class="form-group">
                <label class="primary-text-color">Description</label>
                <textarea
                  class="custom-form-control mb-3"
                  v-model="quiz.description"
                ></textarea>
              </div>
              <div class="form-group">
                <label class="primary-text-color"
                  >Total Marks <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  v-model="quiz.total_marks"
                  class="custom-form-control mb-3"
                  required
                  @keydown="isNumber($event)"
                />
              </div>
              <!-- <div class="form-group" v-if="role != null">
                <label class="primary-text-color"
                  >Facilitator <span class="text-danger">*</span></label
                >
                <select
                  class="custom-form-control"
                  v-model="facilitator_id"
                  required
                >
                  <option value="" disabled>Select a Facilitator</option>
                  <option
                    v-for="facilitator in facilitators"
                    :value="facilitator.user_id"
                    :key="facilitator.id"
                  >
                    {{ facilitator.name }}
                  </option>
                </select>
              </div> -->
              <template v-for="(question, qIndex) in quiz.questions">
                <div class="d-flex justify-content-end mt-3">
                  <p
                    class="text-danger text-underline cursor-pointer"
                    @click="removeQuestion(qIndex)"
                  >
                    Remove
                  </p>
                </div>
                <div class="form-group">
                  <label class="primary-text-color"
                    >Question {{ qIndex + 1 }}
                    <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    v-model="question.name"
                    class="custom-form-control"
                    required
                  />
                </div>
                <p
                  class="text-primary text-underline cursor-pointer mt-3"
                  @click="addOption(question)"
                >
                  + Add Option
                </p>
                <div class="row mt-3">
                  <div
                    class="form-group col-md-6"
                    v-for="(option, index) in question.options"
                  >
                    <div class="d-flex">
                      <label class="primary-text-color w-100"
                        >Option {{ index + 1 }}</label
                      >
                      <button
                        type="button"
                        class="btn p-0 flex-shrink-1"
                        @click="removeOption(qIndex, index)"
                      >
                        <i
                          class="fa fa-trash-o text-danger"
                          style="font-size: 16px"
                        ></i>
                      </button>
                    </div>
                    <input
                      type="text"
                      v-model="option.name"
                      class="custom-form-control"
                    />
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        :id="index + '' + qIndex"
                        :name="qIndex"
                        :value="option.name"
                        v-model="question.correct_answer"
                      />
                      <label
                        class="form-check-label primary-text-color"
                        :for="index + '' + qIndex"
                        >Mark as correct answer</label
                      >
                    </div>
                  </div>
                </div>
              </template>
              <button
                type="button"
                class="btn btn-sm btn-primary primary-bg-color mt-3"
                @click="addQuestion"
              >
                Add Question
              </button>
              <div class="row">
                <div class="col-md-6">
                  <button
                    type="submit"
                    class="custom-btn primary-bg-color text-white btn-block w-100 mt-3"
                  >
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <button
                    type="button"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    @click="clearAll"
                  >
                    Clear All
                  </button>
                </div>
                <div class="col-md-3">
                  <router-link
                    to="/quiz"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import isNumber from "@/helpers/helpers";

export default {
  mixins: [isNumber],
  data() {
    return {
      quiz: {
        name: null,
        description: null,
        status: "Active",
        questions: [],
        course_id: "",
        total_marks: null,
      },
      question: {
        name: null,
        correct_answer: null,
      },
      option: {
        name: null,
      },
      loading: false,
      facilitators: [],
      facilitator_id: "",
      role: localStorage.getItem("role"),
      courses: [],
      selectedCourse: null,
    };
  },
  mounted() {
    this.fetch("Teacher");
    this.fetchCourses();
  },
  methods: {
    selectCourse() {
      this.quiz.course_id = this.selectedCourse.id;
      this.facilitator_id = this.selectedCourse.facilitator.id;
    },
    addQuestion() {
      this.question.options = [];
      this.quiz.questions.push(Object.assign({}, this.question));
    },
    addOption(index) {
      if (index.options.length > 3) {
        this.$toast.show("You can add max 4 options.", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        index.options.push(Object.assign({}, this.option));
      }
    },
    removeQuestion(index) {
      this.quiz.questions.splice(index, 1);
    },
    removeOption(qIndex, index) {
      this.quiz.questions[qIndex].options.splice(index, 1);
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.quiz);
      data.facilitator_id =
        this.role == null ? localStorage.getItem("upId") : this.facilitator_id;
      this.$store
        .dispatch("quiz/store", { data })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.$toast.show("Successfuly added", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            Object.keys(this.quiz).forEach((key) => (this.quiz[key] = null));
            Object.keys(this.question).forEach(
              (key) => (this.question[key] = null)
            );
            Object.keys(this.option).forEach(
              (key) => (this.option[key] = null)
            );
            this.quiz.questions = [];
            this.quiz.status = "Active";
            this.facilitator_id = "";
          } else {
            this.$toast.show("Failed to save", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    clearAll() {
      Object.keys(this.quiz).forEach((key) => (this.quiz[key] = null));
      Object.keys(this.question).forEach((key) => (this.question[key] = null));
      Object.keys(this.option).forEach((key) => (this.option[key] = null));
      this.quiz.questions = [];
      this.quiz.status = "Active";
    },
    fetch(type) {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("facilitators/getAll", { type, search, pagination })
        .then((response) => {
          this.facilitators = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    fetchCourses() {
      const search = "";
      const pagination = false;
      const facilitator_id =
        this.role == null ? localStorage.getItem("upId") : null;
      this.$store
        .dispatch("courses/getAll", { search, pagination, facilitator_id })
        .then((response) => {
          this.courses = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
