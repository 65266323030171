<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <button
      class="btn primary-bg-color text-white mt-5"
      @click="$router.go(-1)"
    >
      Back
    </button>
    <div class="card mt-3">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 mt-3">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                {{ items?.batch?.title }}
              </h5>
            </div>
            <router-link
              :to="`/add/batch/participent/${batchId}`"
              class="btn btn-sm btn-primary mb-2 float-end primary-bg-color"
            >
              Add Student
            </router-link>
          </div>
          <div class="col-md-12">
            <data-table
              :columns="columns"
              @handle-status="handleStatus"
              :status="status"
              :items="students"
              :module="module"
            ></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Participant List Modal -->
  <div
    class="modal fade"
    id="participantModal"
    tabindex="-1"
    aria-labelledby="participantModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="d-flex justify-content-center mb-5 mt-5">
            <h5 class="ps-2" style="border-left: 2px solid #0074ce">
              Participant List
            </h5>
          </div>
          <!-- <data-table :columns="columns" :items="students" :isEdit="isEdit" :isDelete="isDelete"
              :isChecked="isChecked" @checkboxUpdated="handleCheckboxUpdated"></data-table> -->

          <div
            class="p-4 mt-3 col-md-10 offset-md-1"
            style="
              border-radius: 16px;
              background-color: rgba(0, 116, 206, 0.2);
            "
          >
            <h5>Search Participants <span class="text-danger">*</span></h5>
            <div class="form-group row">
              <div class="col-md-6 mt-3">
                <label class="primary-text-color">Department</label>
                <select
                  class="custom-form-control"
                  v-model="department_id"
                  @change="fetchStudents"
                >
                  <option value="">Select Department</option>
                  <option
                    v-for="department in departments"
                    :value="department.id"
                  >
                    {{ department.name }}
                  </option>
                </select>
              </div>
              <div class="col-md-6 mt-3">
                <label class="primary-text-color">Qualification</label>
                <select
                  class="custom-form-control"
                  v-model="qualification"
                  @change="fetchStudents"
                >
                  <option value="">Select Qualification</option>
                  <option
                    v-for="qualification in qualifications"
                    :value="qualification.name"
                  >
                    {{ qualification.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group mt-3">
              <label class="primary-text-color">Designation</label>
              <select
                class="custom-form-control"
                v-model="designation"
                @change="fetchStudents"
              >
                <option value="" selected>Select Designation</option>
                <option
                  v-for="designation in designations"
                  :value="designation.name"
                >
                  {{ designation.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dataTable from "@/components/dataTable.vue";

export default {
  components: {
    dataTable,
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          text: "Name",
          value: "student",
          secondValue: "name",
        },
        {
          text: "Email",
          value: "student",
          secondValue: "email",
        },
        {
          text: "Phone",
          value: "student",
          secondValue: "phone",
        },
        {
          text: "CNIC",
          value: "student",
          secondValue: "cnic",
        },
        {
          text: "CGPA",
          value: "student",
          secondValue: "result",
          thirdValue: "cgpa",
        },
      ],
      items: [],
      module: "batch",
      permissions: null,
      isEdit: false,
      isDelete: false,
      isView: true,
      students: [],
      batchId: "",
      status: true,
      departments: [],
      qualifications: [],
      designations: [],
      qualification: null,
      department_id: null,
      designation: null,
    };
  },
  mounted() {
    this.batchId = this.$route.params.id;
    this.fetch(this.batchId);
    this.fetchDepartments();
    this.fetchDesignations();
    this.fetchQualifications();
    this.permissions = localStorage.getItem("permissions");
    if (this.permissions && this.permissions.includes("batch-Edit")) {
      this.isEdit = true;
    }
    if (this.permissions && this.permissions.includes("batch-Delete")) {
      this.isDelete = true;
    }
  },
  methods: {
    fetchQualifications() {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("qualification/getAll", { search, pagination })
        .then((response) => {
          this.qualifications = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    fetchDesignations() {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("designation/getAll", { search, pagination })
        .then((response) => {
          this.designations = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("batch/getBatchStudents", { id })
        .then((response) => {
          this.items = response.data;
          this.students = this.items.enrollments;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    handleStatus(status, id) {
      this.loading = true;
      const data = {
        id: id,
        status: status,
      };
      this.$store
        .dispatch("batch/updateStudentStatus", { data })
        .then((response) => {
          if (response.success == true) {
            this.loading = false;
            this.fetch(this.batchId);
            this.$toast.show("Successfuly Removed.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
          } else {
            this.errors = response.error;
            console.log(response.error);
          }
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    fetchDepartments() {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("department/getAll", { search, pagination })
        .then((response) => {
          this.departments = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
  },
};
</script>
