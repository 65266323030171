<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mt-5 mx-auto">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Profile</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="editUser">
              <div class="form-group row mb-3">
                <div class="col-md-3">
                  <img
                    :src="previewProfilePic"
                    style="
                      width: 120px;
                      height: 120px;
                      border-radius: 50%;
                      border: 1px solid #ddd;
                    "
                  />
                </div>
                <div class="col-md-8 mt-3">
                  <label>Upload Profile Picture</label>
                  <div class="position-relative">
                    <input
                      type="file"
                      ref="profilePic"
                      class="position-relative"
                      accept="image/png, image/jpg, image/jpeg"
                      @change="profilePicSelected"
                      style="opacity: 0; z-index: 100"
                    />
                    <button
                      type="button"
                      class="btn btn-primary primary-bg-color position-absolute"
                      style="left: 0"
                    >
                      Browse...
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>Name <span class="text-danger">*</span></label>
                <span class="text-danger d-block" v-if="errors.f_name">
                  Name is required
                </span>
                <input
                  type="text"
                  class="custom-form-control mb-3"
                  required
                  v-model="user.name"
                  @keydown="validateText($event)"
                />
              </div>
              <div class="form-group">
                <label>Phone</label>
                <input
                  type="text"
                  class="custom-form-control mb-3"
                  v-model="user.phone"
                  @keypress="isNumber($event)"
                  minlength="11"
                  maxlength="11"
                />
              </div>
              <div class="form-group">
                <label>Email</label>
                <span class="text-danger d-block" v-if="errors.email">
                  Email is required
                </span>
                <input
                  type="email"
                  class="custom-form-control mb-3"
                  v-model="user.email"
                  pattern="[^\s@]+@[^\s@]+\.[^\spatt@]+"
                />
              </div>
              <div class="row">
                <div class="col-md-12 mt-3">
                  <button
                    type="submit"
                    class="custom-btn primary-bg-color text-white btn-block w-100"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import isNumber from "@/helpers/helpers";
import validateText from "@/helpers/helpers";

export default {
  mixins: [isNumber, validateText],
  data() {
    return {
      user: [],
      errors: [],
      userId: null,
      loading: false,
      profilePicture: null,
      previewProfilePic: null,
      uploadProfilePic: null,
    };
  },
  mounted() {
    this.userId = localStorage.getItem("upId");
    this.fetch(this.userId);
  },
  methods: {
    profilePicSelected() {
      this.profilePicture = this.$refs.profilePic.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.previewProfilePic = e.target.result;
      };
      reader.onloadend = () => {
        this.user.image = reader.result;
      };
      reader.readAsDataURL(this.profilePicture);
    },
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("userProfile/getSingle", { id })
        .then((response) => {
          this.user = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    submit() {
      this.loading = true;
      this.errors = [];
      const data = toRaw(this.user);
      const id = this.userId;
      this.$store
        .dispatch("userProfile/update", { data, id })
        .then(() => {
          this.loading = false;
          this.$toast.show("Successfuly updated.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.errors = [];
        })
        .catch((e) => {
          this.errors = e.error;
          this.loading = false;
        });
    },
  },
};
</script>
