<template>
  <div>
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div
      class="modal fade"
      :id="'deleteModal' + index"
      tabindex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <p>Are you sure you want to delete?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              data-bs-dismiss="modal"
            >
              No
            </button>
            <button
              type="button"
              class="btn btn-sm primary-bg-color text-white"
              data-bs-dismiss="modal"
              @click="deleteItem"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";

export default {
  props: {
    index: null,
    module: null,
    selectedItem: null,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    deleteItem() {
      this.loading = true;
      const id = toRaw(this.selectedItem.id);
      this.$store
        .dispatch(`${this.module}/delete`, { id })
        .then(() => {
          this.loading = false;
          this.$toast.show("Successfuly deleted", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.$emit("deleted");
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>
