<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="card position-relative" style="top: 50px">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 mt-3">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Enrollment Request
              </h5>
            </div>
          </div>
          <div class="col-md-12">
            <data-table
              :columns="columns"
              :items="items"
              :module="module"
              :isAction="false"
            ></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dataTable from "@/components/dataTable.vue";
export default {
  components: {
    dataTable,
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          text: "Course",
          value: "course",
          secondValue: "name",
        },
        {
          text: "Student Name",
          value: "student",
          secondValue: "name",
        },
        {
          text: "Reason to Enroll",
          value: "reason",
        },
      ],
      items: [],
      module: "feedback",
      permissions: null,
      isView: true,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$store
        .dispatch("courses/enrollmentRequests")
        .then((response) => {
          this.items = response;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
  },
};
</script>
