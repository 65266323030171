<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mx-auto mt-5">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Add Admin
              </h5>
            </div>
            <form @submit.prevent="submit" autocomplete="off">
              <div class="form-group">
                <label class="primary-text-color"
                  >Name <span class="text-danger">*</span></label
                >
                <span class="text-danger d-block" v-if="errors?.f_name">
                  Name is required
                </span>
                <input
                  type="text"
                  class="custom-form-control"
                  required
                  v-model="profile.name"
                  @keydown="validateText($event)"
                />
              </div>
              <div class="form-group mt-2">
                <label class="primary-text-color">Phone</label>
                <input
                  type="text"
                  class="custom-form-control"
                  v-model="profile.phone"
                  @keypress="isNumber($event)"
                  minlength="11"
                  maxlength="11"
                />
              </div>
              <div class="form-group mt-2">
                <label class="primary-text-color"
                  >Email <span class="text-danger">*</span></label
                >
                <span class="text-danger d-block" v-if="errors?.username">
                  Email already taken.
                </span>
                <input
                  type="email"
                  class="custom-form-control"
                  required
                  v-model="profile.email"
                  pattern="[^\s@]+@[^\s@]+\.[^\spatt@]+"
                  autocomplete="new-email"
                />
              </div>



              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color">
                    Password <span class="text-danger">*</span>
                  </label>

                  <div class="d-flex align-items-center">
                    <input :type="passwordFieldType" v-model="admin.password" class="custom-form-control mr-2 me-1"
                      autocomplete="new-password" required />
                    <span class="input-group-text me-1" @click="togglePasswordVisibility">
                      <i :class="passwordFieldType === 'password' ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                    </span>
                    <span class="input-group-text ml-2 me-1" @click="generatePassword">
                      <i class="fa fa-key"></i>
                    </span>
                  </div>
                </div>
              </div>


              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color">
                    Re-Type Password <span class="text-danger">*</span>
                  </label>
                  <span class="text-danger d-block">
                    {{ errors?.confirmPassword }}
                  </span>
                  <div class="input-group">
                    <input :type="passwordFieldType" v-model="admin.confirmPassword" class="custom-form-control"
                      autocomplete="new-password" required />

                  </div>
                </div>
              </div>



              <div class="form-group mt-2">
                <label class="primary-text-color"
                  >Role <span class="text-danger">*</span></label
                >
                <select
                  class="custom-form-control"
                  v-model="admin.role"
                  required
                >
                  <option value="">Select...</option>
                  <option v-for="role in roles" :value="role.name">
                    {{ role.name }}
                  </option>
                </select>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <button
                    type="submit"
                    class="custom-btn primary-bg-color text-white btn-block w-100 mt-3"
                  >
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <button
                    type="button"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    @click="clearAll"
                  >
                    Clear All
                  </button>
                </div>
                <div class="col-md-3">
                  <router-link
                    to="/users"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import isNumber from "@/helpers/helpers";
import validateText from "@/helpers/helpers";

export default {
  mixins: [isNumber, validateText],
  data() {
    return {
      admin: {
        username: null,
        password: null,
        confirmPassword: null,
        role: "",
        createdBy: localStorage.getItem("uid"),
        type: "Admin",
        profile: null,
      },
      profile: {
        name: null,
        phone: null,
        email: null,
      },
      roles: [],
      errors: [],
      loading: false,
      passwordFieldType: 'password',
    };
  },
  mounted() {
    this.fetchRoles();
  },
  methods: {
    submit() {
      if (this.admin.password !== this.admin.confirmPassword) {
        this.$toast.show("Confirm Password didn't match", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        this.loading = true;
        this.errors = [];
        this.admin.username = this.profile.email;
        this.admin.profile = this.profile;
        const data = this.admin;
        this.$store
          .dispatch("users/store", { data })
          .then((response) => {
            this.loading = false;
            if (response.success == true) {
              this.$toast.show("Successfuly added.", {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 5000,
              });
              this.clearAll();
            } else {
              this.errors = response.error;
            }
          })
          .catch((e) => {
            this.loading = false;
            console.log(e);
          });
      }
    },
    fetchRoles() {
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("roles/getAll", { search, pagination })
        .then((response) => {
          this.roles = response.data;
        })
        .catch((e) => {
          console.log(e.error);
        });
    },
    clearAll() {
      Object.keys(this.admin).forEach((key) => (this.admin[key] = null));
      Object.keys(this.profile).forEach((key) => (this.profile[key] = null));
      this.admin.role = "";
      this.admin.createdBy = localStorage.getItem("uid");
    },
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    generatePassword() {
      const length = 8;
      const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let password = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
      }
      this.admin.password = password;
      this.admin.confirmPassword = password;
    },
  },
};
</script>
