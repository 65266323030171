<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mx-auto mt-5">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Add Tehsil
              </h5>
            </div>
            <form @submit.prevent="submit">
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >District <span class="text-danger">*</span></label
                  >
                  <select
                    v-model="tahseel.district_id"
                    class="custom-form-control"
                    required
                  >
                    <option v-for="district in districts" :value="district.id">
                      {{ district.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Name <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="custom-form-control"
                    v-model="tahseel.name"
                    required
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <button
                    type="submit"
                    class="custom-btn primary-bg-color text-white btn-block w-100 mt-3"
                  >
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <button
                    type="button"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    @click="clearAll"
                  >
                    Clear All
                  </button>
                </div>
                <div class="col-md-3">
                  <router-link
                    to="/tahseel"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";

export default {
  data() {
    return {
      loading: false,
      districts: [],
      tahseel: {
        district_id: null,
        name: null,
      },
    };
  },
  mounted() {
    this.fetchDistrict();
  },
  methods: {
    fetchDistrict() {
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("district/getAll", { search, pagination })
        .then((response) => {
          this.districts = response.data;
        })
        .catch((e) => {
          console.log("district:", e);
        });
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.tahseel);
      this.$store
        .dispatch("tahseel/store", { data })
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            this.$toast.show("Successfuly added.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            Object.keys(this.tahseel).forEach(
              (key) => (this.tahseel[key] = null)
            );
          } else {
            this.errors = response.error;
            console.log(response.error);
          }
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    clearAll() {
      Object.keys(this.tahseel).forEach((key) => (this.tahseel[key] = null));
    },
  },
};
</script>
