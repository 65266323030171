<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mx-auto mt-5">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Edit Participant
              </h5>
            </div>
            <form @submit.prevent="submit" autocomplete="off">
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Email <span class="text-danger">*</span></label
                  >
                  <span class="d-block text-danger">{{
                    errors?.username
                  }}</span>
                  <input
                    type="email"
                    v-model="profile.user.username"
                    class="custom-form-control"
                    required
                    pattern="[^\s@]+@[^\s@]+\.[^\spatt@]+"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color">Password</label>
                  <input
                    type="password"
                    v-model="user.password"
                    class="custom-form-control"
                    autocomplete="new-password"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color">Re-Type Password</label>
                  <span class="text-danger d-block">
                    {{ errors?.confirmPassword }}
                  </span>
                  <input
                    type="password"
                    v-model="user.confirmPassword"
                    class="custom-form-control"
                    autocomplete="new-password"
                  />
                </div>
              </div>
              <div class="form-group row mt-3">
                <div class="col-md-3">
                  <img
                    :src="previewProfilePic"
                    style="
                      width: 120px;
                      height: 120px;
                      border-radius: 50%;
                      border: 1px solid #ddd;
                    "
                  />
                </div>
                <div class="col-md-8 mt-3">
                  <label>Upload Profile Picture</label>
                  <div class="position-relative">
                    <input
                      type="file"
                      ref="profilePic"
                      class="position-relative"
                      accept="image/png, image/jpg, image/jpeg"
                      @change="profilePicSelected"
                      style="opacity: 0; z-index: 100"
                    />
                    <button
                      type="button"
                      class="btn btn-primary primary-bg-color position-absolute"
                      style="left: 0"
                    >
                      Browse...
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group row mt-3">
                <div class="col-md-12">
                  <label class="primary-text-color"
                    >Name <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="custom-form-control mb-3"
                    v-model="profile.name"
                    required
                    @keydown="validateText($event)"
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6 mt-3">
                  <label class="primary-text-color"
                    >Department <span class="text-danger">*</span></label
                  >
                  <select
                    class="custom-form-control"
                    v-model="profile.department_id"
                    required
                  >
                    <option value="">Select Department</option>
                    <option
                      v-for="department in departments"
                      :value="department.id"
                    >
                      {{ department.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-6 mt-3">
                  <label class="primary-text-color">Designation</label>
                  <select
                    v-model="profile.designation"
                    class="custom-form-control"
                  >
                    <option value="" selected>Select Designation</option>
                    <option v-for="des in designations" :value="des.name">
                      {{ des.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Qualification <span class="text-danger">*</span></label
                  >
                  <select
                    v-model="profile.qualification"
                    class="custom-form-control"
                    required
                  >
                    <option value="">Select Qualification</option>
                    <option v-for="des in qualifications" :value="des.name">
                      {{ des.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Select District <span class="text-danger">*</span></label
                  >
                  <select
                    class="custom-form-control"
                    required
                    v-model="districtId"
                    @change="fetchTahseel"
                  >
                    <option v-for="district in districts" :value="district.id">
                      {{ district.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Select Tehsil <span class="text-danger">*</span></label
                  >
                  <select
                    class="custom-form-control"
                    v-model="profile.tahseel_id"
                    required
                  >
                    <option v-for="tahseel in tahseels" :value="tahseel.id">
                      {{ tahseel.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Phone <span class="text-danger">*</span></label
                  >
                  <span class="d-block text-danger">{{ errors?.phone }}</span>
                  <input
                    type="text"
                    v-model="profile.phone"
                    maxlength="11"
                    minlength="11"
                    @keypress="isNumber($event)"
                    class="custom-form-control"
                    required
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color">Gender</label>
                  <div class="col-md-12">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        id="male"
                        value="Male"
                        v-model="profile.gender"
                      />
                      <label
                        class="form-check-label primary-text-color"
                        for="male"
                        >Male</label
                      >
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        id="female"
                        value="Female"
                        v-model="profile.gender"
                      />
                      <label
                        class="form-check-label primary-text-color"
                        for="female"
                        >Female</label
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6 mt-3">
                  <label class="primary-text-color">Date of Birth</label>
                  <input
                    type="date"
                    v-model="profile.dob"
                    class="custom-form-control"
                  />
                </div>
                <div class="col-md-6 mt-3">
                  <label class="primary-text-color">CNIC</label>
                  <span class="d-block text-danger">{{ errors?.cnic }}</span>
                  <input
                    type="text"
                    v-model="profile.cnic"
                    maxlength="13"
                    minlength="13"
                    @keypress="isNumber($event)"
                    class="custom-form-control"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-9">
                  <button
                    type="submit"
                    class="custom-btn primary-bg-color text-white btn-block w-100 mt-3"
                  >
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <router-link
                    to="/participants"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import isNumber from "@/helpers/helpers";
import validateText from "@/helpers/helpers";

export default {
  mixins: [isNumber, validateText],
  data() {
    return {
      loading: false,
      profile: {
        name: null,
        department_id: "",
        designation: null,
        phone: null,
        gender: null,
        dob: null,
        email: null,
        cnic: null,
        type: "Student",
        qualification: null,
        tahseel_id: null,
        tahseel: null,
        user: {
          username: null,
        },
      },
      user: {
        password: null,
        confirmPassword: null,
      },
      errors: [],
      profilePicture: null,
      previewProfilePic: null,
      uploadProfilePic: null,
      userId: null,
      departments: [],
      districts: [],
      districtId: null,
      tahseels: [],
      designations: [],
      qualifications: [],
    };
  },
  mounted() {
    this.fetchDepartments();
    this.fetchDistrict();
    this.fetchDesigantion();
    this.fetchQualifications();
    this.userId = this.$route.params.id;
    this.fetch(this.userId);
  },
  methods: {
    fetchDesigantion() {
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("designation/getAll", { search, pagination })
        .then((response) => {
          this.designations = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    fetchQualifications() {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("qualification/getAll", { search, pagination })
        .then((response) => {
          this.qualifications = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    fetchDistrict() {
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("district/getAll", { search, pagination })
        .then((response) => {
          this.districts = response.data;
        })
        .catch((e) => {
          console.log("district:", e);
        });
    },
    fetchTahseel() {
      this.loading = true;
      const id = this.districtId;
      this.$store
        .dispatch("tahseel/getByDistrict", { id })
        .then((response) => {
          this.tahseels = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log("district:", e);
        });
    },
    fetchDepartments() {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("department/getAll", { search, pagination })
        .then((response) => {
          this.departments = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("participants/getSingle", { id })
        .then((response) => {
          this.profile = response.data;
          if (this.profile.tahseel) {
            this.districtId = this.profile.tahseel.district_id;
            this.fetchTahseel();
          }
          if (this.profile.profile_image) {
            this.previewProfilePic = this.profile.profile_image.image_url;
          }
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    profilePicSelected() {
      this.profilePicture = this.$refs.profilePic.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.previewProfilePic = e.target.result;
      };
      reader.onloadend = () => {
        this.uploadProfilePic = reader.result;
      };
      reader.readAsDataURL(this.profilePicture);
    },
    submit() {
      this.loading = true;
      if (this.user.password != this.user.confirmPassword) {
        this.errors.confirmPassword = "Password didn't match";
        this.loading = false;
      }
      if (this.user.password) {
        this.profile.user.password = this.user.password;
      }
      if (this.uploadProfilePic != null) {
        this.profile.file = this.uploadProfilePic;
      }
      const data = toRaw(this.profile);
      const id = this.userId;
      this.$store
        .dispatch("users/update", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            this.$toast.show("Successfuly updated.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            this.errors = null;
          } else {
            this.$toast.show("Please review your form.", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
            this.errors = response.error;
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>
