<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mx-auto mt-5">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Add Course
              </h5>
            </div>
            <form @submit.prevent="submit">
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Course Name <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    v-model="course.name"
                    class="custom-form-control"
                    required
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Duration (in months)
                    <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    v-model="course.duration"
                    class="custom-form-control"
                    required
                    @keypress="isNumber($event)"
                  />
                </div>
              </div>
              <div class="form-group mt-3" v-if="role != null">
                <label class="primary-text-color"
                  >Facilitator <span class="text-danger">*</span></label
                >
                <select
                  class="custom-form-control"
                  v-model="facilitator_id"
                  required
                >
                  <option value="" disabled>Select a Facilitator</option>
                  <option
                    v-for="facilitator in facilitators"
                    :value="facilitator.id"
                  >
                    {{ facilitator.name }}
                  </option>
                </select>
              </div>
              <div class="form-group row">
                <div class="col-md-6 mt-3">
                  <label class="primary-text-color">Predecessor</label>
                  <select
                    class="custom-form-control"
                    v-model="course.predecessor_course_id"
                  >
                    <option value="">Select Course</option>
                    <option v-for="course in courses" :value="course.id">
                      {{ course.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-6 mt-3">
                  <label class="primary-text-color">Successor</label>
                  <select
                    class="custom-form-control"
                    v-model="course.successor_course_id"
                  >
                    <option value="">Select Course</option>
                    <option v-for="course in courses" :value="course.id">
                      {{ course.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color">Description</label>
                  <textarea
                    class="custom-form-control"
                    v-model="course.description"
                  ></textarea>
                </div>
              </div>

              <div class="form-group">
                <div class="image-group">
                  <div class="col-md-4 mt-3">
                    <img
                      v-if="course.thumbnail"
                      :src="thumbnailPreview"
                      class="img-thumbnail"
                      style="width: 100%"
                    />
                  </div>
                </div>
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Upload Thumbnail <span class="text-danger">*</span></label
                  >
                  <div class="position-relative">
                    <input
                      type="file"
                      ref="thumbnail"
                      class="position-relative"
                      accept="image/png, image/jpg, image/jpeg"
                      @change="selectThumbnail"
                      style="opacity: 0; z-index: 100"
                      required
                    />
                    <button
                      type="button"
                      class="btn btn-primary primary-bg-color position-absolute"
                      style="left: 0"
                    >
                      Browse...
                    </button>
                  </div>
                </div>
              </div>

              <div class="row">
                <template
                  v-for="(obj, index) in course.objectives"
                  :key="index"
                >
                  <div class="d-flex justify-content-end mt-3">
                    <button
                      type="button"
                      class="btn btn-sm btn-danger"
                      @click="removeObjective(index)"
                    >
                      Remove
                    </button>
                  </div>
                  <label class="primary-text-color"
                    >Objective {{ index + 1 }}</label
                  >
                  <div>
                    <textarea
                      class="custom-form-control"
                      v-model="course.objectives[index].objective"
                      required
                    ></textarea>
                  </div>
                </template>
                <div class="col-md-6 mt-3">
                  <button
                    class="btn btn-sm primary-bg-color text-white"
                    @click="addObjective"
                  >
                    Add Objective
                  </button>
                </div>
              </div>
              <div class="row">
                <template v-for="(kpi, index) in course.kpis" :key="index">
                  <div class="d-flex justify-content-end mt-3">
                    <button
                      type="button"
                      class="btn btn-sm btn-danger"
                      @click="removeKpi(index)"
                    >
                      Remove
                    </button>
                  </div>
                  <label class="primary-text-color">Kpi {{ index + 1 }}</label>
                  <div>
                    <textarea
                      class="custom-form-control"
                      v-model="course.kpis[index].kpi"
                      required
                    ></textarea>
                  </div>
                </template>
                <div class="col-md-6 mt-3">
                  <button
                    class="btn btn-sm primary-bg-color text-white"
                    @click="addKpi"
                  >
                    Add KPI
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <button
                    type="submit"
                    class="custom-btn primary-bg-color text-white btn-block w-100 mt-3"
                  >
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <button
                    type="button"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    @click="clearAll"
                  >
                    Clear All
                  </button>
                </div>
                <div class="col-md-3">
                  <router-link
                    to="/courses"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import isNumber from "@/helpers/helpers";

export default {
  mixins: [isNumber],
  data() {
    return {
      loading: false,
      course: {
        name: null,
        description: null,
        predecessor_course_id: "",
        successor_course_id: "",
        objectives: [],
        kpis: [],
        duration: null,
      },
      objective: {
        objective: "",
      },
      kpi: {
        kpi: "",
      },
      thumbnailPreview: "",
      courses: [],
      facilitators: [],
      facilitator_id: "",
      role: localStorage.getItem("role"),
    };
  },
  mounted() {
    this.fetchCourses();
    this.fetch("Teacher");
  },
  methods: {
    fetchCourses() {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("courses/getAll", { search, pagination })
        .then((response) => {
          this.courses = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.course);
      data.facilitator_id =
        this.role == null
          ? localStorage.getItem("upId")
          : this.facilitator_id;
      this.$store
        .dispatch("courses/store", { data })
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            this.$toast.show("Successfuly added.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            Object.keys(this.course).forEach(
              (key) => (this.course[key] = null)
            );
            this.course.predecessor_course_id = "";
            this.course.successor_course_id = "";
            this.course.kpis = [];
            this.course.objectives = [];
            this.facilitator_id = "";
          } else {
            this.errors = response.error;
            console.log(response.error);
          }
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    selectThumbnail() {
      let picture = this.$refs.thumbnail.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.thumbnailPreview = e.target.result;
      };
      reader.onloadend = () => {
        this.course.thumbnail = reader.result;
      };
      reader.readAsDataURL(picture);
    },
    clearAll() {
      Object.keys(this.course).forEach((key) => (this.course[key] = null));
      this.course.predecessor_course_id = "";
      this.course.successor_course_id = "";
      this.course.kpis = [];
      this.course.objectives = [];
    },
    addObjective(event) {
      event.preventDefault();
      this.course.objectives.push(Object.assign({}, this.objective));
      this.objective = { objective: "" };
    },
    removeObjective(index) {
      this.course.objectives.splice(index, 1);
    },
    addKpi(event) {
      event.preventDefault();
      this.course.kpis.push(Object.assign({}, this.kpi));
      this.kpi = { kpi: "" };
    },
    removeKpi(index) {
      this.course.kpis.splice(index, 1);
    },
    fetch(type) {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("facilitators/getAll", { type, search, pagination })
        .then((response) => {
          this.facilitators = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
  },
};
</script>
