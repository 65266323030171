<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="card position-relative" style="top: 50px">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 mt-3">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Manual Assessment Management
              </h5>
            </div>
            <router-link
              v-if="permissions && permissions.includes('manualAssessment_add')"
              to="/manualAssessment/add"
              class="btn btn-sm btn-primary mb-2 float-end primary-bg-color"
              >Add Manual Assessment</router-link
            >
          </div>
          <div class="col-md-12">
            <data-table
              :columns="columns"
              :items="items"
              :module="module"
              :isEdit="isEdit"
              :isDelete="isDelete"
              @deleted="fetch"
              @search="fetch"
              @clear="fetch"
              @nextPage="nextPage"
            ></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    dataTable,
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          text: "Title",
          value: "title",
        },
        {
          text: "Course",
          value: "course",
          secondValue: "name",
        },
        {
          text: "Total marks",
          value: "total_marks",
        },
      ],
      items: [],
      module: "manualAssessment",
      permissions: null,
      isEdit: false,
      isDelete: false,
      role: null,
    };
  },
  mounted() {
    this.role = localStorage.getItem("role");
    this.fetch();
    this.permissions = localStorage.getItem("permissions");
    if (
      this.permissions &&
      this.permissions.includes("manualAssessment_edit")
    ) {
      this.isEdit = true;
    }
    if (
      this.permissions &&
      this.permissions.includes("manualAssessment_delete")
    ) {
      this.isDelete = true;
    }
  },
  methods: {
    nextPage(url) {
      this.loading = true;
      const facilitator_id =
        this.role == null ? localStorage.getItem("upId") : null;
      url += `&facilitator_id=${facilitator_id}`;
      axiosInstance.get(url).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
    fetch(search = "") {
      this.loading = true;
      const pagination = true;
      const facilitator_id =
        this.role == null ? localStorage.getItem("upId") : null;
      this.$store
        .dispatch("manualAssessment/getAll", {
          search,
          pagination,
          facilitator_id,
        })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
  },
};
</script>
