<template>
  <div>
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div
      ref="addLevel"
      class="modal fade"
      :id="'addLevelModal' + index"
      tabindex="-1"
      aria-labelledby="addLevelModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <div class="d-flex justify-content-center mb-3">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Add Level
              </h5>
            </div>
            <form @submit.prevent="submit">
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color"
                    >Level Name <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    v-model="level.name"
                    class="custom-form-control"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 mt-3">
                  <label class="primary-text-color">Description</label>
                  <textarea
                    class="custom-form-control"
                    v-model="level.description"
                  ></textarea>
                </div>
              </div>
              <div class="row">
                <div class="col-md-9">
                  <button
                    type="submit"
                    class="custom-btn primary-bg-color text-white btn-block w-100 mt-3"
                    data-bs-dismiss="modal"
                  >
                    Save
                  </button>
                </div>
                <div class="col-md-3">
                  <button
                    type="button"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import router from "@/router";

export default {
  props: {
    index: null,
    module: null,
    selectedItem: null,
  },
  data() {
    return {
      loading: false,
      level: {
        name: null,
        description: null,
        course_id: null,
      },
    };
  },
  methods: {
    submit() {
      this.loading = true;
      this.level.course_id = this.selectedItem.encrypted_id;
      const data = toRaw(this.level);
      this.$store
        .dispatch("courseLevels/store", { data })
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            this.$toast.show("Successfuly added.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            Object.keys(this.level).forEach((key) => (this.level[key] = null));
            window.location.href = `/courseLevels/${this.selectedItem.encrypted_id}`;
          } else {
            this.errors = response.error;
            console.log(response.error);
          }
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
  },
};
</script>
